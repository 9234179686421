import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  client: [],
  query: {}
};

export const AppCheckin = createSlice({
  name: 'AppCheckin',
  initialState,
  reducers: {
    add_checkin: (state, action) => {
      state.client.push(action.payload);
    },

    add_query: (state, action) => {
      state.query = action.payload;
    },

    remove_checkin: (state) => {
      state.client = [];
    },
    remove_query: (state) => {
      state.query = {};
    }
  }
});

export const { add_checkin, remove_checkin, add_query, remove_query } = AppCheckin.actions;

export const selectChechin = (state) => state.AppCheckin.client;
export const selectQuery = (state) => state.AppCheckin.query;


export default AppCheckin.reducer;
