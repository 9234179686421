import {
  Button,
  Input,
  Select,
  notification,
  Row,
  Col,
  Modal,
  Divider,
  Space,
} from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../assets/utils/useAuth";
import useLocalStorage from "../../assets/utils/useLocal";
import lib, { myRequestObj, writeArrayToCSV } from "../../assets/utils/lib";
import "./clients.css";
import { uri } from "../../assets/utils/http-request";
import config, { category, facilityType, policy_type, searchTarget, states } from "../../assets/utils/config";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  SettingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { BiBuildings } from "react-icons/bi";

import formValidator from "../formvalidation";
import CustomFileUploadModal from "../compoinents/UploadModal.js";
import SearchableTable from "../compoinents/SearchableTable.js";
import { PolicyHolderModal } from "../compoinents/userData.js";
import { ListOfDependent } from "../compoinents/profileUpdate.js";
import { EnroleeBiodataModal } from "../compoinents/enroleeData.js";
import { FacilityData } from "../compoinents/FacilityData.js";
import { render } from "@testing-library/react";

const { TextArea, Search } = Input;
const { Option } = Select;

const ClientVerufyHook = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [, setError] = React.useState(false);
  const [socialwarn, setSocialWarn] = useState("");
  const [validSocial, setValidSocial] = useState(true);
  const [addedHeads, setAddedHeads] = useState(0);
  const [premiumType, setPremiumType] = useState("Individual");

  const [clientRegistrationData, setClientRegistrationData] = useState({});
  const [facilityRegistrationData, setFacilityRegistrationData] = useState({
    facility_staff_id: user?.staffId,
  });

  const [loading, setLoading] = useState(false);
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);
  const [loadingCreateFacility, setLoadingCreateFacility] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showCreateFacilityModal, setShowCreateFacilityModal] = useState(false);
  const [validatingSocial, setValidatingSocial] = useState(false);
  const [validatedSocial, setValidatedSocial] = useState(false);

  const [_, setClientData] = useState({});
  const [searchParam, setSearchParam] = useLocalStorage(config.key.search);

  const [showBatchUserModal, setShowBatchUserModal] = useState(false);
  const [showBatchProviderModal, setShowBatchProviderModal] = useState(false);
  const [showBatchEnroleeModal, setShowBatchEnroleeModal] = useState(false);
  const [uploading, setUploading] = useState(false)

  const [tableToRender,] = useState('clients')
  const [searchResult, setSarchResult] = useState({ [tableToRender]: [] });

  const [packagePrices, setPackagePrices] = useState({})
  const [packages, setPackages] = useState([]);

  const canEdit = (user = null, data = {}) => {
    return user && [1, 2].includes(Number(user?.role["staffRoleId"]))
  }

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  });

  useEffect(() => {
    showDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showCreateUserModal) {
      myRequestObj('plans').post({})
      .then(data => data?.data)
      .then(data => {
        data?.data?.map(plan => {
          setPackages(d => [...d, {label: plan?.plan_name?.__capitalize(), value: plan?.plan_name?.__lower()}]);
          setPackagePrices(d => (
            {
              ...d, 
              [plan?.plan_name?.__lower()]: {individual: plan?.plan_cost, family: plan?.plan_six_cost}
            }
          ));
        })
      });
    }
  }, [showCreateUserModal]);

  const showDetail = async () => {
    setClientData({});
    setSarchResult(d => ({ ...d, [tableToRender]: [] }));

    const key = "verify";

    if (!searchParam?.search || !searchParam?.target) {
      notification.warning({
        message: "The query was not understood",
        key,
        placement: "topLeft",
      });
      return;
    }

    setLoading(true);
    var result = null;
    switch (searchParam?.target) {
      case 'clients':
        result = await (await lib.searchClients(searchParam))?.data;
        break;
      case 'enrollees':
        result = await (await lib.searchEnrolee(searchParam))?.data;
        break;
      case 'facilities':
        result = await (await lib.searchProviders(searchParam))?.data;
        break;
      case 'corporations':
        result = await (await lib.searchOrganizations(searchParam))?.data;
        break

      case 'constableItems':
        console.log(searchParam)
        result = await (await lib.searchCostables(searchParam))?.data;
        break

      default:
        notification.warning({
          message: "The query was not understood",
          key,
          placement: "topLeft",
        });
        return;
    }
    if (result) {
      if (Number(result?.error) === 0) {
        setClientData(result?.data);
        setSarchResult(d => ({ ...d, [searchParam['target']]: result?.data }));
      } else {
        notification.error({
          message: result?.message || result?.msg,
          key,
          duration: 2,
          placement: "topLeft",
        });
      }
    }
    setLoading(false);
  };

  const saveProvider = async () => {
    if (
      !facilityRegistrationData?.facility_name ||
      !facilityRegistrationData?.facility_type ||
      !facilityRegistrationData?.facility_plan_type ||
      !facilityRegistrationData?.facility_email ||
      !facilityRegistrationData?.facility_phone ||
      !facilityRegistrationData?.facility_state ||
      !facilityRegistrationData?.facility_city
    ) {
      notification.error({
        message: "Please fill the compulsory fields",
        placement: "topLeft",
      });
      return;
    }

    setLoadingCreateFacility(true);

    let reqData = await lib.saveProvider(facilityRegistrationData);

    if (reqData?.status === "error") {
      notification.error({ message: reqData?.msg, placement: "topLeft" });
    }

    if (reqData.error === 1) {
      notification.error({ message: reqData?.message, placement: "topLeft" });
    }

    if (reqData?.error === 0) {
      notification.success({
        message: reqData?.message,
        duration: 5,
        placement: "topLeft",
      });
      setShowCreateFacilityModal(false);
      setFacilityRegistrationData({});
    }

    setLoadingCreateFacility(false);
  };

  const handleOk = async () => {
    if (
      (clientRegistrationData.category === "corporate" ||
        clientRegistrationData.category === "referal") &&
      validSocial === false
    ) {
      notification.error({
        message: "Invalid Assocaition Code",
        placement: "topLeft",
      });
      return;
    }

    if (clientRegistrationData?.premium_type === "family") {
      var additional_heads = 6 + addedHeads - 1;
      var enrolee_size = 6 + addedHeads;
      var policy_cost = clientRegistrationData?.insurance_package
        ? packagePrices[clientRegistrationData?.insurance_package][
        "individual"
        ] *
        6 +
        packagePrices[clientRegistrationData?.insurance_package][
        "individual"
        ] *
        addedHeads
        : packagePrices[clientRegistrationData?.insurance_package]["family"] +
        packagePrices[clientRegistrationData?.insurance_package][
        "individual"
        ] *
        addedHeads;
    } else {
      additional_heads = addedHeads;
      enrolee_size = addedHeads + 1;
      policy_cost =
        packagePrices[clientRegistrationData?.insurance_package]["individual"] +
        packagePrices[clientRegistrationData?.insurance_package]["individual"] *
        addedHeads;
    }

    let builder = formValidator.validateAccountRegistration(
      {
        ...clientRegistrationData,
        additional_heads,
        enrolee_size,
        policy_cost,
      },
      {},
      setError
    );

    setLoadingCreateUser(true);

    let reqData = await lib.registerDash(builder);

    if (reqData.status === "error") {
      notification.error({ message: reqData.msg, placement: "topLeft" });
    }
    if (reqData.data?.error === 1) {
      notification.error({
        message: reqData.data.message,
        placement: "topLeft",
      });
    }

    if (reqData.data?.error === 0) {
      notification.success({
        message: reqData.data.message,
        placement: "topLeft",
      });
      // setShowCreateUserModal(false);
      setClientRegistrationData({});
    }

    setLoadingCreateUser(false);
  };

  const handleDependent = (e) => {
    setAddedHeads(e);
  };

  const handleFocusOut = async (e) => {
    setValidatingSocial(true);
    let obj = {
      social_no: clientRegistrationData?.social_no,
      mode: clientRegistrationData?.category,
    };

    let reqData = await lib.validatSocial(obj);

    if (reqData.status === "error") {
      notification.error({ message: reqData.msg, placement: "topLeft" });
    }

    if (reqData?.error === 1) {
      notification.error({ message: "Invalid code", placement: "topLeft" });
      setSocialWarn("Invalid code");
      setValidSocial(false);
    }

    if (reqData?.error === 0) {
      notification.success({ message: reqData.message, placement: "topLeft" });
      setValidSocial(true);
      setSocialWarn("");
    }
    setValidatedSocial(true);
    setValidatingSocial(false);
  };

  const uploadToServer = async (endpoint, data, target) => {
    setUploading(true)
    notification.info({
      message: <>
        <p>Bulk upload in progress!!!</p>
        <p><strong>Please do not close or refresh this window</strong></p>
        <>This operation could take a couple of minutes</>
      </>,
      key: "upload",
      icon: <LoadingOutlined />,
      duration: 0,
      placement: "topLeft",
    })
    myRequestObj(endpoint).post(data)
      .then(response => response)
      .then(data => {
        console.log(data);
        if (Number(data?.data?.error) === 0) {
          notification.success({ message: data?.data?.message, key: 'upload' })
          const link = document.createElement("a");

          link.href = uri + '/' + data?.data?.data;
          link.download = `Bulk ${target} Onboarding Report.csv`;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        } else {
          notification.error({ message: data?.data?.message, key: 'upload' })
        }
      }).catch(error => {
        console.error('Error uploading file(s)', error);
        notification.info({
          message: <>
            <p>Bulk upload failed</p>
            <p>{error?.message || error?.msg}</p>
          </>,
          key: "upload",
          icon: <LoadingOutlined />,
          duration: 0,
          placement: "topLeft",
        })
      }).finally(() => {
        setUploading(false)
        setTimeout(() => {
          notification.destroy('upload')
        }, 2000)
      });
  }

  const handleBatchUploadUsers = async (files) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    await uploadToServer('batchUsers', formData, 'Policy')
  }

  const saveBatchProvider = async (files) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    uploadToServer('batchFacility', formData, 'Facility')
  }

  const saveBatchEnrollee = async (files) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    await uploadToServer('batchEnrollees', formData, 'Enrollee')
  }

  const clientsTable = [
    "email", "phone", "name", "category", "social_no", "insurance_package", "additional_heads",
    "premium_type", "state", "city"
  ];

  const enrolleeTable = [
    "first_name", "surname", "middle_name", "date_of_birth",
    "relationship", "title", "gender", "religion", "contact_address",
    "phone", "email", "lga", "state", "marital_status", "existing_medical_condition",
    "previous_medical_condition", "policy_no", "insurance_no", "financier_id",
    "primary_health_facility", "secondary_health_facility", "role",
  ]

  const orgTable = [
    "org_id", "org_name", "org_social_no", "org_code", "org_email", "org_address",
    "org_city", "org_state", "org_joined_date", "org_renew_date", "org_expiry_date", "org_status"
  ];

  const providersTable = [
    "facility_name", "facility_type",
    "facility_description", "facility_email", "facility_password",
    "facility_phone", "facility_designation",
    "facility_plan_type", "facility_website", "facility_address", "facility_city",
    "facility_state"
  ];

  const constableItems = [
    "id", "item_code", "item_brand", "item_name", "item_dosage", "item_strength",
    "item_presentation", "category_id", "item_status", "category_name"
  ];

  const mappings = {
    clients: [
      {
        title: 'Policy Name | No. | Email',
        dataIndex: 'name',
        key: 'name',
        render: (__, record) => <><strong>{__?.toUpperCase()}</strong> <br></br><strong>{record?.policy_no}</strong><br></br><strong>{record?.email}</strong></>
      },
      {
        title: 'Package',
        dataIndex: 'premium_type',
        key: 'premium_type',
        render: (__, record) => <>
          {record?.insurance_package?.toUpperCase()}<br></br>
          {__?.toUpperCase()}
        </>
      },
      {
        title: 'Premium',
        dataIndex: 'category',
        key: 'category',
        render: (__, record) => <>
          {record?.category}<br></br>
          {record?.category === 'corporate' && <>{record?.social_no}</>}<br></br>
        </>
      },
      {
        title: 'No. Enrollees',
        dataIndex: 'enrolee_size',
        key: 'enrolee_size',
        render: (__, record) => <>{record?.active_enrollees} of {__}</>
      },
      {
        title: 'Date (Joined | Validity)',
        dataIndex: 'date',
        key: 'date',
        render: (__, record) => <>{(new Date(__)).toDateString()} <br></br>{(new Date(record?.expiry_date)).toDateString()}</>
      },
      {
        title: 'Status | Action',
        key: 'status',
        render: (__, record) => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>{record.status ? <CheckOutlined style={{ color: 'blue' }} /> : <CloseOutlined style={{ color: 'red' }} />}</Space>
          <Divider type="vertical" style={{ border: '1px solid black' }} />
          <Space>
            <Button type="link" icon={<SettingOutlined />} onClick={() => rowSetting(record, searchParam?.target)} />
            <Button type="link" icon={<UsergroupAddOutlined />} onClick={() => rowSetting(record, 'client_enrollees')} />
          </Space>
        </div>,
      },
    ],
    corporations: [
      {
        title: "Name",
        key: 'org_name',
        dataIndex: 'org_name',
        render: (_, record) => <>
          <div><strong>{_}</strong></div>
          <div><strong>Code</strong>: {record?.org_social_no || record?.code}</div>
        </>
      },
      {
        key: 'org_address',
        dataIndex: 'org_address',
        title: "Contact Address",
        render: (_, record) => <>
          <div><strong>{_}</strong></div>
          <div>{record?.org_city} {record?.org_state}</div>
          <div><strong>Email</strong>: {record?.org_email}</div>
        </>
      },
      {
        key: 'org_expiry_date',
        dataIndex: 'org_expiry_date',
        title: "Dates",
        render: (_, record) => <>
          <div><strong>Onboarded</strong>: {record?.org_joined_date ? (new Date(record?.org_joined_date)).toDateString() : null}</div>
          <div><strong>Last Renewal</strong>: {(new Date(record?.org_renew_date)).toDateString()}</div>
          <div><strong>Next Renewal</strong>: {(new Date(record?.org_expiry_date)).toDateString()}</div>
        </>
      },
      {
        key: 'org_status',
        title: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>Status</Space>
        </div>,
        dataIndex: 'org_status',
        render: (_, record) => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>{_ ? <CheckOutlined style={{ color: 'blue' }} /> : <CloseOutlined style={{ color: 'red' }} />}</Space>
        </div>
      },
    ],
    facilities: [
      {
        key: 'facility_name',
        title: "Facility Name | Phone | Email",
        dataIndex: 'facility_name',
        render: (_, record) => <>
          <strong>{_}</strong><br></br>
          <strong>{record?.facility_phone}</strong><br></br>
          <strong>{record?.facility_email}</strong>
        </>
      },
      {
        key: 'facility_plan_type',
        title: "Facility Plan Type",
        dataIndex: 'facility_plan_type',
        render: (_, record) => <>
          <strong>{record?.facility_type?.toUpperCase()}</strong><br></br>
          <strong>{record?.facility_plan_type?.toUpperCase()}</strong>
        </>
      },
      {
        key: 'facility_address',
        title: "Facility Address",
        dataIndex: 'facility_address',
        render: (_, record) => <>
          {_}<br></br>
          <strong>{record?.facility_city} | {record?.facility_state}</strong><br></br>
          {record?.facility_website && <Button type="link">{record?.facility_website}</Button>}
        </>
      },
      {
        key: 'facility_status',
        title: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>Status</Space>
          <Divider type="vertical" style={{ border: '1px solid black' }} />
          <Space>Action</Space>
        </div>,
        dataIndex: 'facility_status',
        render: (_, record) => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>{_ ? <CheckOutlined style={{ color: 'blue' }} /> : <CloseOutlined style={{ color: 'red' }} />}</Space>
          <Divider type="vertical" style={{ border: '1px solid black' }} />
          <Space>
            <Button type="link" icon={<SettingOutlined />} onClick={() => rowSetting(record, searchParam?.target)} />
          </Space>
        </div>
      },
    ],
    enrollees: [
      {
        title: 'Name',
        key: 'title',
        render: (_, record) => <div>
          <strong>{record?.title ? record?.title + ". " : ''}{record?.first_name} {record?.middle_name ? record?.middle_name + ' ' : ''}{record?.surname}</strong>
          <div><strong>Role:</strong> {record?.role}</div>
          <div><strong>Gender:</strong> {record?.gender}</div>
        </div>,
      },
      {
        title: 'Contact Information',
        key: 'email',
        render: (_, record) => (
          <>
            <div>{record?.phone}</div>
            <div>{record?.email}</div>
            <div>{record?.contact_address}</div>
            <div>{record?.lga}, {record?.state}</div>
          </>
        ),
      },
      {
        title: 'Medical Information',
        key: 'existing_medical_condition',
        render: (_, record) => (
          <>
            <div><strong>Existing:</strong> {record?.existing_medical_condition || 'N/A'}</div>
            <div><strong>Previous:</strong> {record?.previous_medical_condition || 'N/A'}</div>
          </>
        ),
      },
      {
        title: 'Insurance Details',
        key: 'insurance_no',
        render: (_, record) => (
          <>
            <div><strong>Policy No:</strong> {record?.policy_no}</div>
            <div><strong>Insurance No:</strong> {record?.insurance_no}</div>
          </>
        ),
      },
      {
        title: 'Facilities',
        key: 'primary_health_facility',
        render: (_, record) => (
          <>
            <div><strong>Primary:</strong> {record?.primary_health_facility}</div>
            <div><strong>Secondary:</strong> {record?.secondary_health_facility}</div>
          </>
        ),
      },
      {
        title: 'Other Details',
        key: 'relationship',
        render: (_, record) => (
          <>
            {record?.role === 'pricipal' ? null : <div><strong>Relationship:</strong> {record?.relationship}</div>}
            <div><strong>Religion:</strong> {record?.religion}</div>
            <div><strong>Date of Birth:</strong> {(new Date(record?.date_of_birth)).toDateString()}</div>
            <div><strong>Marital Status:</strong> {record?.marital_status}</div>
          </>
        ),
      },
      {
        key: 'status',
        title: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>Status</Space>
          <Divider type="vertical" style={{ border: '1px solid black' }} />
          <Space>Action</Space>
        </div>,
        dataIndex: 'status',
        render: (_, record) => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>{_ ? <CheckOutlined style={{ color: 'blue' }} /> : <CloseOutlined style={{ color: 'red' }} />}</Space>
          <Divider type="vertical" style={{ border: '1px solid black' }} />
          <Space>
            <Button type="link" icon={<SettingOutlined />} onClick={() => rowSetting(record, searchParam?.target)} />
          </Space>
        </div>
      },
    ],
    constableItems: [
      {
        title: "Item",
        key: "item_code",
        render: (___, record) => <div>
          Name: <strong>{record?.item_name}</strong><br></br>
          Code: <strong>{record?.item_code}</strong><br></br>
          {Number(record?.category_id) === 1 && <>Brand: <strong>{record?.item_brand}</strong><br></br></>}
        </div>,
        width: '40%'
      },
      {
        title: "Item Presentation",
        key: "item_presentation",
        render: (___, record) => <div>
          Category: <strong>{record?.category_name}</strong><br></br>
          {Number(record?.category) === 1 && <>
            Dosage: <strong>{record?.item_dosage}</strong><br></br>
            Presentation: <strong>{record?.item_presentation}</strong>
          </>}
        </div>
      },
      {
        title: "Data Added",
        key: "item_date",
        render: (___, record) => <strong>{record?.item_date ? (new Date(record?.item_date)).toDateString() : 'N/A'}</strong>
      },
      {
        title: "Status",
        key: "item_status",
        render: (___, record) => (Boolean(record?.item_status) ? <strong>Available</strong> : <strong>Not Available</strong>)
      }
    ]
  };

  const columns = [
    {
      title: <Row gutter={5} justify={'space-around'} align={'middle'}>
        <Col span={1}>Search:</Col>
        <Col span={4}><Select defaultValue={searchParam?.target} onChange={(e) => setSearchParam(d => ({ ...d, target: e }))} style={{ width: '100%' }} options={searchTarget} /></Col>
        <Col span={user && [1, 2].includes(Number(user.role["staffRoleId"])) ? 14 : 19}>
          <Search onSearch={showDetail} value={searchParam?.search} allowClear placeholder="Enter Search Keyword..." onChange={(e) => setSearchParam(d => ({ ...d, search: e.target.value }))} />
        </Col>
        {user && [1, 2].includes(Number(user.role["staffRoleId"])) ?
          <Col span={5} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button type="link" icon={<UserAddOutlined />} onClick={() => setShowCreateUserModal(true)}>Add Client(s)</Button>
            <Divider type="vertical" style={{ borderColor: 'black' }} />
            <Button type="link" icon={<BiBuildings />} onClick={() => setShowCreateFacilityModal(true)}>Add Facility</Button>
          </Col> : null
        }
      </Row>,
      children: mappings[searchParam?.target || tableToRender]
    }
  ];

  const [showClient, setShowClient] = useState(false);
  const [showEnrollee, setShowEnrollee] = useState(false);
  const [showClientEnrollee, setShowClientEnrollee] = useState(false);
  const [showFacility, setShowFacility] = useState(false);
  const [rowData, setRowData] = useState({});

  const rowSetting = async (record, target = null) => {
    setRowData(record)
    switch (target) {
      case 'corporations': return;
      case 'clients':
        setShowClient(true);
        break
      case 'enrollees':
        setShowEnrollee(true);
        break
      case 'client_enrollees':
        setShowClientEnrollee(true);
        break
      case 'facilities':
        setShowFacility(true);
        break
      default: return;
    }
    // setShow(true)
  };

  const handleCancelEnrollees = () => {
    setShowEnrollee(!showEnrollee);
  }

  const handleCancelFacilities = () => {
    setShowFacility(!showFacility);
  }

  const handleCancelClientEnrollees = () => {
    setShowClientEnrollee(!showClientEnrollee)
  }

  return (
    <div style={{ margin: '20px 0' }}>
      <SearchableTable columns={columns} dataSource={searchResult[searchParam?.target]} loading={loading} renderRow={() => null} />
      {/* Clients Account Creation Modal */}
      <Modal
        title={
          <Row>
            <Col>Create Policy Account</Col>
          </Row>
        }
        centered
        open={showCreateUserModal && !showCreateFacilityModal}
        onOk={() => { }}
        onCancel={() => setShowCreateUserModal(false)}
        footer={
          user && [1, 2].includes(Number(user.role["staffRoleId"])) ? (
            <>
              <Button disabled={loadingCreateUser} onClick={() => setShowBatchUserModal(true)}>
                {loadingCreateUser ? <LoadingOutlined /> : <>Batch Policy Upload</>}
              </Button>
              <Button disabled={loadingCreateUser} onClick={() => setShowBatchEnroleeModal(true)}>
                {loadingCreateUser ? <LoadingOutlined /> : <>Batch Enrolee Upload</>}
              </Button>
              <Button disabled={loadingCreateUser} onClick={handleOk}>
                {loadingCreateUser ? <LoadingOutlined /> : <>Create Account</>}
              </Button>
              <Button
                type="dashed"
                disabled={loadingCreateUser}
                danger
                onClick={() => setShowCreateUserModal(false)}
              >
                Close
              </Button>
            </>) : null
        }
        width={800}
        destroyOnClose
      >
        <Row>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row>
                  <Col span={8}>Insurance Package:</Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          insurance_package: e,
                        }))
                      }
                      value={clientRegistrationData?.insurance_package}
                      style={{ width: "100%", marginRight: "10px" }}
                      options={packages}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Policy Type: </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      onChange={(e) => {
                        setPremiumType(e);
                        setClientRegistrationData((d) => ({
                          ...d,
                          premium_type: e,
                        }));
                      }}
                      value={
                        clientRegistrationData?.premium_type || premiumType
                      }
                      style={{ width: "100%", marginRight: "10px" }}
                      options={policy_type}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Plan Category: </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          category: e,
                        }))
                      }
                      value={clientRegistrationData?.category}
                      style={{ width: "100%", marginRight: "10px" }}
                      options={category}
                    />
                  </Col>
                </Row>
                {clientRegistrationData.category === "corporate" ||
                  clientRegistrationData.category === "referal" ? (
                  clientRegistrationData.category === "corporate" ? (
                    <Row>
                      <Col span={8}>Association Code:</Col>
                      <Col span={16}>
                        <Input
                          disabled={loadingCreateUser}
                          suffix={
                            !validSocial && validatedSocial ? (
                              <CloseOutlined style={{ color: "red" }} />
                            ) : validatingSocial ? (
                              <LoadingOutlined style={{ color: "blue" }} />
                            ) : validSocial && validatedSocial ? (
                              <CheckOutlined style={{ color: "green" }} />
                            ) : null
                          }
                          onBlur={handleFocusOut}
                          onChange={(e) =>
                            setClientRegistrationData((d) => ({
                              ...d,
                              social_no: e.target.value,
                            }))
                          }
                          value={clientRegistrationData?.social_no}
                          placeholder="23SWIWQQ"
                          style={{ width: "100%", marginRight: "10px" }}
                        />
                        <span>
                          {clientRegistrationData.category === "corporate" &&
                            !validSocial ? (
                            <>{socialwarn}</>
                          ) : null}
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={8}>Referal Code:</Col>
                      <Col span={16}>
                        <Input
                          disabled={loadingCreateUser}
                          suffix={
                            !validSocial && validatedSocial ? (
                              <CloseOutlined color="red" />
                            ) : validatingSocial ? (
                              <LoadingOutlined color="blue" />
                            ) : !validSocial && validatedSocial ? (
                              <CheckOutlined color="green" />
                            ) : null
                          }
                          onBlur={handleFocusOut}
                          onChange={(e) =>
                            setClientRegistrationData((d) => ({
                              ...d,
                              social_no: e.target.value,
                            }))
                          }
                          value={clientRegistrationData?.social_no}
                          placeholder="23SWIWQQ"
                          style={{ width: "100%", marginRight: "10px" }}
                        />
                        <span>
                          {clientRegistrationData.category === "referal" &&
                            !validSocial ? (
                            <>{socialwarn}</>
                          ) : null}
                        </span>
                      </Col>
                    </Row>
                  )
                ) : null}
                <Row>
                  <Col span={8}>Full Name:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          name: e.target.value,
                        }))
                      }
                      autoFocus
                      value={clientRegistrationData?.name}
                      placeholder="Musa Umar"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Additional Dependents:</Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      style={{ width: "100%", marginRight: "10px" }}
                      onChange={handleDependent}
                      value={addedHeads}
                    >
                      <Option value={0}>0</Option>
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3</Option>
                      <Option value={4}>4</Option>
                      <Option value={5}>5</Option>
                      <Option value={6}>6</Option>
                      <Option value={7}>7</Option>
                      <Option value={8}>8</Option>
                      <Option value={9}>9</Option>
                      <Option value={10}>10</Option>
                    </Select>
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bolder",
                        color: "skyblue",
                        lineHeight: "1px",
                      }}
                    >
                      Use this if ther are additional enrollees apart from the
                      provisonal enrollee size: Family = 6 Enrollees; Individual
                      = 1 Enrollee
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>State of Residence: </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      style={{ width: "100%", marginRight: "10px" }}
                      onChange={(e) => {
                        setClientRegistrationData((d) => ({ ...d, state: e }));
                      }}
                      value={clientRegistrationData?.state}
                      options={states}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>City of Residence: </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          city: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.city}
                      placeholder="Ogidilu"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Email:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      type="email"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          email: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.email}
                      placeholder="example@gmail.com"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Confirm Email:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      type="email"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          confirm_email: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.confirm_email}
                      placeholder="example@gmail.com"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Phone Number:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      type="phone"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          phone: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.phone}
                      placeholder="0801 234 5678"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Confirm Phone Number</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      type="phone"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          confirm_phone: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.confirm_phone}
                      placeholder="0801 234 5678"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Password:</Col>
                  <Col span={16}>
                    <Input.Password
                      disabled={loadingCreateUser}
                      placeholder="*********"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          password: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.password}
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Confirm Password:</Col>
                  <Col span={16}>
                    <Input.Password
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          confirm_password: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.confirm_password}
                      placeholder="*********"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      {/* Facility Onboarding Modal */}
      <Modal
        title="Onboard New Facility"
        centered
        open={showCreateFacilityModal && !showCreateUserModal}
        onOk={() => { }}
        onCancel={() => setShowCreateFacilityModal(false)}
        footer={
          user && [1, 2].includes(Number(user.role["staffRoleId"])) ? (
            <>
              <Button disabled={loadingCreateFacility} onClick={() => setShowBatchProviderModal(true)}>
                {loadingCreateFacility ? <LoadingOutlined /> : <>Batch Upload</>}
              </Button>
              <Button disabled={loadingCreateFacility} onClick={saveProvider}>
                {loadingCreateFacility ? <LoadingOutlined /> : <>Add Facility</>}
              </Button>
              <Button
                type="dashed"
                disabled={loadingCreateFacility}
                danger
                onClick={() => setShowCreateFacilityModal(false)}
              >
                Close
              </Button>
            </>) : null
        }
        width={800}
        destroyOnClose
      >
        <Row>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    Facility Name <span style={{ color: "red" }}>*</span>:{" "}
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      autoFocus
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_name: e.target.value,
                        }))
                      }
                      value={facilityRegistrationData?.facility_name}
                      style={{ width: "100%" }}
                      placeholder="What is the full name of the facilty?"
                    />
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: "bolder",
                        color: "skyblue",
                      }}
                    >
                      Kindly include the branch city or area if its a branch of
                      an already onboarded facility
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility Type <span style={{ color: "red" }}>*</span>:{" "}
                  </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_type}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_type: e,
                        }))
                      }
                      options={facilityType}
                      style={{ width: "100%" }}
                      placeholder="Select Facility Type e.g. Hospital, Dental, Laboratory"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility Plans <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_plan_type}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_plan_type: e,
                        }))
                      }
                      options={[
                        ...packages,
                        { label: "All Plans", value: "all plans" },
                      ]}
                      style={{ width: "100%" }}
                      placeholder="On what plan is the facility onboarded?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility State <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_state}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_state: e,
                        }))
                      }
                      options={states}
                      style={{ width: "100%" }}
                      placeholder="What State is the facility located?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility City <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_city}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_city: e.target.value,
                        }))
                      }
                      placeholder="What city, town or LGA is the facility located?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility Address <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <TextArea
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_address}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_address: e.target.value,
                        }))
                      }
                      rows={3}
                      placeholder="How can we get to this facility"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Constact Person:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_designation}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_designation: e.target.value,
                        }))
                      }
                      rows={3}
                      placeholder="Who is the contact person for this facility?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility Email <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_email}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_email: e.target.value,
                        }))
                      }
                      placeholder="What is the email of this facility?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Phone Number <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_phone}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_phone: e.target.value,
                        }))
                      }
                      placeholder="What is the phone number of this facility?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Onboarded By:</Col>
                  <Col span={16}>
                    <Input
                      // disabled={loadingCreateFacility}
                      disabled
                      value={facilityRegistrationData?.facility_staff_id}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_staff_id: e.target.value,
                        }))
                      }
                      placeholder="Who onboarded this facility?"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <CustomFileUploadModal
        visible={showBatchUserModal}
        onOk={handleBatchUploadUsers} loading={uploading}
        onCancel={() => { setShowBatchUserModal(false); setUploading(false) }}
        name={'Bulk User Onboarding'} showDownload={true} id={'upload_batch_user'}
        downloadHandler={() => writeArrayToCSV(clientsTable, 'policy_registration_dat_format.csv', '|')}
      />

      <CustomFileUploadModal
        visible={showBatchProviderModal}
        onOk={saveBatchProvider}
        onCancel={() => { setShowBatchProviderModal(false); setUploading(false) }} loading={uploading}
        name={'Bulk Facility Onboarding'} showDownload={true} id={'upload_batch_facilities'}
        downloadHandler={() => writeArrayToCSV(providersTable, 'providers_registration_dat_format.csv', '|')}
      />

      <CustomFileUploadModal
        visible={showBatchEnroleeModal}
        onOk={saveBatchEnrollee}
        onCancel={() => { setShowBatchEnroleeModal(false); setUploading(false) }} loading={uploading}
        name={'Bulk Dependents Onboarding'} showDownload={true} id={'upload_batch_dependents'}
        downloadHandler={() => writeArrayToCSV(enrolleeTable, 'enrollees_registration_dat_format.csv', '|')}
      />

      {showClient && (
        <PolicyHolderModal
          display={showClient}
          data={rowData}
          action={showDetail}
          showAllActions={true}
          onclose={() => setShowClient(false)}
          viewOnly={canEdit()}
        />
      )}

      {(showClientEnrollee) ?
        <ListOfDependent
          dependentList={[]}
          policy={rowData}
          // command={() => enrolleeListCommand(rowData)}
          showModal={showClientEnrollee}
          closeModal={handleCancelClientEnrollees}
          viewOnly={canEdit()}
        /> : null
      }

      {(showEnrollee) && <EnroleeBiodataModal data={rowData} display={showEnrollee} action={handleCancelEnrollees} command={showDetail} source={1} viewOnly={canEdit()} />}

      {(showFacility) && <FacilityData data={rowData} display={showFacility} action={handleCancelFacilities} command={showDetail} source={1} viewOnly={canEdit()} />}

    </div>
  );
};


export default ClientVerufyHook;