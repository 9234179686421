import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from "react-router-dom"

import {
  Select, Button, Modal, Input, Spin, Row, Col, notification, Typography,
  Space, Divider, Table, InputNumber, Form, Popconfirm
} from 'antd';

import { PlusOutlined, ArrowLeftOutlined, MinusCircleOutlined, Loading3QuartersOutlined, LoadingOutlined, ReloadOutlined, CloseCircleOutlined, DeleteOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import CurrencyFormat from 'react-currency-format';

import lib, { fetchPaginatedData, myRequest, myRequestObj } from "../../assets/utils/lib";

import './clients.css';
import { useAuth } from "../../assets/utils/useAuth";
import Helpers from "../../assets/utils/Helpers";
import PopConfirm from "../compoinents/PopConfirm";
import { RiAddLine } from "react-icons/ri";

const { TextArea } = Input;
const { Option } = Select;

const customTableStyle = {
  borderCollapse: 'collapse',
};

const PlanDetail = () => {
  const { plan } = useParams()
  const { set, user } = useAuth();
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);

  const [PlanObj, setPlanObj] = useState(state);
  const [visiblePlanEdit, setVisiblePlanEdit] = useState(false);
  const [planTypes, setPlanTypes] = useState([]);

  const [visiblePlanSummary, setVisiblePlanSummary] = useState(false);
  const [PlanToSummary, setPlanToSummary] = useState({});
  const [summaries, setSummaries] = useState([]);

  const [categories, setCategories] = useState([]);
  const [categoriesToEdit, setCategoriesToEdit] = useState({});
  const [planCategories, setPlanCategories] = useState([]);
  const [visibleAddCategory, setVisibleAddCategory] = useState(false);
  const [visibleEditCategory, setVisibleEditCategory] = useState(false);

  const [planCategoryToAdd, setPlanCategoryToAdd] = useState({})

  const [, setLoadingDetail] = useState(false);
  const [PlanDetails,] = useState(false);

  const [reloadData, setReloadData] = useState(false)

  const [careTypes, setCareTypes] = useState([]);
  const [, setLoadingCareCategory] = useState(false);
  const [loadingSummaries, setLoadingSummaries] = useState(false);

  const [planLimits, setPlanLimits] = useState([]);
  const [viewPlanLimits, setViewPlanLimits] = useState(false);
  const [planLimitUpdateData, setPlanLimitUpdateData] = useState({});
  const [viewAddLimits, setViewAddLimits] = useState(false);

  const [formSummary] = Form.useForm();

  const updatePlan = (data = false) => {
    if (!data) {
      notification.warning({ message: 'No Data' });
      return;
    } else {
      let changed = false;
      for (let key of Object.keys(data)) {
        if (key.includes('types_')) {
          delete data[key]
          continue;
        }

        if (data[key] !== state[key]) {
          changed = true;
        }
      }
      if (!changed) {
        notification.warning({ message: 'No Changes were Made' });
        return;
      }
    }

    setLoading(true)
    lib.updatePlan(data)
      .then((resp) => resp.data)
      .then((plan) => {
        if (plan?.status === 'error') {
          notification.error({ message: plan.msg, placement: 'topLeft' })
        } else if (Number(plan?.error) === 0) {
          notification.success({ message: plan.message, placement: 'topLeft' })
          setVisiblePlanEdit(false)
          setPlanObj({ ...state, ...data })
          navigate(`/plans/${btoa(state.plan_id)}`, { state: { ...state, ...data } })
        } else {
          notification.error({ message: plan.message, placement: 'topLeft' })
        }
      })
      .finally(() => setLoading(false))
  }

  const getPlanTypes = async () => {
    lib.getPlanTypes()
      .then((resp) => resp.data)
      .then((types) => types?.data?.map(planType => { return { value: planType.plans_types_id, label: planType.plans_types_name } }))
      .then(render => setPlanTypes((render)))
  }

  const updatePlanStaus = (item, value) => {
    setLoading(true);
    const data = { plan_status: value, plan_id: item.plan_id };
    updatePlan(data);
  }

  const planDetailCatgeory = (data) => {
    setLoadingDetail(true)
    myRequest('post', 'planDetailCategories', { id: data.plan_id })
      .then((resp) => resp.data)
      .then((detail) => detail?.data)
      .then(render => { setPlanCategories(render); })
      .finally(() => setLoading(false))
  }

  const getCategories = () => {
    lib.getCategories()
      .then((resp) => resp.data)
      .then((categories) => categories?.data)
      .then(render => setCategories(render))
  }

  const getPlanLimits = (payload) => {
    myRequestObj('planLimits').post(payload)
      .then((resp) => resp.data)
      .then((categories) => categories?.data)
      .then(render => setPlanLimits(render))
  }

  const addPlanLimits = (payload) => {
    console.log(payload)
    myRequestObj('savePlanLimit').post(payload)
    .then((resp) => resp.data)
    .then((data) => {
      if (Number(data?.error) === 0) {
        notification.success({ message: data?.message })
        addPlanLimitsViewHandler()
        getPlanLimits({ id: state?.plan_id })
      } else {
        notification.error({ message: data?.message })
      }
    })
  }

  const updatePlanLimits = (payload) => {
    myRequestObj('updatePlanLimit').post(payload)
      .then((resp) => resp.data)
      .then((data) => {
        if (Number(data?.error) === 0) {
          notification.success({ message: data?.message })
          getPlanLimits({ id: state?.plan_id })
          addPlanLimitsViewHandler()
        } else {
          notification.error({ message: data?.message })
        }
      })
  }

  const deletePlanLimits = (payload) => {
    myRequestObj('deletePlanLimit').post(payload)
      .then((resp) => resp.data)
      .then((data) => {
        if (Number(data?.error) === 0) {
          notification.success({ message: data?.message })
          getPlanLimits({ id: state?.plan_id })
          addPlanLimitsViewHandler()
        } else {
          notification.error({ message: data?.message })
        }
      })
  }

  const addCategoryToPlan = () => {
    const keys = ['price', 'category', 'care_type'];
    for (let k of keys) {
      if (!Object.keys(planCategoryToAdd).includes(k) || !planCategoryToAdd[k]) {
        notification.warning({ message: 'An important field is empty. If this persists, consider clicking the red back button, then procceed as before.' })
        return;
      }
    }

    // if()
    const data = { plan: state.plan_id, categories: [planCategoryToAdd] }
    setLoading(true)
    myRequest('post', 'relatePlanCategories', data)
      .then(resp => resp.data)
      .then(data => {
        if (Number(data?.error) === 0) {
          setVisibleAddCategory(false)
          notification.success({ message: data?.message })
          planDetailCatgeory(state)
        } else {
          notification.error({ message: data?.message })
        }
      })
      .finally(() => {
        setPlanCategoryToAdd({});
        setLoading(false)
      })
  }

  const updatePlanCategoryMap = () => {
    const data = { id: categoriesToEdit.plans_plans_category_id, cost: categoriesToEdit.plan_plan_category_cost, care_type: categoriesToEdit.plans_plans_category_care_type }
    setLoading(true)
    myRequest('post', 'updatPlanCategoryMap', data)
      .then((resp) => resp.data)
      .then((resp) => {
        if (!resp.error) {
          setPlanCategories([])
          planDetailCatgeory(state)
          notification.success({ message: resp.message })
          setVisibleEditCategory(false)
        }
      })
      .finally(() => setLoading(false))
  }

  const deletePlanCategoryMap = () => {
    const data = { id: categoriesToEdit.plans_plans_category_id }
    setLoading(true)
    myRequest('post', 'removePlanCategoryMap', data)
      .then((resp) => resp.data)
      .then((resp) => {
        if (!resp.error) {
          setPlanCategories([])
          planDetailCatgeory(state)
          notification.success({ message: resp.message })
          setVisibleEditCategory(false)
          setReloadData(true)
        }
      })
      .finally(() => setLoading(false))
  }

  const getSummary = () => {
    setLoadingSummaries(true);
    myRequest('post', 'planSummary', PlanToSummary)
      .then((resp) => resp?.data)
      .then((detail) => setSummaries(detail?.data ?? []))
      .finally(() => setLoadingSummaries(false))
  }

  const handlePanSummaryClosed = e => {
    setVisiblePlanSummary(false)
    setSummaries([])
    formSummary.setFieldsValue({
      summaries: [],
    });
  }

  const handlePlanSummarySubmit = e => {
    formSummary.validateFields().then(values => {
      // console.log('value', {...values, ...PlanToSummary});
      if (!values['summaries']?.length) {
        setVisiblePlanSummary(false);
        setSummaries([])
        return;
      }
      setLoadingSummaries(true)
      myRequest('post', 'addPlanSummary', { ...values, ...PlanToSummary })
        .then(response => response.data)
        .then(res => {
          if (res?.error) {
            notification.error({ message: res?.message, placement: 'topLeft' })
          } else {
            notification.success({ message: res?.message, placement: 'topLeft' })
            formSummary.setFieldValue('summaries',)
          }
        })
        .finally(() => {
          getSummary()
          setLoadingSummaries(false)
        })
    })
      .catch(e => {
        notification.error({ message: 'Form Fields Error: Kindly remove empty fields', placement: 'topLeft' })
      })
  }

  const removeSummary = id => {
    setLoadingSummaries(true)
    myRequest('post', 'removePlanSummary', id)
      .then(response => response.data)
      .then(res => {
        if (res?.error) {
          notification.error({ message: res?.message, placement: 'topLeft' })
        } else {
          notification.success({ message: res?.message, placement: 'topLeft' })
        }
      })
      .finally(() => {
        getSummary()
        setLoadingSummaries(false)
      })
  }

  useEffect(() => {
    visiblePlanSummary && getSummary();
  }, [visiblePlanSummary]) //eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (user) {
      if (![1, 5].includes(Number(user.role['staffRoleId']))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission',
          placement: 'topLeft'
        })
        navigate('/login')
      }
    } else {
      navigate('/login')
    }
  })

  useEffect(() => {
    if (!state || !plan) navigator('/plans')
    // console.log(atob(plan), state)
    getPlanTypes();
    getCategories();
    planDetailCatgeory(state)
    getPlanLimits({ id: state?.plan_id });
    fetchCareTypesList()
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (PlanDetails) {
      PlanDetails?.categories && setPlanCategories(PlanDetails.categories);
    }
  }, [PlanDetails])

  // useEffect(() => {
  //   if (visibleAddCategory || visibleEditCategory) fetchCareTypesList()
  // }, [visibleAddCategory, visibleEditCategory])

  const handleRowClick = (record) => {
    setVisiblePlanEdit(true)
    setPlanToSummary(record);
  };

  const rowProps = (record) => {
    return {
      onClick: () => handleRowClick(record)
    };
  };

  const columnConf = [
    {
      title: 'Plan Name',
      key: 'plan_name',
      dataIndex: 'plan_name',
      width: '25%'
    },
    {
      title: 'Plan Unit Cost',
      key: 'plan_cost',
      dataIndex: 'plan_cost',
      width: '15%',
      render: (_) => <CurrencyFormat value={_} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
    },
    {
      title: 'Plan Family Cost',
      key: 'plan_six_cost',
      dataIndex: 'plan_six_cost',
      width: '15%',
      render: (_) => <CurrencyFormat value={_} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
    },
    // {
    //   title: 'In-Patient Limit',
    //   key: 'in_patient_limit',
    //   dataIndex: 'in_patient_limit',
    //   width: '10%'
    // },
    // {
    //   title: 'Out-Patient Limit',
    //   key: 'out_patient_limit',
    //   dataIndex: 'out_patient_limit',
    //   width: '10%'
    // },
    {
      title: 'Plan Description',
      key: 'plan_description',
      dataIndex: 'plan_description',
      width: '20%'
    },
    {
      title: 'Plan Type',
      key: 'plans_types_name',
      dataIndex: 'plans_types_name',
      width: 'auto'
    },
    {
      title: 'Status',
      key: 'plan_status',
      dataIndex: 'plan_status',
      render : (_) => parseInt(_)? <CheckCircleFilled style={{fontSize: 25}}/> : <CloseCircleFilled style={{color: "#ef4d0e", fontSize: 25}}/>,
      width: 'auto',
    }
  ]

  const expandedRowRenderCategories = (record, index, indent, expanded) => {
    const handleButtonClick = e => {
      setVisibleAddCategory(true)
    }

    const handleRowClick = (record) => {
      console.log(record)
      setCategoriesToEdit(record)
      setCategoriesToEdit(record)
      setVisibleEditCategory(true)
    };

    const rowProps = (record) => {
      return {
        onClick: () => handleRowClick(record)
      };
    };

    const renderHeader = () => (
      <>{
        planCategories.length ?
          <div style={{ display: 'flex' }}>
            <Button type="primary" onClick={handleButtonClick} disabled={loading}>
              Add A Plan Category
            </Button>
          </div> : null
      }</>
    );

    const customLocale = {
      emptyText: <div>
        <p>No data available</p>
        <Button type="primary" onClick={handleButtonClick} disabled={loading}> Add A Plan Category</Button>
      </div> // Custom empty message
    };

    const columns = [
      { title: 'Categories Name', dataIndex: 'plans_categories_name', key: 'plans_categories_name', width: '25%' },
      { title: 'Categories Cost', dataIndex: 'plan_plan_category_cost', key: 'plan_plan_category_cost', width: '15%', render: (_) => <CurrencyFormat value={_} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> },
      { title: 'Categories Description', dataIndex: 'plans_categories_description', key: 'plans_categories_description', width: '60%' },
    ];

    return <Table columns={columns} dataSource={planCategories} pagination={false} indentSize={indent} expandable={{
      indentSize: 0,
      expandedRowRender: record => <CategoryItems record={record} plan={state} reloadData={reloadData} action={(e) => setReloadData(e || false)} />,
      defaultExpandedRowKeys: ['0'],
      defaultExpandAllRows: true
    }}
      style={customTableStyle}
      summary={renderHeader}
      locale={customLocale}
      onRow={rowProps}
    />;
  };

  async function fetchCareTypesList(payload = {}) {
    setLoadingCareCategory(true);
    const $allData = []
    try {
      const generator = fetchPaginatedData('post', 'careTypesList', payload);

      for await (const data of generator) {
        $allData.push(...data);
      }
      setCareTypes($allData.map(item => ({ label: item?.checkin_type_name?.toUpperCase(), value: item?.checkin_type_id })));
    } catch (error) {
      console.error('Error fetching care categories:', error);
    } finally {
      setLoadingCareCategory(false);
      return $allData;
    }
  }

  const planLimitsHandler = () => {
    setViewPlanLimits(!!!viewPlanLimits);
  }

  const addPlanLimitsViewHandler = () => {
    setViewAddLimits(!!!viewAddLimits);
  }

  const careTypeOptionsForLimits = () => {
    const checkinTypeIdsWithLimits = new Set(planLimits.map(type => type?.checkin_types_id));
    return careTypes.filter(type => !checkinTypeIdsWithLimits.has(type?.value)) || [];
  }

  const cancleLimitAdd = () => {
    viewAddLimits && addPlanLimitsViewHandler()
    setPlanLimitUpdateData({});
  }

  return (
    <>
      <br></br>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button type="primary" danger onClick={() => navigate('/plans')} icon={<ArrowLeftOutlined />} style={{ display: 'flex', placeItems: 'center' }}>Back</Button>
      </div>

      <br></br>
      {
        state &&
        <>
          <Table
            columns={columnConf}
            dataSource={[state]}
            onRow={rowProps}
            rootClassName='row'
            pagination={false}
            expandable={
              {
                indentSize: 0,
                expandedRowRender: expandedRowRenderCategories,
                // defaultExpandedRowKeys: ['0'],
                defaultExpandAllRows: true
              }
            }
          />
        </>
      }

      <Modal
        open={viewPlanLimits}
        title={<strong>{PlanObj?.plan_name} Plan Limits</strong>}
        onOk={null}
        onCancel={planLimitsHandler}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <Button type="primary" onClick={getPlanLimits} disabled={loading} icon={<ReloadOutlined />}>Reload</Button>, */}
            {careTypeOptionsForLimits()?.length ? <Button type="dashed" danger onClick={addPlanLimitsViewHandler} disabled={loading} icon={<RiAddLine />}>Add Limit</Button> : null},
            <Button type="primary" danger onClick={planLimitsHandler} disabled={loading}>Close</Button>
          </div>
        ]}
        destroyOnClose
      >
        {
          <Row gutter={[12, 12]}>
            {planLimits?.map(limit =>
              <Col span={24}>
                <Row gutter={[10, 10]}>
                  <Col span={10}>{limit?.checkin_type_name?.toUpperCase()}</Col>
                  <Col span={7}>
                    <InputNumber 
                      style={{width: '100%'}}  min={0} 
                      value={
                        (Number(planLimitUpdateData?.plans_limits_id) === Number(limit?.plans_limits_id)) ? 
                          planLimitUpdateData?.plans_limits_amount : limit?.plans_limits_amount
                      } 
                      onChange={e => setPlanLimitUpdateData(d => ({ ...d, plans_limits_id: limit?.plans_limits_id, plans_limits_amount: e }))}
                    />
                  </Col>
                  <Col span={6}>
                    {((Number(planLimitUpdateData?.plans_limits_amount) !== Number(limit?.plans_limits_amount)) && 
                      (Number(planLimitUpdateData?.plans_limits_id) === Number(limit?.plans_limits_id))) ? 
                      <PopConfirm
                        title={'Are you sure you want to save this change, it could affect the organizations operation'}
                        okText="Yes" cancelText="No"
                        onConfirm={() => updatePlanLimits({ id: planLimitUpdateData?.plans_limits_id, amount: planLimitUpdateData?.plans_limits_amount })}
                        triggerButtonText={"Update Limit"}
                        triggerButtonProps={{ type: 'dashed', danger: true, style: { width: '100%' } }}
                      /> : null
                    }
                  </Col>
                  <Col span={1}>
                    {
                      <PopConfirm
                        title={'Are you sure you want to remove this limit, it could affect the organizations operation'}
                        okText="Yes" cancelText="No"
                        onConfirm={() => deletePlanLimits({ id: limit?.plans_limits_id })}
                        triggerButtonText={""}
                        triggerButtonProps={{ type: 'text', danger: true, style: { width: '100%' }, icon: <DeleteOutlined /> }}
                      />
                    }
                  </Col>
                </Row>
              </Col>
            )}
            {
              (viewAddLimits && careTypeOptionsForLimits()?.length) ? <>
                <Col span={24}>
                  <Row gutter={[10,10]}>
                    <Col span={10}>
                      <Select
                        value={planLimitUpdateData?.checkin_types_id}
                        onChange={e => setPlanLimitUpdateData(d => ({ ...d, plans_id: state?.plan_id, checkin_types_id: e }))}
                        options={careTypeOptionsForLimits()}
                        style={{width: '100%'}}
                      />
                    </Col>
                    <Col span={6}>
                      <InputNumber style={{width: '100%'}} min={0} value={planLimitUpdateData?.plans_limits_amount} onChange={e => setPlanLimitUpdateData(d => ({ ...d, plans_limits_amount: e }))} />
                    </Col>
                    <Col span={5}>
                      <PopConfirm
                        title={'Are you sure you want to save this change, it could affect the organizations operation'}
                        okText="Yes" cancelText="No"
                        onConfirm={
                          () => addPlanLimits(
                            {
                              id: state?.plan_id,
                              amount: planLimitUpdateData?.plans_limits_amount,
                              checkinType: planLimitUpdateData?.checkin_types_id
                            }
                          )
                        }
                        triggerButtonText={"Add Limit"}
                        triggerButtonProps={{ type: 'dashed', danger: true, style: { width: '100%' } }}
                      />
                    </Col>
                    <Col span={2}>
                      <Button type="text" onClick={cancleLimitAdd} icon={<CloseCircleOutlined />}></Button>
                    </Col>
                  </Row>
                </Col>
              </> : null
            }
          </Row>
        }
      </Modal>

      {/* Plan Editing Modal */}
      <Modal
        open={visiblePlanEdit}
        title="Edit Plan"
        onOk={null}
        onCancel={() => setVisiblePlanEdit(false)}
        footer={[
          <Button type="primary" danger onClick={() => setVisiblePlanEdit(false)} disabled={loading}>Close</Button>,
          <Button disabled={loading} type="dashed" danger onClick={() => updatePlanStaus(PlanObj, parseInt(PlanObj?.plan_status) ? 0 : 1)} >{parseInt(PlanObj?.plan_status) ? 'Disable' : 'Enable'}</Button>,
          <Button type="dashed" onClick={() => setVisiblePlanSummary(true)} >Plan Summary</Button>,
          <Button type="dashed" onClick={planLimitsHandler} >Plan Limits</Button>,
          <Button type="primary" onClick={() => updatePlan(PlanObj)} loading={loading} disabled={loading}>Save</Button>
        ]}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row>
              <Col span={8}>Plan Name</Col>
              <Col span={16}>
                <Input type="text" style={{ width: '100%' }} value={PlanObj?.plan_name} onChange={e => setPlanObj(d => ({ ...d, plan_name: e.target.value }))} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>Plan Description</Col>
              <Col span={16}>
                <TextArea type="text" style={{ width: '100%' }} value={PlanObj?.plan_description} onChange={e => setPlanObj(d => ({ ...d, plan_description: e.target.value }))} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>Plan Type</Col>
              <Col span={16}>
                <Select style={{ width: '100%' }} value={PlanObj?.plan_type} onChange={e => setPlanObj(d => ({ ...d, plan_type: e }))} options={planTypes} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>Plan Cost</Col>
              <Col span={16}>
                <InputNumber style={{ width: '100%' }} value={PlanObj?.plan_cost} onChange={e => setPlanObj(d => ({ ...d, plan_cost: e }))} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>Plan Cost - Family</Col>
              <Col span={16}>
                <InputNumber style={{ width: '100%' }} value={PlanObj?.plan_six_cost} onChange={e => setPlanObj(d => ({ ...d, plan_six_cost: e }))} />
              </Col>
            </Row>
          </Col>
          {/* <Divider type="horizontal" orientationMargin={0} orientation="left">Limits</Divider>
          <Col span={24}>
            <Row>
              <Col span={8}>In-Patient Limit</Col>
              <Col span={16}>
                <InputNumber style={{width: '100%'}} value={PlanObj?.in_patient_limit} onChange={e => setPlanObj(d => ({...d, in_patient_limit: e}))} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>Out-Patient Limit</Col>
              <Col span={16}>
                <InputNumber style={{width: '100%'}} value={PlanObj?.out_patient_limit} onChange={e => setPlanObj(d => ({...d, out_patient_limit: e}))} />
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Modal>

      {/* Add Category to Plan Modal */}
      <Modal
        open={visibleAddCategory}
        title="Add A New Category"
        onOk={null}
        onCancel={() => setVisibleAddCategory(false)}
        footer={[
          <Button type="primary" danger disabled={loading} onClick={() => setVisibleAddCategory(false)} >Close</Button>,
          <Button type="primary" disabled={loading} onClick={addCategoryToPlan} >Save</Button>
        ]}
        style={{ maxHeight: 900 }}
        width={500}
        destroyOnClose
      >
        <Row key={'planCategoryAdd'} style={{ width: '100%' }} gutter={[12, 12]}>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Col span={8}><span>Category Name</span></Col>
              <Col span={16}>
                <Select
                  onChange={e => setPlanCategoryToAdd(d => ({ ...d, category: e }))}
                  style={{
                    width: '100%',
                  }}
                  value={planCategoryToAdd?.category}
                >
                  {categories.filter(cat => !planCategories.find(c => c.plans_categories_id === cat.plans_categories_id)).map((item) => (
                    <Option key={item.plans_categories_name} value={item.plans_categories_id} disabled={loading}>
                      {item.plans_categories_name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Col span={8}><span>Care Type:</span></Col>
              <Col span={16}>
                <Select
                  onChange={e => setPlanCategoryToAdd(d => ({ ...d, care_type: e }))}
                  style={{ width: '100%' }}
                  value={planCategoryToAdd?.care_type}
                  options={careTypes}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Col span={8}><span>Cover Limit:</span></Col>
              <Col span={16}><InputNumber value={planCategoryToAdd?.price} style={{ width: '100%' }} onChange={e => setPlanCategoryToAdd(d => ({ ...d, price: e }))} /></Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      {/* Edit Category to Plan Modal */}
      <Modal
        open={visibleEditCategory}
        title="Edit Category"
        onOk={null}
        onCancel={() => setVisibleEditCategory(false)}
        footer={[
          <Button type="primary" danger disabled={loading} onClick={() => setVisibleEditCategory(false)} >Close</Button>,
          <Popconfirm
            title={<>Delete the <strong>{categoriesToEdit?.plans_categories_name}</strong></>}
            description="Are you sure to delete this category?"
            onConfirm={deletePlanCategoryMap}
            okText="Yes"
            cancelText="No"
          >
            <Button type="dashed" danger disabled={loading}>Delete</Button>
          </Popconfirm>,
          <Button type="primary" disabled={loading} onClick={updatePlanCategoryMap} >Save</Button>
        ]}
        style={{ maxHeight: 900 }}
        width={500}
        destroyOnClose
      >
        <Row key={'planCategoryAdd'} style={{ width: '100%' }} gutter={[12, 12]}>
          <Col span={24}>
            <Row align={'top'}>
              <Col span={8}>Category Name:</Col>
              <Col span={16}>
                <Typography.Paragraph style={{ width: '100%' }} >{categoriesToEdit?.plans_categories_name}</Typography.Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row align={'top'}>
              <Col span={8}>Category Description:</Col>
              <Col span={16}>
                <Typography.Paragraph style={{ width: '100%' }} >{categoriesToEdit?.plans_categories_description}</Typography.Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Col span={8}><span>Care Type:</span></Col>
              <Col span={16}>
                <Select
                  onChange={e => setCategoriesToEdit(d => ({ ...d, plans_plans_category_care_type: e }))}
                  style={{ width: '100%' }}
                  value={categoriesToEdit?.plans_plans_category_care_type}
                  options={careTypes}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Col span={8}><span>Cover Limit:</span></Col>
              <Col span={16}><InputNumber disabled={loading} value={categoriesToEdit?.plan_plan_category_cost} style={{ width: '100%' }} onChange={e => setCategoriesToEdit(d => ({ ...d, plan_plan_category_cost: e }))} /></Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      {/* Perks Modal */}
      <Modal
        open={visiblePlanSummary}
        title={"Plan Perks - " + PlanToSummary?.plan_name}
        onOk={null}
        onCancel={handlePanSummaryClosed}
        footer={[
          <Button type="primary" danger onClick={handlePanSummaryClosed} disabled={loadingSummaries}>Close</Button>,
          <Button type="primary" onClick={handlePlanSummarySubmit} disabled={loadingSummaries} loading={loadingSummaries}>Save</Button>
        ]}
        style={{ maxHeight: 900 }}
        width={800}
      >
        <Form
          form={formSummary}
          name="dynamic_form_complex_2"
          autoComplete="off"
        // onValuesChange={(changed, values) => console.log(changed, values)}
        >
          {
            loadingSummaries && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Loading3QuartersOutlined spin style={{ fontSize: 50 }} />
            </div>
          }
          {summaries?.map((su, index) => (
            <Row key={index} style={{ width: '100%' }} gutter={[12, 12]}>
              <Col span={21}>
                <strong>
                  {su?.plan_summary_text}
                </strong>
              </Col>
              <Col span={3}>
                <Space align="baseline" style={{ width: '100%' }}>
                  <Button type="dashed" danger icone={<MinusCircleOutlined />} onClick={() => removeSummary({ id: su?.plan_summary_id })} disabled={loadingSummaries}>Remove</Button>
                  {/* <Button type="dashed" danger icone={<MinusCircleOutlined />} onClick={() => removeSummary({id: su?.plan_summary_id})} disabled={loadingSummaries}>Edit</Button> */}
                </Space>
              </Col>
            </Row>
          ))}
          <Divider />
          <Form.List name="summaries">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={field.key} style={{ width: '100%' }} gutter={[12, 4]}>
                    <Col span={23}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                        }
                        style={{
                          width: '70%',
                        }}
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            // label={`Summary ${++index}`}
                            name={[field.name, 'item']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing Value',
                              },
                            ]}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Input disabled={loadingSummaries} />
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Space align="baseline" style={{ width: '100%' }}>
                        <MinusCircleOutlined onClick={() => remove(field.name)} disabled={loadingSummaries} />
                      </Space>
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={loadingSummaries}>
                    Add Summary
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

const CategoryItems = ({ record, plan, reloadData, action }) => {
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [visibleAddItem, setVisibleAddItem] = useState(false);
  const [visibleEditItem, setVisibleEditItem] = useState(false);

  const [categoryItemToAdd, setCategoryItemToAdd] = useState({})
  const [categoryItemToEdit, setCategoryItemToEdit] = useState({})

  const planDetailItems = (data) => {
    setLoading(true)
    myRequest('post', 'planDetailItems', { id: data.plan_id, categoryId: record.plans_plans_category_id })
      .then((resp) => resp.data)
      .then((detail) => detail?.data)
      .then(render => setPlanItems(render))
      .finally(() => setLoading(false))
  }

  const addItemToCategory = () => {
    const keys = ['price', 'item'];
    for (let k of keys) {
      if (!Object.keys(categoryItemToAdd).includes(k) || !categoryItemToAdd[k]) {
        notification.warning({ message: 'An important field is empty. If this persists, consider clicking the red back button, then procceed as before.' })
        return;
      }
    }

    const data = { category: record.plans_categories_id, map: record.plans_plans_category_id, items: [categoryItemToAdd] }
    setLoading(true)
    myRequest('post', 'relateCatItems', data)
      .then(resp => resp.data)
      .then(data => {
        if (Number(data?.error) === 0) {
          setVisibleAddItem(false)
          notification.success({ message: data?.message })
          planDetailItems(plan)
        } else {
          notification.error({ message: data?.message })
        }
      })
      .finally(() => {
        setCategoryItemToAdd({});
        setLoading(false)
        setVisibleAddItem(false)
        getItems();
      })
  }

  const getItems = () => {
    lib.getItems()
      .then((resp) => resp?.data)
      .then((planTypes) => planTypes?.data)
      .then(render => setItems(render))
  }

  const updateCategoryItemMap = () => {
    const data = { id: categoryItemToEdit.plans_categories_items_id, cost: categoryItemToEdit.plans_categories_items_cost }
    setLoading(true)
    myRequest('post', 'updatCategoryItemMap', data)
      .then((resp) => resp?.data)
      .then((resp) => {
        if (!resp.error) {
          planDetailItems({ ...plan, ...record })
          notification.success({ message: resp?.message })
          setVisibleEditItem(false)
        }
      })
      .finally(() => setLoading(false))
  }

  const handleRowClick = (record) => {
    setCategoryItemToEdit(record)
    setVisibleEditItem(true)
  };

  const rowProps = (record) => {
    return {
      onClick: () => handleRowClick(record)
    };
  };

  const deleteCategoryItemMap = () => {
    const data = { id: categoryItemToEdit.plans_categories_items_id }
    setLoading(true)
    myRequest('post', 'removeCategoryItemMap', data)
      .then((resp) => resp?.data)
      .then((resp) => {
        if (!resp?.error) {
          planDetailItems({ ...plan, ...record })
          notification.success({ message: resp?.message })
          setVisibleEditItem(false)
          action(true)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    planDetailItems({ ...plan, ...record })
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    visibleAddItem && getItems()
  }, [visibleAddItem])

  useEffect(() => {
    reloadData && planDetailItems({ ...plan, ...record })
    action()
  }, [reloadData, action]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleButtonClick = e => {
    setVisibleAddItem(true)
  }

  const data = planItems.filter(item => record.plans_categories_id === item.plans_categories_items_category_id);

  const renderHeader = () => (
    <>{
      data.length ?
        <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
          <Button type="primary" onClick={handleButtonClick} disabled={!items.filter(item => !planItems.find(i => i.plans_items_id === item.plans_items_id))}>
            Add Benefit Item
          </Button>
        </div> : null
    }</>
  );

  const customLocale = {
    emptyText: <div>
      <p>No data available</p>
      <Button type="primary" onClick={handleButtonClick}>Add Benefit Item</Button>
    </div> // Custom empty message
  };

  const columns = [
    { title: 'Items Name', dataIndex: 'plans_items_name', key: 'plans_items_name', width: '40%' },
    { title: 'Items Description', dataIndex: 'plans_items_description', key: 'plans_items_description', width: '50%' },
    { title: 'Items Cost', dataIndex: 'plans_categories_items_cost', key: 'plans_categories_items_cost', width: '10%', render: _ => <CurrencyFormat value={_} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> },
  ];

  if (reloadData || loading) return <div style={{ display: "flex", placeContent: 'center' }}><Spin style={{ fontSize: 100 }} /></div>

  return (
    <>
      <Table columns={columns} dataSource={data} pagination={false} indentSize={0} locale={customLocale} summary={renderHeader} style={customTableStyle} onRow={rowProps} />
      <Modal
        open={visibleAddItem}
        title={<>Add Benefit Item - { }</>}
        onOk={null}
        onCancel={() => setVisibleAddItem(false)}
        footer={[
          <Button type="primary" danger onClick={() => setVisibleAddItem(false)} disabled={loading}>Close</Button>,
          <Button type="primary" onClick={addItemToCategory} disabled={loading}>Save</Button>
        ]}
        style={{ maxHeight: 900 }}
        width={800}
        destroyOnClose
      >
        <Row key={'planItemAdd'} style={{ width: '100%' }} gutter={[12, 12]}>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Col span={8}><span>Item Name</span></Col>
              <Col span={16}>
                <Select
                  onChange={e => setCategoryItemToAdd(d => ({ ...d, item: e }))}
                  style={{
                    width: '100%',
                  }}
                  value={categoryItemToAdd?.item}
                >
                  {items.filter(item => !planItems.find(i => i.plans_items_id === item.plans_items_id)).map((item) => (
                    <Option key={item.plans_items_id} value={item.plans_items_id} disabled={loading}>
                      {item.plans_items_name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Col span={8}><span>Cover Limit:</span></Col>
              <Col span={16}><InputNumber value={categoryItemToAdd?.price} style={{ width: '100%' }} onChange={e => setCategoryItemToAdd(d => ({ ...d, price: e }))} /></Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      {/* Edit Category to Plan Modal */}
      <Modal
        open={visibleEditItem}
        title="Edit Category"
        onOk={null}
        onCancel={() => setVisibleEditItem(false)}
        footer={[
          <Button type="primary" danger disabled={loading} onClick={() => setVisibleEditItem(false)} >Close</Button>,
          <Popconfirm
            title={<>Delete the <strong>{categoryItemToEdit?.plans_items_name}</strong></>}
            description="Are you sure to delete this item?"
            onConfirm={deleteCategoryItemMap}
            okText="Yes"
            cancelText="No"
          >
            <Button type="dashed" danger disabled={loading}>Delete</Button>
          </Popconfirm>,
          <Button type="primary" disabled={loading} onClick={updateCategoryItemMap} >Save</Button>
        ]}
        style={{ maxHeight: 900 }}
        width={500}
        destroyOnClose
      >
        <Row key={'categoryItemEdit'} style={{ width: '100%' }} gutter={[12, 12]}>
          <Col span={24}>
            <Row align={'top'}>
              <Col span={8}>Item Name:</Col>
              <Col span={16}>
                <Typography.Paragraph style={{ width: '100%' }} >{categoryItemToEdit?.plans_items_name}</Typography.Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row align={'top'}>
              <Col span={8}>Item Description:</Col>
              <Col span={16}>
                <Typography.Paragraph style={{ width: '100%' }} >{categoryItemToEdit?.plans_items_description}</Typography.Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'} style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Col span={8}><span>Cover Limit:</span></Col>
              <Col span={16}><InputNumber disabled={loading} value={categoryItemToEdit?.plans_categories_items_cost} style={{ width: '100%' }} onChange={e => setCategoryItemToEdit(d => ({ ...d, plans_categories_items_cost: e }))} /></Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default PlanDetail;