import PageTitleHook from "../pagetitle"
import { Button, Input, Row, Empty, Col, Typography, Avatar, Divider, Space, Drawer, notification } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from '../../assets/utils/useAuth';
import './checkins.css'
import { uri_img } from "../../assets/utils/http-request";
import lib, { myRequestObj } from "../../assets/utils/lib";
import Helpers from "../../assets/utils/Helpers";
import { useDispatch } from "react-redux";
import { selectclient } from "../../stores/verify";
import Loader from "../compoinents/loader";
import SearchWithAdvancedFeatures from "../compoinents/SearchWithDropdown";
import { ConsumedServices, EnrolleeConsumption } from "../compoinents/EnrolleeConsumption";
import PopConfirm from "../compoinents/PopConfirm";

const CheckinListHook = props => {
  const extended = props.extended !== undefined ? props.extended : true;

  const navigate = useNavigate();
  const { user, set } = useAuth();
  const [checkins, setCheckins] = useState([]);
  const [checkinsCopy, setCheckinsCopy] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState('');
  const dispatcher = useDispatch(selectclient);
  const [loading, setLoading] = useState(false)

  const checkinFetchInterval = useRef();
  const checkinFetchTimeout = useRef();
  const location = useLocation();
  const state = location?.state;

  useEffect(() => {
    if (!user) {
      navigate('/login')
    } else {
      if (![1, 2, 3, 4].includes(Number(user?.role['staffRoleId']))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission',
          placement: 'topLeft'
        })
        navigate('/dashboard')
      }
    }
  })

  useEffect(() => {
    if (state) {
      const { filterKeyword, item } = state;
      if (filterKeyword?.dropdown) {
        const payload = { keyword: filterKeyword?.dropdown === 'encounter_code' ? item?.code : item?.insurance_no, dropdown: filterKeyword?.dropdown };
        searchEncounters(payload);
      } else {
        state.filterKeyword = {
          ...filterKeyword,
          dropdown: "insurance_no",
        };
        const payload = { keyword: item?.insurance_no, dropdown: 'insurance_no' };
        searchEncounters(payload);
      }
    } else {
      loadAtiveManualEncounter();
      // fetchCheckins();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterClaimsList(filterKeyword);
  }, [filterKeyword]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterClaimsList = (e) => {
    const inputValue = e;
    if (inputValue) {
      setCheckinsCopy(checkins?.filter((val) => {
        return (
          val?.surname?.toString().toLowerCase().search(inputValue) >= 0 || val?.first_name?.toString().toLowerCase().search(inputValue) >= 0 ||
          val?.middle_name?.toString().toLowerCase().search(inputValue) >= 0 || val?.insurance_no?.toString().toLowerCase().search(inputValue) >= 0 ||
          val?.code?.toString().toLowerCase().search(inputValue) >= 0 || val?.checkin_date?.toString().toLowerCase().search(inputValue) >= 0 ||
          val?.checkinType?.toString().toLowerCase().search(inputValue) >= 0 || val?.policy_no?.toString().toLowerCase().search(inputValue) >= 0 ||
          val?.facility_name?.toString().toLowerCase().search(inputValue) >= 0 || val?.facility_state?.toString().toLowerCase().search(inputValue) >= 0
        )
      }))
    } else {
      setCheckinsCopy(checkins);
    }
  }

  const clearState = () => {
    navigate(location.pathname, { replace: true, state: null });
  }

  const fetchCheckins = (url = 'aCheckinList', payload = {}, clear = true) => {
    if (clear) clearState()
    setLoading(true);
    myRequestObj(url).post(payload)
      .then(res => res?.data)
      .then(data => {
        console.log(data)
        const dat = data?.data?.map(c => {
          const this_dat = {};
          Object.entries(c).forEach(col => {
            this_dat[col[0]] = col[1] || '';
          })
          return this_dat;
        })
        setCheckins(dat);
        setCheckinsCopy(dat);
      }).finally(() => setLoading(false));
  }

  // const updateCheckins = () => {
  //   clearIntervals();
  //   checkinFetchTimeout.current = setTimeout(() => {
  //     checkinFetchInterval.current = setInterval(() => {
  //       fetchCheckins()
  //     }, 3000)
  //   }, 1000)
  // }

  const clearIntervals = () => {
    clearTimeout(checkinFetchTimeout.current);
    clearInterval(checkinFetchInterval.current);
  }

  const searchEncounters = ({ keyword, dropdown }) => {
    setFilterKeyword({ keyword, dropdown });
    if (!dropdown && !keyword) {
      notification.error({ message: "What are you trying to search?" })
      return;
    }

    if (!dropdown) {
      notification.error({ message: "Search option must be delected!" })
      return;
    }

    if (!keyword) {
      notification.error({ message: "What do you want to search?" })
      return;
    }

    setLoading(true);
    fetchCheckins('encounters', { keyword, target: dropdown }, false)
  }

  const checkinTypes = {
    1: 'In-Patient', 2: 'Out-Patient'
  }

  const checkinSource = {
    0: 'Facilty', 1: 'Manual PA'
  }

  const loadAtiveManualEncounter = () => {
    fetchCheckins("aCheckinList", { status: 0, source: 1, target: 'manual' })
  }

  const checkout = async (record) => {
    const payload = { checkinId: record?.code }
    lib.checkout(payload)
    .then(resp => resp?.data)
    .then(data => {
       notification.success({ message: data?.message});
       if(!Number(data?.error)) loadAtiveManualEncounter();
     })
  }

  return (
    <>
      {extended ? <>
        <PageTitleHook title='Encounters' style={{ color: 'blue' }} />
        {/* <FloatButton tooltip={<div>Reload</div>} onClick={fetchCheckins} icon={<ReloadOutlined />}/> */}
        <Row gutter={[10, 10]} justify={'space-between'}>
          <Col xs={24} md={14} style={{ border: '2px solix black' }}>
            <SearchWithAdvancedFeatures
              dropdownOptions={[
                { value: 'insurance_no', label: 'Insurance No' },
                { value: 'encounter_code', label: 'Encounter Code' }
              ]}
              loading={loading}
              // onDropdownChange={(value) => console.log('Selected:', value)}
              onSearch={searchEncounters}
              // onSearchChange={(value) => console.log('Search input changed:', value)}
              showDropdown={true}
              allowClear={true}
              customButtonLabel="Search"
              defaultDropdownValue={state?.filterKeyword?.dropdown}
              defaultSearchValue={state?.filterKeyword?.dropdown === 'encounter_code' ? state?.item?.code : state?.item?.insurance_no}
              theme="dark"
              customStyles={{
                container: {
                  width: '100%',
                  // border: '1px solid black',
                },
                select: {
                  width: '100%', // Ensure it fills its parent container
                  borderRadius: '5px',
                  backgroundColor: 'white',
                },
                search: {
                  width: '100%', // Ensure it fills its parent container
                  border: 'none',
                  borderRadius: '5px',
                  backgroundColor: 'white',
                  '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                  },
                },
              }}
            />
            {/* 
              <Input.Search style={{ width: '100%' }} placeholder='Enter your filter words ...' onSearch={searchEncounters}
              enterButton="Search" size="large" value={filterKeyword} onChange={e => setFilterKeyword(e.target.value.toLowerCase())} /> 
            */}
          </Col>
          <Col xs={24} md={5}>
            {
              [1, 2].includes(Number(user?.role['staffRoleId'])) ?
                <Button type="primary" onClick={() => navigate('/file-encounter')} size="large" style={{ width: '100%' }}>File An Encounter</Button> : null
            }
          </Col>
          {/* <Col xs={24} md={3}>
            <Button type="primary" onClick={() => fetchCheckins()} color="grey" size="large" style={{ width: '100%' }}>All Encounters</Button>
          </Col> */}
          <Col xs={24} md={5}>
            <Button type="primary" onClick={loadAtiveManualEncounter} color="grey" size="large" style={{ width: '100%' }}>Active Encounters (*)</Button>
          </Col>
        </Row>
      </> : null
      }
      <table className="table align-middle mb-0 bg-white">

        <tbody>
          {
            loading ? <Loader size={100} color="#76c341" /> :
              (checkinsCopy?.length) ?
                checkinsCopy?.map(item => {
                  return (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={`${uri_img}${item?.pic_name}`}
                            alt={item?.first_name}
                            style={{ width: '45px', height: '45px' }}
                            className="rounded-circle"
                          />
                          <div className="ms-3">
                            <p className="fw-bold mb-1">{extended && item?.surname.toUpperCase()} {extended && ','} {item?.first_name} {extended && item?.middle_name}</p>
                            <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>{extended && 'Id No.:'}</span> {item?.insurance_no}</p>
                            <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>Checkin Type:</span> {checkinTypes[item?.checkinType] || 'N/A'} </p>
                          </div>
                        </div>
                      </td>
                      <td className=''>
                        {extended && <>
                          <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>Encounter Dates:</span></p>
                          <p className="text-muted mb-0"><strong style={{ fontWeight: 'bold' }}>Check-In: </strong>{(new Date(item?.checkin_date)).toDateString()}</p>
                          <p className="text-muted mb-0"><strong style={{ fontWeight: 'bold' }}>Check-Out:</strong> {item?.checkout_date ? (new Date(item?.checkout_date)).toDateString() : 'N/A'}</p>
                          <p className="text-muted mb-0"><strong style={{ fontWeight: 'bold' }}>Filed By:</strong> {checkinSource[item?.source] || 'N/A'}</p>
                        </>}
                      </td>
                      <td className=''>
                        <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>Care Provider:</span></p>
                        <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>{extended && 'Facility:'}</span> {item?.facility_name}</p>
                        {
                          extended && <>
                            <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>Facility Phone:</span> {item?.facility_phone}</p>
                            <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>Facility Address:</span> {item?.facility_address}</p>
                          </>
                        }
                      </td>
                      <td>
                        <Row gutter={[10, 10]}>
                          <Col span={24}>
                            <ClientDetails data={item} />
                          </Col>
                          {
                            item?.code && [1, 2].includes(Number(user?.role['staffRoleId'])) ?
                              <Col span={24}>
                                <Button type="primary" style={{ width: '100%' }} onClick={() => navigate(`/preauth-action?checkin=${item?.code}`, { state: { item, filterKeyword } })}>View PA</Button>
                              </Col> : null
                          }
                          {
                            (item?.source == 1 && item?.checkin_status == 0 && [1, 3].includes(Number(user?.role['staffRoleId']))) ?
                              <Col span={24}>
                                <PopConfirm title={<>Are you sure you want to enable this?</>}
                                  onConfirm={() => checkout(item)}
                                  confirmType="danger"
                                  triggerButtonText="Chockout"
                                  triggerButtonProps={{ type: 'primary', disabled: loading, style: {width: '100%'} }}
                                  placement="top"
                                  theme="danger"
                                  locale="en"
                                  debounceTime={500}
                                />
                                {/* <Button type="primary" style={{ width: '100%' }} onClick={() => checkout(item)}>Checkout</Button> */}
                              </Col> : null
                          }
                        </Row>
                      </td>
                    </tr>
                  )
                }) :
                <tr>
                  <td colSpan={4}>
                    <Empty
                      description={
                        <span>
                          There are no claims in this category
                        </span>
                      }
                      style={{ padding: 50, width: '100%' }}
                    >
                      {/* <Button type="primary">File a Claim Now</Button> */}
                    </Empty>
                  </td>
                </tr>
          }
        </tbody>
      </table>
    </>
  )
}

export const ClientDetails = ({ data }) => {
  const [, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const contoller = () => {
    setVisible(!!!visible)
  }

  return (
    <>
      <Button type="dashed" danger style={{ width: '100%' }} onClick={contoller}>View Client</Button>
      <Drawer
        open={visible}
        title={<>
          <Typography.Title level={5} style={{ margin: '0 auto' }}>
            <Space>
              <Space>
                <Avatar src={`${uri_img}${data.pic_name}`} style={{ marginRight: 20 }} />
              </Space>
              <Space direction="vertical" size={'small'}>
                <Space style={{ margin: '0 auto', padding: '0 auto', height: 'fit-content' }}>
                  {data.surname.toUpperCase().trim()}, {data.first_name.trim()} {data.middle_name.trim()}
                </Space>
                <Space style={{ marginLeft: '0 auto', fontWeight: '100', fontSize: 10, padding: '0 auto', height: 'fit-content' }}>
                  {data.code} - {(new Date(data.checkin_date)).toLocaleString()}
                </Space>
              </Space>
            </Space>
          </Typography.Title>
        </>}
        onOk={handleOk}
        onClose={contoller}
        closable
        // footer={[
        //   <Button key="back" onClick={handleCancel}>
        //     Close
        //   </Button>
        // ]}
        width={500}
      >
        <Typography.Title level={5} style={{ margin: '2px auto' }}>
          Bio-Data
        </Typography.Title>
        <Divider style={{ margin: '0 auto' }} />
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Role: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.role}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Insurance Number: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.insurance_no}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Policy Number: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.policy_no}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Gender: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.gender}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>State / LGA: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.state.toUpperCase()} / {data.lga.toUpperCase()}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Primiry Health Care: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.primary_health_facility}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Secondary Health Care: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.secondary_health_facility}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <EnrolleeConsumption userId={data?.insurance_no} enrolleeData={data} />
          </Col>
        </Row>
        <Divider style={{ margin: '5 0' }} />
        <Typography.Title level={5} style={{ margin: '2px auto' }}>
          Facility
        </Typography.Title>
        <Divider style={{ margin: '0 auto' }} />
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Facility: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.facility_name}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Facility Phone: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.facility_phone}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Facility Address: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.facility_address}
            </Typography.Title>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default CheckinListHook;