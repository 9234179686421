import { Modal, notification } from "antd";
import config from "./config";
import request, {headers, uri} from "./http-request";
import { WarningFilled } from "@ant-design/icons";

const lib = {};
const key = "notification";

const setHeader = () => {
  const user = JSON.parse(localStorage.getItem(config.key.user));
  return user
    ? {
        headers: {
          Authorization: "Bearer " + user?.jwt,
        },
      }
    : {};
};

lib.sendItemDoc = async (data) => myRequestObj(`saveItemDoc`).post(data);
  
lib.verifyPaymentFlutter = async (data) => myRequestObj(`verifyPayment`).post(data);

lib.registerDash = async (data) => myRequestObj(`regUserDash`).post(data)

lib.loginSpecial = async (data) => myRequestObj(`login&special`).post(data)

lib.login = async (data) => myRequestObj(`loginClaimsAdmin`).post(data)

lib.createUser = async (data) => myRequestObj(`aCreateUser`).post(data)

// API call for Admin Users - Aman Medicare
lib.readUser = async (data) => myRequestObj(`aUser`).post(data);

lib.updateProfile = async (data) => myRequestObj(`aChangeStatus`).post(data);

lib.departments = async () => myRequestObj(`departments`).post();

lib.roles = async () => myRequestObj(`roles`).post();

lib.reset = async (data) => myRequestObj(`reset`).post(data);

lib.enrollees = async (data) => myRequestObj(`enrollees`).post(data);

lib.principal = async (data) => myRequestObj(`pricipal`).post(data);

lib.saveNewProfile = async (data) => myRequestObj(`save-profile`).post(data);

// used for verifying clients from claims
lib.getClient = async (data) => myRequestObj(`validateUserClaims`).post(data);

lib.sendImage = async (data) => myRequestObj(`upload`).post(data);
  
// Search for users
lib.search = async (data) => myRequestObj(`search`).post(data);

// Search for users
lib.searchClients = async (data) => myRequestObj(`searchClients`).post(data);

// Search Only Enrolless
lib.searchEnrolee = async (data) => myRequestObj(`searchEnrollees`).post(data);

lib.checkin = async (data) => myRequestObj("clientCheckin").post(data);

lib.getClient = async (data) => myRequestObj("validateUserClaims").post(data);

// Search for providers
lib.searchProviders = async (data) => myRequestObj(`searchProviders`).post(data);

// Search Organizations
lib.searchOrganizations = async (data) => myRequestObj(`searchOrganization`).post(data);

// Search Costables
lib.searchCostables = async (data) => myRequestObj(`searchCostables`).post(data);

lib.getProviders = async (data) => myRequestObj(`getProviders`).post(data);

// Update facility information
lib.updateProvider = async (data) => myRequestObj(`update_facility`).post(data);

// Grab data for users
lib.grabData = async (data) => myRequestObj(`grabData`).post(data);

// Update for users
lib.updateUserData = async (data) => myRequestObj(`new_update`).post(data);

// Update for users
lib.updateUserDataCopy = async (data) => myRequestObj(`new_update_ad`).post(data);

// Update for users
lib.updateUserFinance = async (data) => myRequestObj(`updatePolicy`).post(data);

// Update for users
lib.unverifiedClients = async (data) => myRequestObj(`unverified`).post(data);

// Update for users
lib.unverifiedEnrollees = async (data) => myRequestObj(`unverified-enrollees`).post(data);

lib.checkin = async (data) => myRequestObj(`clientCheckin`).post(data);

lib.checkout = async (data) => myRequestObj(`checkout`).post(data);

lib.checkinList = async () => myRequestObj(`aCheckinList`).post();

lib.sendPA = async (data) => myRequestObj(`paRequest`).post(data);

lib.saveProvider = async (data) => myRequestObj(`add_facility`).post(data);

lib.getCostableCategories = async () => myRequestObj(`constableCategoriesList`).post();

lib.validatSocial = async (data) => myRequestObj(`validate_social`).post(data);

lib.checkMailAvailability = async (data) => myRequestObj(`checkEmailAvailable`).post(data);

lib.updateUserEmail = async (data) => myRequestObj(`updatePolicyEmail`).post(data);

// Organizatoins Management
// Get All Organizations
lib.orgs = async (data) => myRequestObj(`organizations`).post(data);

// Get All Organizations
lib.updateOrgDate = async (data) => myRequestObj(`updateOrgExpiry`).post(data);

// Get All Organizations
lib.updateOrgDate = async (data) => myRequestObj(`updateOrgExpiry`).post(data);

// Get All Organizations
lib.updateOrgData = async (data) => myRequestObj(`updateOrgData`).post(data);

// Get All Organizations
lib.createOrg = async (data) => myRequestObj(`createOrg`).post(data);

// Plan Management

/*
 *   Plans Designer
 */
// Get a list of plan types
lib.getPlanTypes = async (data = {}) => myRequestObj(`plan_type`).post(data);
  
// Get a list of plan types
lib.getPlans = async (data = {}) => myRequestObj(`plans`).post(data);

// Create New Plan
lib.createPlan = async (data = {}) => myRequestObj(`createPlan`).post(data);

// Update a plan
lib.updatePlan = async (data = {}) => myRequestObj(`updatePlan`).post(data);

// design a category
lib.designplan = async (data = {}) => myRequestObj(`relatePlanCategories`).post(data);

// design a category
lib.planDetails = async (data = {}) => myRequestObj(`planDetail`).post(data);

// Organization Plans
lib.orgPlans = async (data = {}) => myRequestObj(`orgPlans`).post(data);

// Submit Organization Plans
lib.submitOrgPlans = async (data = {}) => myRequestObj(`submitOrgPlans`).post(data);

// Remove Organization Plans
lib.removeOrgPlans = async (data = {}) => myRequestObj(`removeOrgPlan`).post(data);

/*
 *   Plan Category Designer
 */
// Get list of plan categories
lib.getCategories = async (data = {}) => myRequestObj(`categories`).post(data);
  
// Create a new category
lib.createCategory = async (data = {}) => myRequestObj(`createCategory`).post(data);
  
// Update a category
lib.updateCategory = async (data = {}) => myRequestObj(`updateCategory`).post(data);

// design a category
lib.designCategory = async (data = {}) => myRequestObj(`relateCatItems`).post(data);

// detail a category
lib.categoryDetails = async (data = {}) => myRequestObj(`categoryDetail`).post(data);
  
/*
 *   Plan Items Designer
 */
// Get a list of plan types
lib.getItems = async (data = {}) => myRequestObj(`items`).post(data);

// Create a plan item
lib.createItem = async (data = {}) => myRequestObj(`createItem`).post(data);
  
// update plan item
lib.updateItem = async (data = {}) => myRequestObj(`updateItem`).post(data);

// Plans Summary
lib.plansSummary = async (data = {}) => myRequestObj(`plansSummary`).post(data)

export const myTime = async () => myRequestObj('currentDate').post();

export const myRequest = async (type = "post", uri = "index", data = {}) => {
  let url = `?${uri}`;
  try {
    if (type === "get") {
      const resp = await request.get(url, data, setHeader());
      if (parseInt(resp?.data?.error)) {
        notification.error({
          key,
          message: resp?.data?.message,
          placement: "topLeft",
        });
        return { error: 1, data: [], message: resp?.data?.message };
      }
      return resp;
    } else {
      const resp = await request.post(url, data, setHeader());
      if (parseInt(resp?.data?.error)) {
        notification.error({
          key,
          message: resp?.data?.message,
          placement: "topLeft",
        });
        return { error: 1, data: [], message: resp?.data?.message };
      }
      return resp;
    }
  } catch (e) {
    notification.error({
      key,
      message: e?.response?.data?.msg || e?.message,
      placement: "topLeft",
    });

    return { status: "error", msg: e?.response?.data?.msg || e?.message };
  }
};

export const myRequestObj = (uri = "index") => {
  const url = `?${uri}`;
  return {
    get: async (data = {}) => {
      try {
        const resp = await request.get(url, data, setHeader());
        if (parseInt(resp?.data?.error)) {
          notification.error({
            key,
            message: resp?.data?.message,
            placement: "topLeft",
          });
          return { error: 1, data: [], message: resp?.data?.message };
        }
        return resp;
      } catch (e) {
        notification.error({
          key,
          message: e?.response?.data?.msg || e?.message,
          placement: "topLeft",
        });

        return { status: "error", msg: e?.response?.data?.msg || e?.message };
      }
    },
    post: async (data = {}) => {
      try {
        const resp = await request.post(url, data, setHeader());
        if (parseInt(resp?.data?.error)) {
          notification.error({
            key,
            message: resp?.data?.message,
            placement: "topLeft",
          });
          return { error: 1, data: [], message: resp?.data?.message };
        }
        return resp;
      } catch (e) {
        notification.error({
          key,
          message: e?.response?.data?.msg || e?.message,
          placement: "topLeft",
        });

        return { status: "error", msg: e?.response?.data?.msg || e?.message };
      }
    },
  };
  // return requestObject;
};

export async function uploader(url, formData) {
  const user = JSON.parse(localStorage.getItem(config.key.user));
  // let herder = headers;
  let header
  if(user){
    header = {...headers, Authorization: "Bearer " + user?.jwt,}
  }else{
    header = headers;
  }
  try {
    const loc = `${uri}?${url}`;
    const response = await fetch(loc, {
      method: "POST",
      body: formData,
      headers: header
    });
    return await response.json();
  } catch (e) {
    console.error("Error:", e);
    return { status: "error", msg: e?.response?.data?.msg || e?.message };
  }
}

export async function* fetchPaginatedData(
  method = "post",
  uri = "index",
  payload = {}
) {
  let page = 1;
  let totalPages = 1;

  do {
    const response = await myRequest(method, uri, { ...payload, page });
    const responseData = await response?.data;
    //   Quit operation on error
    if (responseData?.error) {
      break;
    }

    const { pages, data } = responseData?.data;
    ++page;
    totalPages = pages;
    yield data;
  } while (page <= totalPages);
}

export const calculateAge = (birthDateTime) => {
  const today = new Date();
  const dob = new Date(birthDateTime.slice(0, 10));
  let age = today.getFullYear() - dob.getFullYear();

  const todayMonthDay = (today.getMonth() << 5) + today.getDate();
  const birthMonthDay = (dob.getMonth() << 5) + dob.getDate();

  if (todayMonthDay < birthMonthDay) {
    age--;
  }

  return age;
};

export const calculateAgeAlt = (birthDate) => {
  const today = new Date();
  const dob = new Date(birthDate);
  let age = today.getFullYear() - dob.getFullYear();

  const todayMonthDay = (today.getMonth() << 5) + today.getDate();
  const birthMonthDay = (dob.getMonth() << 5) + dob.getDate();

  if (todayMonthDay < birthMonthDay) {
    age--;
  }

  return age;
};

export const underscoreToCapitalizedWords = (variable) => {
  return variable
    .split(`_`)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const camelToCapitalizedWords = (variable) => {
  return variable
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const processObjectKeysForCSVExport = (jsonobj) => {
  if (Array.isArray(jsonobj)) {
    return jsonobj.map((value) =>
      Object.entries(value).map((__) => ({
        [underscoreToCapitalizedWords(__[0])]: __[1],
      }))
    );
  }
  return Object.entries(jsonobj).map((__) => ({
    [underscoreToCapitalizedWords(__[0])]: __[1],
  }));
};

export const processObjectKeysForCSVExportOptimized = (
  jsonobj,
  excludedKeys = []
) =>
  Array.isArray(jsonobj)
    ? jsonobj.map((obj) =>
        Object.entries(obj)
          .filter(([key]) => !excludedKeys.includes(key))
          .map(([key, value]) => ({
            [underscoreToCapitalizedWords(key)]: value,
          }))
      )
    : Object.entries(jsonobj)
        .filter(([key]) => !excludedKeys.includes(key))
        .map(([key, value]) => ({
          [underscoreToCapitalizedWords(key)]: value,
        }));

export const downloadJSONAsCSV = (data, filename) => {
  const csvData = [];

  // Extract header row from data
  const header = Object.keys(data);
  csvData.push(header.join(","));

  // Extract data rows from data
  // data.forEach(item => {
  const row = Object.values(data).map((val) => val);
  csvData.push(row.join("\n"));
  // });

  // Create CSV file and download
  const csvContent = csvData.join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Release the URL object to prevent empty file downloads
  URL.revokeObjectURL(url);
};

export const downloadCSVFromJSON = (
  jsonData,
  filename = "data.csv",
  excludedKeys = [],
  callback = null,
  delimeter = "\t",
  headerHandler = "underscore"
) => {
  const csvRows = [];
  const headers = Object.keys(jsonData[0]).filter(
    (key) => !excludedKeys.includes(key)
  );
  for (const dataObj of jsonData) {
    const rowValues = [];
    for (const header of headers) {
      rowValues.push(dataObj[header]);
    }
    csvRows.push(rowValues.join(delimeter));
  }

  let csvString = "";
  switch (headerHandler) {
    case "underscore":
      csvString = [
        headers.map((h) => underscoreToCapitalizedWords(h)).join("\t"),
        ...csvRows,
      ].join("\n");
      break;
    case "camel":
      csvString = [
        headers.map((h) => camelToCapitalizedWords(h)).join("\t"),
        ...csvRows,
      ].join("\n");
      break;
    default:
      csvString = [headers.join("\t"), ...csvRows].join("\n");
      break;
  }

  // Create a download link for the CSV file
  const downloadLink = document.createElement("a");
  const blob = new Blob([csvString], { type: "text/csv" });
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;

  // Trigger the download link to download the CSV file
  const triggerDownload = () => {
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (callback) {
    downloadLink.addEventListener("click", callback);
  }

  triggerDownload();
};

export function removeDuplicates(array, property) {
  if(!array) return;
  return array.filter(
    (obj, index, self) =>
      !self.slice(index + 1).find((item) => item[property] === obj[property])
  );
}

export function removeDuplicatesCompareAll(array, property) {
  return array.filter((obj, index) =>
    !array.slice(0, index).some((item) => item[property] === obj[property])
  );
}

export function isEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export function updateChangedObjects(oldObjects, newObjects) {
  const oldObjectMap = {};
  const updatedObjects = [];

  // Create a map of old objects indexed by id
  for (const oldObj of oldObjects) {
    oldObjectMap[oldObj.id] = oldObj;
  }

  // Iterate through new objects and compare with corresponding old objects
  for (const newObj of newObjects) {
    const oldObj = oldObjectMap[newObj.id];

    if (!oldObj || !isEqual(oldObj, newObj)) {
      updatedObjects.push(newObj);
    } else {
      updatedObjects.push(oldObj); // Push unchanged objects
    }
  }

  return updatedObjects;
}

export function findDifferentKeysTwoObj(obj1, obj2, keysToOmit = [], controlKey = '') {
  if (!(obj1 && obj2)) return [];

  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
  const differentKeys = [];

  for (const key of allKeys) {
    if (keysToOmit.includes(key)) {
      continue; // Skip keys that are marked for omission
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (value1 !== value2) {
      differentKeys.push(key);
    }
  }

  return differentKeys;
}

export function findDifferentKeysThreeObj(obj1, obj2, obj3, keysToOmit = []) {
  if (!(obj1 && obj2 && obj3)) return [];
  const allKeys = new Set([
    ...Object.keys(obj1),
    ...Object.keys(obj2),
    ...Object.keys(obj3),
  ]);
  const differentKeys = [];

  for (const key of allKeys) {
    if (keysToOmit.includes(key)) {
      continue; // Skip keys that are marked for omission
    }
    const value1 = obj1[key];
    const value2 = obj2[key];
    const value3 = obj3[key];

    if (value1 !== value2 || value1 !== value3 || value2 !== value3) {
      differentKeys.push(key);
    }
  }

  return differentKeys;
}

export function omitKeys(obj, keysToOmit) {
  if (!obj) return {};
  const newObj = { ...obj };

  for (const key of keysToOmit) {
    delete newObj[key];
  }

  return newObj;
}

export function writeArrayToCSV(data, filename = "data.csv", delimiter = ",") {
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid data provided.");
    return;
  }

  let  csvContent = data;
  if(Array.isArray(data[1])) {
    csvContent = data.map((row) => row.join(delimiter)).join("\n");
  }

  const blob = new Blob([csvContent], { type: "text/csv" });
  const link = document.createElement("a");

  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

export const confirmModal = async (content = <></>, title = 'Warning', type = 'warning', icon = <WarningFilled color="gold" />) => new Promise( resolved => Modal.confirm({
  title, content,
  icon: icon,
  destroyOnClose: true,
  cancelText: 'No',
  okText: 'Yes',
  okType: 'danger',
  onCancel: () => resolved(false),
  onOk: () => resolved(true),
  type
}))

export function sleep(delayInMilliseconds) {
  return new Promise(resolve => setTimeout(resolve, delayInMilliseconds));
}

// export const myTime = async () => {
//   return fetch(worldTimeApi).then(response => response.json())
// }

export default lib;
