import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector} from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom"
import Draggable from "react-draggable";
import { Select, Button,  Input, Spin, Row, Col, Typography, Space, Drawer, Modal, Empty, InputNumber, notification, Popconfirm, Badge } from 'antd';
import PageTitleHook from "../pagetitle";
import { add_queries, selectQueries } from '../../stores/queries'

import './preauth.css';
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined, } from "@ant-design/icons";
import config from "../../assets/utils/config";
import CurrencyFormat from "react-currency-format";  //eslint-disable-line
import { myRequestObj } from "../../assets/utils/lib";
import { useAuth } from "../../assets/utils/useAuth";
import { ClientDataHook } from "../compoinents/EnrolleeDataHook";
import PopConfirm from "../compoinents/PopConfirm";
import InputCopy from "../compoinents/copyInput";

const { TextArea } = Input;

const PARequestHook = () => {
  const { user } = useAuth();
  const [items, setItems] = useState([]);
  const [careCatData, setCareCatData] = useState({}); //eslint-disable-line
  const [provDiagenosis, setProvDiagenosis] = useState([]);
  const [client, setClient] = useState({});
  const navigator = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const showCareInfo = useRef(false);
  const interval = useRef();
  const state = (useLocation())?.state;

  const [showAddItemButton, showRemoveItemButton] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  // console.log(user?.role)
  useEffect(() => {
    fetchPAData(params.get('checkin')).then(res => {
      setItems(res?.items) //.filter(item => (!params.get('filter'))? true : itemStatusesKeys.indexOf(params.get('filter')) === Number(item?.claim_item_status)))
      // setItems(res?.items?.filter(item => (!params.get('filter'))? true: itemStatusesKeys.indexOf(params.get('filter')) === Number(item?.claim_item_status)))
      setClient(res?.misc);
      setCareCatData(res?.careLimits)
      updateList();
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchPAData(claimsId) {
    return myRequestObj('paList').post({checkinId: claimsId})
    .then(response => response?.data)
    .then(body =>{ 
      const provList = body?.data?.diagnosis?.map((d) => ({
        label: `${d.code} ${d.name}`,
        value: d.id,
      }))
      setProvDiagenosis(provList? provList : [])
      return body?.data;
    }).catch(e => {
      console.log('PA Fetch Error', e.message)
    });
  }

  const send_claim = async (state) => {
    if(Number(state?.item?.source) === 1) {
      navigator(`/checkins`, {state: {item: state?.item, filterKeyword: state?.filterKeyword}})
    }else{
      navigator('/preauth')
    }
    return () => clearInterval(interval);
  } 

  const updateList = () => {
    if(interval.current) clearInterval(interval.current);
    interval.current = setInterval(() => {
      if(window.location.pathname === '/preauth-action'){
        fetchPAData(params.get('checkin')).then(res => {
          // setItems(res?.items?.filter(item => (!params.get('filter'))? true: itemStatusesKeys.indexOf(params.get('filter')) === Number(item?.claim_item_status)))
          setItems(res?.items) //.filter(item => (!params.get('filter'))? true : itemStatusesKeys.indexOf(params.get('filter')) === Number(item?.claim_item_status)))
          setClient(res?.misc);
          setCareCatData(res?.careLimits)
        });
      }
    }, 3000)
  }

  const handleAddItems = () => {
    navigator(`/file-encounter?eid=${client?.code}`, { state: client })
    // setShowAddItem(!!!showAddItem)
  }

  return (
    <>
      <PageTitleHook title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div>PA Request Processing</div><div><InputCopy value={client?.code} style={{fontSize: '20px', minWidth: '300px'}}/></div></div>} style={{fontSize: '1.8em'}} />
      <hr />
      <ClientDataHook data = {client} style={{maxWidth: "100%"}}/>
      <div style={{maxHeight: '450px', overflowY: 'auto', marginTop: '10px'}}>
        <label className='form-label' style={{display: 'block', fontSize: 20, clear: 'both', fontWeight: 600, textDecoration: 'underline'}} >Diagnosis - Provisional</label>
        <Select
          mode="multiple"
          value={provDiagenosis}
          style={{width: '100%'}}
          disabled
        >
        </Select>
      </div>
      <div style={{marginTop: 10}}>
        {/* Care Information */}
        {showCareInfo.current?<>
          <div style={{display: 'block', fontSize: 20, clear: 'both', fontWeight: 600, textDecoration: 'underline'}} >Care Information</div>
          <div style={{maxHeight: '450px', overflowY: 'auto', display: 'flex', margin: '10px 0', gap: 5, flexWrap: 'wrap'}}>
            {
              Object.entries(careCatData).map((care) => 
                <div style={{ border: `5px solid ${(Math.ceil(care[1]?.spentPercentCare) > 70 || Math.ceil(care[1]?.spentPercentCare) > 70) ? 'red' : 'green'}`, borderRadius: '10px', padding: 5, boxShadow: `1px 2px 2px 2px ${(Math.ceil(care[1]?.spentPercentCare) > 70 || Math.ceil(care[1]?.spentPercentCare) > 70) ? 'red' : 'green'}`, minWidth: '40%' }}>
                  <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px', width: '100%'}}>
                    <Typography.Paragraph  style={{margin: "0 30px 0 0 ", padding: 0, fontSize: 18, textDecoration: 'underline'}}>
                      <span>{care[0]}:</span>
                    </Typography.Paragraph>
                  </div>
                  <div style={{width: '100%'}}><span style={{minWidth: '120px', width: '40%', display:'inline-block'}}>{client?.checkinName?.__capitalize()} Limit (#): </span> <span style={{minWidth: '120px', width: '58%', display:'inline-block'}}><CurrencyFormat thousandSeparator=',' value={care[1]?.categories_cost} prefix="₦" disabled type="text" displayType="text" /></span> </div>
                  <div style={{width: '100%'}}><span style={{minWidth: '120px', width: '40%', display:'inline-block'}}>Care Limit (#): </span> <span style={{minWidth: '120px', width: '58%', display:'inline-block'}}><CurrencyFormat thousandSeparator=',' value={care[1]?.categories_cost} prefix="₦" disabled type="text" displayType="text" /></span> </div>
                  <div style={{width: '100%'}}><span style={{minWidth: '120px', width: '40%', display:'inline-block'}}>Usage (#): </span> <span style={{minWidth: '120px', width: '58%', display:'inline-block'}}><CurrencyFormat thousandSeparator=',' value={care[1]?.spent} prefix="₦" disabled type="text" displayType="text" /></span> </div>
                  <div style={{width: '100%'}}>
                    <span style={{minWidth: '120px', width: '40%', display:'inline-block'}}>Usage (Care | Checkin) (%): </span> 
                    <span style={{minWidth: '120px', width: '58%', display:'inline-block'}}>
                      <CurrencyFormat value={care[1]?.spentPercent} suffix="%" disabled type="text" displayType="text" /> | 
                      <CurrencyFormat value={care[1]?.spentPercentCategory} suffix="%" disabled type="text" displayType="text" />
                    </span>
                  </div>
                </div>
              )
            }
          </div> 
        </> : null}
        <div style={{display: 'block', fontSize: 20, clear: 'both', fontWeight: 600, textDecoration: 'underline'}} >Request Items List</div>
        <table className="table align-middle mb-0 bg-white" >
          <thead>
            <tr>
              <td>S/N</td>
              {/* <td>Auth Code</td> */}
              {/* <td>Care Category</td> */}
              <td>Item Category</td>
              <td>Requested Item</td>
              <td>Quantity</td>
              <td>Filed Cost</td>
              <td>Approved Cost</td>
              {/* <td></td> */}
              <td></td>
            </tr>
          </thead>
          <tbody>
            {items?.map((item, index) => <AuthorizationRequestDataListHook data = {item} index={index} key={index} /> )}
          </tbody>
        </table>
      </div>
      {/* <hr /> */}
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', margin: '30px', justifyContent: 'space-between', gap: '5em'}}>
        <Button  onClick={() => send_claim(state)} danger>Close </Button>
        {([1, 2].includes(Number(user?.role['staffRoleId'])) && Number(state?.item?.source) === 1)?
          <PopConfirm 
            title={<>
              <strong>NB: This operation has financial implication!</strong><br></br>
              <span>Are you sure you want to add more PA's to this encounter?</span>
            </>}
            onConfirm={handleAddItems}
            confirmType="danger"
            triggerButtonText="Add More PAs"
            triggerButtonProps={{ type: 'primary', danger: true, style: {width: '100%'} }}
            placement="top"
            theme="danger"
            locale="en"
            debounceTime={500}
          /> : null
        }
      </div>
    </>
  )
}

const AuthorizationRequestDataListHook = props => {
  const {user} = useAuth();
  const [itemsApprovalStates, setItemsApprovalStates] = useState({itemId: props?.data?.id_, status: 0});
  const [itemApproveReqStatus, setItemApproveReqStatus] = useState(false);
  const [itemsQueryStates, ] = useState({itemId: props?.data?.id_, status: 0});
  const [itemQueryReqStatus, ] = useState(false);
  const [itemsRejectStates, setItemsRejectStates] = useState({itemId: props?.data?.id_, status: 0});
  const [itemRejectReqStatus, setItemRejectReqStatus] = useState(false);
  const [approvedCost, setApprovedCost] = useState((Number(props?.data?.claim_item_status) === 1)? props?.data?.approved_cost : props?.data?.cost);

  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleApproval, setVisibleApproval] = useState(false);
  const [comment, setComment] = useState('');

  // Modal control start
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const handleCancel = (e) => {
    setVisibleApproval(false);
    setVisibleReject(false);
  };


  const authorize = async () => {
    if(approvedCost <= 0) {
      notification.error({
        message: <span>Approved Cost Can not be {approvedCost}</span>, placement: 'topLeft'
      })
    }
    setItemApproveReqStatus(1);
    setItemsApprovalStates({itemId: props?.data?.id_, status: 2})

    return myRequestObj(`authorizePA`).post({checkinId: props?.data?.checkin_id, id: props?.data?.id_, appproved_cost: approvedCost, comment})
    .then((response) => response?.data)
    .then((body) =>{
      if(!body?.error){
        setItemsApprovalStates({itemId: props?.data?.id_, status: 1})
        notification.success({
          message: body?.message, placement: 'topLeft'
        })
      }else{
        notification.error({
          message: body?.message, placement: 'topLeft'
        })
      }
      setVisibleApproval(false);
      return body;
    });
  }

  const reject = async () => {
    setItemRejectReqStatus(1)
    setItemsRejectStates({itemId: props?.data?.id_, status: 2})
    return myRequestObj(`rejectPA`).post({checkinId: props?.data?.checkin_id, id: props?.data?.id_, comment})
    .then((response) => response?.data)
    .then((body) =>{
      if(!body?.error){
        setItemsRejectStates({itemId: props?.data?.id_, status: 1})
        notification.success({
          message: body?.message, placement: 'topLeft'
        })
      }else{
        notification.error({
          message: body?.message, placement: 'topLeft'
        })
      }
      setVisibleReject(false)
      return body;
    });
  }

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };


  return (
    <>
      <tr>
        <td className='fw-bold'>{props?.index + 1}</td>
        {/* <td className='fw-bold'>
          <span><strong>{Array.from(props?.data?.auth_code ?? '').filter(i => i !== '.').join('').split('/')[2]}</strong></span>
        </td> */}
        {/* <td className='fw-bold'>
          {props?.data?.plans_items_name}
        </td> */}
        <td className='fw-bold'>
          {props?.data?.category_name.split(' ')[0]}
        </td>
        <td className='fw-bold'>
          <span><strong>{`${props?.data?.item_name}`} </strong></span>
        </td>
        <td>
          <span><strong>{props?.data?.quantity} </strong></span>
        </td>
        <td>
          <span><strong>{props?.data?.cost} </strong></span>
        </td>
        <td>
          {!(Number(props?.data?.claim_item_status) && Number(props?.data?.claim_item_status) !== 2) && ![1,4].includes(Number(user?.role?.staffRoleId))?
            <InputNumber 
              className="fw-normal mb-1 fw-bold" 
              style={{width: 100}} 
              value = {approvedCost}
              onChange={e => { 
                if(e < 0) return;
                setApprovedCost(e)
              }} 
              onBlur = {e => {
                if(!isNaN(e.target.value) && Number(e.target.value) !== 0){
                  setApprovedCost(Number(e.target.value));
                }else{
                  setApprovedCost(props?.data?.cost);
                }
              }}
              disabled={(itemApproveReqStatus || itemQueryReqStatus || itemRejectReqStatus || Number(itemsApprovalStates.status) === 1 || (Number(props?.data?.claim_item_status) && Number(props?.data?.claim_item_status) !== 2) || [1,4].includes(Number(user?.role?.staffRoleId)))? true: false}
            /> : <span><strong>{approvedCost} </strong></span>
          }
        </td>
        <td>
          <span style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            <Popconfirm placement="top"
              title={<span>Are you sure you want to authorize <strong>{approvedCost || props?.data?.cost}</strong> for this request?</span>}
              onConfirm={() => setVisibleApproval(true)}
              okText="Yes"
              cancelText="No"
              disabled={(itemApproveReqStatus || itemQueryReqStatus || itemRejectReqStatus || Number(itemsApprovalStates.status) === 1 || (Number(props?.data?.claim_item_status) && Number(props?.data?.claim_item_status) !==2) || [1,4].includes(Number(user?.role?.staffRoleId)))? true: false}
            >
              <Button type={'primary'} style={{display: 'flex', alignItems: 'center'}}
                disabled={(itemApproveReqStatus || itemQueryReqStatus || itemRejectReqStatus || Number(itemsApprovalStates.status) === 1 || (Number(props?.data?.claim_item_status) && Number(props?.data?.claim_item_status) !==2) || [1,4].includes(Number(user?.role?.staffRoleId)))? true: false}
              >
                {/* Authorize */}
                <CheckOutlined />
              </Button>
            </Popconfirm>&nbsp;
            <Popconfirm placement="top"
              title={'Are you sure you want to reject this request?'}
              onConfirm={() => setVisibleReject(true)}
              okText="Yes"
              cancelText="No"
              disabled={(itemApproveReqStatus || itemQueryReqStatus || itemRejectReqStatus || Number(itemsRejectStates.status) === 1 || (Number(props?.data?.claim_item_status) && Number(props?.data?.claim_item_status) !==2) || [1, 4].includes(Number(user?.role?.staffRoleId)))? true: false}
            >
              <Button type='primary' danger style={{display: 'flex', alignItems: 'center'}}
                disabled={(itemApproveReqStatus || itemQueryReqStatus || itemRejectReqStatus || Number(itemsRejectStates.status) === 1 || (Number(props?.data?.claim_item_status) && Number(props?.data?.claim_item_status) !==2) || [1, 4].includes(Number(user?.role?.staffRoleId)))? true: false}
              >
                {/* Reject */}
                <CloseOutlined />
              </Button>
            </Popconfirm>&nbsp;
            <PAQuery data = {props?.data} itemQueryReqStatus={itemQueryReqStatus} itemRejectReqStatus={itemRejectReqStatus} itemsQueryStates={itemsQueryStates} />&nbsp;

            {
              (Number(props?.data?.claim_item_status) && Number(props?.data?.claim_item_status) !== 2)? 
                (Number(props?.data?.claim_item_status) === 1)? <CheckOutlined  style ={{color: "#4aba6fe3", marginLeft: 10, fontSize: '2em'}}/> :
                  (Number(props?.data?.claim_item_status) === 3)? <CloseOutlined   style ={{color: "red", marginLeft: 10, fontSize: '2em'}}/> : null
                : null
            } 
          </span>
        </td>
      </tr>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}} // end
          >
            Approval Memo
          </div>
        }
        open={visibleApproval}
        onOk={authorize}
        onCancel={handleCancel}
        destroyOnClose
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        okText = {
            (Number(itemsApprovalStates.status) === 2)? <Spin size={'small'} />: 'Approve'
          }
      >
        <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
          <label className='form-label'>Comment</label>
          <TextArea rows={3} style={{ width: '100%'}} onChange ={(e) => setComment(e.target.value)}/>
        </div>
      </Modal>
        
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}} // end
          >
            Rejection Memo
          </div>
        }
        open={visibleReject}
        onOk={reject}
        onCancel={handleCancel}
        destroyOnClose
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        okText = {
          (Number(itemsRejectStates.status) === 2)? <Spin size={'small'} />: 'Deny'
        }
      >
        <div className='form-group' style={{width: '100%', marginBottom: '5px'}}>
          <label className='form-label'>Comment</label>
          <TextArea rows={3} style={{ width: '100%'}} onChange ={(e) => setComment(e.target.value)}/>
        </div>
      </Modal>
    </>
  )
}

const PAQuery = ({ data }) => {
  const {user} = useAuth();
  const dispatcher = useDispatch();
  const queries = useSelector(selectQueries)?.filter(i => i.marker == data.id_ && parseInt(i.level) == 0)?.sort((a, b) => Number(a.id) - Number(b.id) );//eslint-disable-line
  const [loading, setLoading] = useState(false);
  const [sending, setsending] = useState(false);
  const [visible, setVisible] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [messageData, ] = useState({ relation: 'claim_items', marker: data.id_, source: 0,  });
  const [, setFirstLoad] = useState(true);
  const [unreadQueriesCount, setUnreadQueriesCount] = useState(0);
  const [queried, setQueried] = useState(0);
  const [reading, setReading] = useState(false);

  let interval = useRef('');
  let interval2 = useRef('');

  useEffect(() => {
    myRequestObj(`queriesCountAdminPA`).post({id_: data.id_, source: 1, level: 0})
    .then(res => res?.data)
    .then(body => {
      if(!body?.error){
        setUnreadQueriesCount(body?.data[0]?.queris);
        setQueried(Number(body?.data[1]?.querid))
      }
    }).finally(() => {
      if(interval2.current) clearInterval(interval2.current);
      interval2.current = setInterval(() => {
        if(!reading && window.location.pathname === '/preauth-action'){
          myRequestObj(`queriesCountAdminPA`).post({id_: data.id_, source: 1, level: 0})
          .then(res => res?.data)
          .then(body => {
            if(!body?.error){
              setUnreadQueriesCount(body?.data[0]?.queris);
              setQueried(Number(body?.data[1]?.querid))
            }
          })
          .catch(e => console.log(e?.msg ?? e?.message))
        }
      }, 5000)
    }).catch(e => console.log(e?.msg ?? e?.message))

    return clearInterval(interval2.current);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const queriesToUpdate = queries?.filter(j => !Number(j.status) && Number(j.source)).map(i => ({id: i.id}))
    if(![2].includes(Number(user?.role?.staffRoleId))) return;
    if(visible && queriesToUpdate.length){
      myRequestObj(`update_queries`).post(queriesToUpdate)
      .then(res => res?.data)
      .catch(e => console.log(e.msg ?? e.message ))
      .finally(setFirstLoad(false));
    }
  }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);

    if(visible){
      localStorage.setItem(config.key.q_reading, 1);
    }

    fetchQueries(visible).then(res => {
      setLoading(false);
      updateQueries()
    })

    setReading(true);
  }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = () => {
    setsending(true);
    myRequestObj(`query`).post({content: messageText, ...messageData})
    .then(res => res?.data)
    .then(body => {
      if(!body?.error){
        dispatcher(add_queries(body?.data));
      }
      setsending(false);
      setMessageText('');
    })
    .catch(e => console.log(e.msg ?? e.message ))
  };

  const handleCancel = () => {
    localStorage.setItem(config.key.q_reading, 0);
    if(interval) clearInterval(interval);
    setVisible(false);
    setReading(false);
  };

  const contoller = () => {
    setVisible(true)
  }

  const composeMessage = e => {
    const mess = e.target.value;
    setMessageText(mess)
  }

  const fetchQueries = async (x) => {
    if(!x) return;
    myRequestObj(`queries`).post(messageData)
    .then(res => res?.data)
    .then(body => {
      if(!body?.error && body?.data?.length){
        dispatcher(add_queries(body?.data));
      }
    })
    .catch(e => console.log(e.msg ?? e.message ))
  }

  // const fetchQueriesCount = async () => {
  //   myRequestObj(`queriesCountAdminPA`)
  //   .post({id_: data.id_, source: 1, level: 0})
  //   .then(res => res?.data)
  //   .then(body => {
  //     if(!body?.error){
  //       setUnreadQueriesCount(body?.data[0]?.queris);
  //       setQueried(Number(body?.data[1]?.querid))
  //     }
  //   })
  //   .catch(e => console.log(e.msg ?? e.message ))
  // }

  const updateQueries = () => {
    interval = setInterval(() => fetchQueries(visible), 5000)
  }

  return (
    <>
      <Badge count={Number(unreadQueriesCount) || null}>
        <Button type='dashed' danger onClick={() => ((!Number(data.claim_item_status) || Number(data.claim_item_status) === 2 || queried))? contoller() : null} 
          disabled ={((!Number(data.claim_item_status) || Number(data.claim_item_status) === 2 || queried ))? false : true}
          style={{display: 'flex', alignItems: 'center'}}>
           <QuestionCircleOutlined />
        </Button>
      </Badge>
      <Drawer
        open={visible}
        title={<>
          <Typography.Title level={5} style={{margin: '0 auto'}}>
            Query: {data.checkin_id}
          </Typography.Title>
        </>}
        // onOk={handleOk}
        onClose={handleCancel}
        footer={[
          [2].includes(Number(user?.role?.staffRoleId)) ?
          <Row justify="space-between" align="bottom">
            <Col span={20}>
              <TextArea rows={2} value={messageText} 
                placeholder='Write Full Description of the Query....' 
                onChange={composeMessage} id={'message'}
                disabled={[1,3].includes(Number(data.claim_item_status)) ? true : false}
              />
            </Col>
            <Col>
              <Button key="back" onClick={handleOk} style={{ color: 'skyblue', fontSize: '1em' }} 
                disabled={[1,3].includes(Number(data.claim_item_status)) || sending || loading || !messageText.length ? true : false}
              > Send </Button>
            </Col>
          </Row> : null
        ]}
        width={600}
      >
        
        <Space gutter={[8, 16]} direction={'vertical'} id='chatContainer'  style={{width: '100%'}}>
          {(loading)?
            <Col>
              <Spin size="large" />
            </Col>
            :(!queries)?
              <Empty description={<span>
                No Queries
              </span>} />:
              <>
                {queries/*.sort((a, b) => Date.parse(a.date) - Date.parse(b.date))*/.map((q, i) => 
                  <Row id={`query_${i}`} 
                    justify={(!Number(q.source))? 'start' : 'end'}
                  >
                    <Col span={16}
                      style={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        lineHeight: '18px',
                        width: 'fit-content', 
                        color: `${!Number(q.source)? 'green': 'red'}`, 
                        border: `${!Number(q.source)? '1px solid green': '1px solid red'}`, 
                        borderRadius: '10px' , padding: '10px'
                      }}
                    >
                      <p>{q.content}</p>
                      <span style={{textAlign: 'right', justifySelf: 'flex-end'}}>{(new Date(q.date)).toLocaleString()}</span>
                    </Col>
                  </Row>
                )}
              </>
          }
        </Space>          
      </Drawer>
    </>
  );
};

const PAFiling = () => {
  const params = useRef(new URLSearchParams(window.location.search));

  useEffect(() => {
    params.current = new URLSearchParams(window.location.search);
  }, [window.location.search]) //eslint-disable-line
  
  return (
    <>
      {
        (params.current.get('checkin'))? <PARequestHook /> : navigator('/preauth')
      }
    </>
  )
}

export default PAFiling;