import { Avatar, Button, Col, DatePicker, Divider, Input, Modal, Radio, Row, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { uri_img } from "../../assets/utils/http-request";
import lib, { calculateAgeAlt, myRequestObj } from "../../assets/utils/lib";
import { CheckOutlined, EditOutlined, LoadingOutlined, } from "@ant-design/icons";
import { states, gender, role, title } from "../../assets/utils/config";
import { renderFieldAlt } from "./renderField";
import { CareCard } from "./idCard";
import { NullSafe, same } from "../../assets/utils/Helpers";
import { useAuth } from "../../assets/utils/useAuth";
import UploadImage from "./PictureUpload";
import { renderButton } from "./renderButton";
import formValidator from "../formvalidation"
import { EnrolleeConsumption } from "./EnrolleeConsumption";

const { TextArea } = Input;
const { Option } = Select;

export const EnroleeBiodata = ({ data, user = null }) => {
  const [show, setShow] = useState(false);

  const launch = () => {
    setShow(true);
  };

  return (
    <>
      <Row
        style={{
          padding: "10px",
          borderBottom: "1px solid black",
          borderTop: "1px solid black",
          margin: "10px 0",
          cursor: "pointer",
        }}
        onClick={() => launch(data, "enrollee")}
      >
        <Col span={2}>
          <Row>
            <Col span={24}>
              <Avatar
                size={64}
                icon={
                  <img
                    src={`${uri_img}${data?.pic_name}`}
                    alt={data?.first_name}
                  />
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={22}>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={8}>Full Name: </Col>
                <Col span={16}>
                  {data?.first_name} {data?.middle_name}{" "}
                  {data?.surname?.toUpperCase()}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={8}>Role: </Col>
                <Col span={16}>{data?.role?.toUpperCase()}</Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={8}>State / LGA: </Col>
                <Col span={16}>
                  {data?.state?.toUpperCase()} / {data?.lga?.toUpperCase()}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={10}>Policy No.: </Col>
                <Col span={14}>{data?.policy_no}</Col>
              </Row>
              <Row>
                <Col span={10}>Insurance No.: </Col>
                <Col span={14}>{data?.insurance_no}</Col>
              </Row>
            </Col>
            <Col span={18}>
              <Row>
                <Col span={8}>Primary Heath Facility: </Col>
                <Col span={16}>{data?.primary_health_facility}</Col>
              </Row>
              <Row>
                <Col span={8}>Altenative Health Facility: </Col>
                <Col span={16}>{data?.secondary_health_facility}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={8}>Age: </Col>
                <Col span={16}>
                  {data?.date_of_birth
                    ? calculateAgeAlt(data?.date_of_birth)
                    : "Invalid Age"}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <EnroleeBiodataModal
        // type={type}
        data={data}
        policy={user}
        display={show}
        action={() => setShow(false)}
      />
    </>
  );
};

export const EnroleeBiodataModal = ({ data, display, policy = null, action = () => null, viewOnly = false, command = null, source = 0 }) => {
  const { user } = useAuth();
  const [policyData, setPolicyData] = useState(policy);
  const [n_data, setNdata] = useState(data);
  const [edit, setEdit] = useState(false);
  const [loadingState, setLoadingState] = useState({
    update: false, activate: false, disable: false, flag: false, revoke: false,
  });
  const [stateHealthCare, setStateHealthCare] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providerError, setProviderError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [showImgUpload, setShowImgUpload] = useState(false);

  const setLoadingFlag = (key, value) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const handleUploadImage = () => {
    setShowImgUpload(!showImgUpload);
  }

  const canEdit = () => {
    return user && [1, 2].includes(Number(user.role["staffRoleId"])) && (Number(data?.status) || (Number(data?.updated)))
  }

  useEffect(() => {
    if (display) {
      if (!policy) handleFocusOut();
      fetchProviders()
    }
  }, [display]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUpdate(!same(data, n_data));
  }, [n_data]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchProviders();
  }, [n_data?.state]) //eslint-disable-line react-hooks/exhaustive-deps

  const updateData = async (e, newData = {}) => {
    if (providerError) {
      return notification.error({
        message: "There is an error preventing this action",
      });
    }

    const actionType = Number(e);
    const loadingMap = {
      1: 'update',
      2: 'activate',
      3: 'disable',
      4: 'flag',
      5: 'revoke',
    };

    const key = loadingMap[actionType];
    setLoadingFlag(key, true);
    setEdit(false);

    try {
      const res = await lib.updateUserData({ ...n_data, ...newData });
      const responseData = res.data;

      if (!responseData?.error) {
        notification.success({ message: responseData?.message, placement: 'topLeft' });
      } else {
        notification.error({ message: responseData?.message, placement: 'topLeft' });
      }
    } catch (err) {
      setEdit(true);
    } finally {
      setLoadingFlag(key, false);
      setLoadingFlag('update', false);
      action();
      command && typeof command === 'function' && command()
    }
  };

  const handleFocusOut = async () => {
    let obj = { search: n_data?.policy_no };
    try {
      const reqData = await (await myRequestObj('searchClients').post(obj))?.data;
      console.log(reqData)
      if (reqData.status === 'error') {
        notification.error({ message: reqData.msg, placement: 'topLeft' });
      }
      if (!reqData?.error) {
        setPolicyData(reqData?.data[0]);
      }
    } catch (err) {
      console.error('Error validating social:', err);
    }
  };

  const fetchProviders = async (e = null) => {
    setLoading(true);
    let obj = { state: e ?? n_data?.state, plan_type: n_data?.insurance_package };

    try {
      const reqData = await (await lib.getProviders(obj))?.data;
      // console.log(reqData)
      if (parseInt(reqData?.error) === 1) {
        setProviderError(true);
        notification.error({ message: reqData?.message, placement: 'topLeft' });
      } else {
        // console.log(reqData)
        setStateHealthCare(reqData?.data);
      }
    } catch (err) {
      console.error('Error fetching providers:', err);
    } finally {
      setLoading(false);
    }
  };

  const configFlag = {
    title: (
      <>Flag - {data?.insurance_no}</>
    ),
    onOk: () => {
      updateData(4);
    },
    okText: "Raise Flag",
    width: 700,
    centered: true,
    maskClosable: true,
    content: (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>Flag Memo: </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) =>
                setNdata((d) => ({ ...d, flag_reason: e.target.value }))
              }
              style={{ width: "100%" }}
              rows={4}
            />
          </Col>
        </Row>
      </>
    ),
  };

  const configUnFlag = {
    title: (
      <>Unflag - {data?.insurance_no}</>
    ),
    onOk() {
      updateData(4);
    },
    centered: true,
    maskClosable: true,
    okText: "Remove Flag",
    content: (
      <>
        <Row>
          <Col span={24}>Flag Memo: </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) => setNdata((d) => ({ ...d, flag_reason: e }))}
              style={{ width: "100%" }}
              rows={4}
            />
          </Col>
        </Row>
      </>
    ),
  };

  return (<>
    <Modal
      title={
        <Row gutter={[16, 16]} align={'middle'}>
          <Col>
            Enrollee - {data?.insurance_no}
          </Col>
          <Divider type="vertical" />
          <Col >
            <Button type="link" danger={!Number(data?.status)} disabled style={{ cursor: 'help', color: Number(data?.status) ? 'green' : 'red' }}>
              {Number(data?.status) ? "Active" : "Inactive"}
            </Button>
          </Col>
          {!viewOnly ? <>
            {
              canEdit() ? <>
                <Divider type="vertical" />
                <Col >
                  <Button onClick={() => setEdit(!edit)}
                    icon={edit ? (
                      <CheckOutlined
                        style={{ fontSize: 20, color: "blue" }}
                        title="Done"
                      />
                    ) : (
                      <EditOutlined
                        onClick={() => setEdit(true)}
                        style={{ fontSize: 20, color: "blue" }}
                      />
                    )
                    }>
                    {edit ? <>Close Edit</> : <>Edit</>}
                  </Button>
                </Col>

                {!parseInt(data?.update_required) &&
                  parseInt(data?.updated) && parseInt(data?.status) && data?.pic_name ? (<>
                    <Divider type="vertical" />
                    <Col >
                      <CareCard data={data} policy={policyData} key={data?.id} />
                    </Col>
                  </>) : null}
              </> : null
            }
            {update ? <>
              <Divider type="vertical" />
              <Col >
                {
                  renderButton({
                    label: loadingState[1] ? <span><LoadingOutlined /> Updating</span> : <>Update</>,
                    key: 'update',
                    isDisabled: Object.values(loadingState).some(Boolean),
                    loadingState: loadingState[1],
                    actionType: 3,
                    onClick: () => updateData(1),
                  })
                }
              </Col>
            </> : null}
          </> : null}
        </Row >
      }
      centered
      open={display}
      onOk={action}
      onCancel={action}
      footer={
        <>
          {(Number(policyData?.status) && !viewOnly) ?
            <>
              {source === 0 ?
                <>
                  {
                    (Number(data?.status) ? <>
                      {renderButton({
                        type: 'primary',
                        label: 'Disable',
                        key: 'disable',
                        isDisabled: Object.values(loadingState).some(Boolean),
                        loadingState: loadingState[3],
                        actionType: 3,
                        onClick: () => updateData(3, { status: 0 }),
                      })}
                    </> : null)
                  }{
                    (!Number(data?.status)) ?
                      <>
                        {renderButton({
                          label: (Number(data?.update_required) && !Number(data?.updated)) ? 'Force Activate' : 'Activate',
                          key: 'activate',
                          isDisabled: Object.values(loadingState).some(Boolean),
                          loadingState: loadingState[2],
                          actionType: 3,
                          onClick: () => updateData(3, { status: 1, update_required: 0, is_new: 0, updated: 1 }),
                        })}

                        {(Number(data?.update_required) && Number(data?.updated) || Number(data?.is_new)) ? renderButton({
                          label: 'Revoke',
                          key: 'revoke',
                          isDisabled: loadingState[5],
                          loadingState: loadingState[5],
                          actionType: 5,
                          onClick: () => updateData(5, { status: 0, update_required: 1, updated: 0, is_new: 0 }),
                        }) : null}
                      </> : null
                  }
                </> : null
              }
            </> : null}
          <Divider type="vertical" />
          <EnrolleeConsumption userId={n_data?.insurance_no} enrolleeData={n_data} policy={policy}/>
          <Divider type="vertical" />
          {renderButton({
            label: 'Close',
            key: 'close',
            actionType: 3,
            onClick: action,
            isDisabled: Object.values(loadingState).some(Boolean)
          })}
        </>
      }
      width={800}
      destroyOnClose
    >
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <Divider orientation="left" orientationMargin={0}>Personal Information</Divider>
          <Row gutter={[16, 8]} align={'middle'}>
            <Col span={19}>
              <Row gutter={[16, 8]}>
                <Col span={12}>
                  {renderFieldAlt('First Name', n_data?.first_name, (e) => setNdata({ ...n_data, first_name: e.target.value }), 'input', [], !edit, viewOnly)}
                </Col>
                <Col span={12}>
                  {renderFieldAlt('Middle Name', n_data?.middle_name, (e) => setNdata({ ...n_data, middle_name: e.target.value }), 'input', [], !edit, viewOnly)}
                </Col>
                <Col span={12}>
                  {renderFieldAlt('Last Name', n_data?.surname, (e) => setNdata({ ...n_data, surname: e.target.value }), 'input', [], !edit, viewOnly)}
                </Col>
                <Col span={12}>
                  {renderFieldAlt(
                    'Gender',
                    n_data?.gender,
                    (value) => setNdata({ ...n_data, gender: value }),
                    'select',
                    [
                      { value: 'male', label: 'Male' },
                      { value: 'female', label: 'Female' },
                    ],
                    !edit, viewOnly
                  )}
                </Col>
                <Col span={12}>
                  {
                    renderFieldAlt(
                      'Age',
                      n_data?.date_of_birth ? calculateAgeAlt(n_data?.date_of_birth) : "Invalid Age",
                      (e) => setNdata({ ...n_data, age: e.target.value }), 'input', [], true, viewOnly
                    )
                  }
                </Col>
                <Col span={12}>
                  {renderFieldAlt(
                    'Role',
                    n_data?.role,
                    (value) => setNdata({ ...n_data, role: value }),
                    'select',
                    role,
                    true, viewOnly
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={5}>
              <Avatar size={140} style={{ cursor: 'pointer' }} shape="square" icon={<img src={`${uri_img}${data?.pic_name}`} alt={data?.first_name?.slice(0, 2)?.toUpperCase()} />} onClick={handleUploadImage} />
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin={0}>Contact Information</Divider>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              {
                renderFieldAlt(
                  'Phone',
                  n_data?.phone,
                  (e) => setNdata({ ...n_data, phone: e.target.value }), 'input', [], !edit, viewOnly
                )
              }
            </Col>
            <Col span={12}>
              {renderFieldAlt('Email', n_data?.email, (e) => setNdata({ ...n_data, email: e.target.value }), 'input', [], !edit, viewOnly)}
            </Col>
            <Col span={12}>
              {renderFieldAlt('Address', n_data?.contact_address, (e) => setNdata({ ...n_data, contact_address: e.target.value }), 'textarea', [], !edit, viewOnly)}
            </Col>
            <Col span={6}>
              {renderFieldAlt(
                'State',
                n_data?.state,
                (value) => { setNdata({ ...n_data, state: value }) },
                'select', states, !edit, viewOnly
              )}
            </Col>
            <Col span={6}>
              {renderFieldAlt(
                'City | LGA',
                n_data?.lga,
                (e) => { setNdata({ ...n_data, lga: e.target.value }); },
                'input', [], !edit, viewOnly
              )}
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin={0}>Medical Information</Divider>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              {
                renderFieldAlt(
                  'First Facility of Choice',
                  n_data?.primary_health_facility,
                  (e) => setNdata({ ...n_data, primary_health_facility: e }), 'select',
                  stateHealthCare?.map((item) => ({ value: item?.facility_name, label: item?.facility_name?.toUpperCase() })),
                  !edit, viewOnly
                )
              }
            </Col>
            <Col span={12}>
              {
                renderFieldAlt(
                  'Second Facility of Choice', n_data?.secondary_health_facility,
                  (e) => setNdata({ ...n_data, secondary_health_facility: e }),
                  'select',
                  stateHealthCare?.map((item) => ({ value: item?.facility_name, label: item?.facility_name?.toUpperCase() })),
                  !edit, viewOnly
                )
              }
            </Col>
            <Col span={12}>
              {renderFieldAlt('Existing Medical Condition', n_data?.existing_medical_condition, (e) => setNdata({ ...n_data, existing_medical_condition: e.target.value }), 'textarea', [], !edit, viewOnly)}
            </Col>
            <Col span={12}>
              {renderFieldAlt(
                'Previous Medical Condition',
                n_data?.previous_medical_condition,
                (value) => setNdata({ ...n_data, previous_medical_condition: value }),
                'textarea',
                [],
                !edit, viewOnly
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal >
    {(canEdit() && showImgUpload) ? <UploadImage data={n_data} style={{ color: "#ffeeff" }} key={n_data?.id} onClose={handleUploadImage} show={showImgUpload} /> : null}
  </>);
};

export const DependantEdit = (props) => {
  const [values, setValues] = useState({});
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [stateHealthCare, setStateHealtCare] = useState([]);
  const { user } = useAuth();

  const [isPrincipal, setIsPrincipal] = useState(false);

  const options = gender;

  const optionsMarital = [
    { label: "Spouse", value: "spouse" },
    { label: "Child", value: "child" },
    { label: "Other", value: "other" },
  ];

  const selectDate = (_, dateString) => {
    setValues((d) => ({ ...d, birth_day: dateString }));
  };

  useEffect(() => {
    if (error) notification.error({ message: error })
  }, [error])

  // Getting principal
  useEffect(() => {
    if (props.isModalVisible) {
      (async () => {
        setLoading(true);

        let payload = {
          policy_no: props?.data?.policy_no,
        };

        let reqData = (await lib.principal(payload))?.data;
        if (reqData?.error === 1) {
          notification.error({
            message: reqData?.message,
            duration: 4,
            placement: "topLeft",
          });
        }

        if (reqData?.status === "error") {
          notification.error({
            message: reqData?.msg || reqData?.message,
            duration: 4,
            placement: "topLeft",
          });
        }

        if (reqData?.error === 0) {
          let principal = reqData.data;
          console.log(principal);
          setIsPrincipal(principal);
        }

        setLoading(false);
      })();
    }
  }, [props.isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProviders = async (e) => {
    let obj = {
      state: e,
      plan_type: props?.data?.insurance_package,
    };

    let reqData = (await lib.getProviders(obj))?.data;

    if (reqData?.data?.error === 1) {
      notification.error({
        message: reqData.data.message,
        duration: 3,
        placement: "topLeft",
      });
    }

    if (reqData?.error === 0) {
      setStateHealtCare(NullSafe(reqData?.data));
      setLoading(false);
    }
  };

  const handleDependentSubmit = async () => {
    let builder = formValidator.validateDependent({...values, role: Number(isPrincipal) === 1 ? "Dependent" : "Principal"}, {}, setError);

    if (!builder) {
      return;
    }

    setLoading(true);
    builder.policy_no = props?.data?.policy_no;
    // builder.role = (Number(isPrincipal) === 1 ? "Dependent" : "Principal").toLowerCase();

    let reqData = await lib.saveNewProfile(builder);

    if (reqData.data?.error === 1) {
      notification.error({
        message: reqData.data.message,
        placement: "topLeft",
      });
    }

    if (reqData.data?.error === 0) {
      notification.success({
        message: reqData.data.message,
        placement: "topLeft",
      });
      props.handleCancel();
      props.command();
    }

    setLoading(false);
    props.command();
  };

  return (
    <>
      <Modal
        width={1000}
        open={props.isModalVisible}
        footer={
          user && [1, 2].includes(Number(user.role["staffRoleId"])) ? (
            <>
              <Button disabled={loading} onClick={handleDependentSubmit}>
                <>{loading ?? <LoadingOutlined />} Add Enrollee</>
              </Button>
              <Button
                type="dashed"
                disabled={loading}
                danger
                onClick={props?.command}
              >
                Close
              </Button>
            </>) : null
        }
        closable={true}
        destroyOnClose
        onClose={props?.command}
        onCancel={props?.command
        }
      >
        <div style={{ width: "100%", margin: "auto" }}>
          {loading ? (
            <LoadingOutlined size={50} />
          ) : (
            <div className="profile-top">
              <Row style={{ width: "100%" }} gutter={[16, 16]}>
                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12} xs={24} sm={24}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Insurance package:</Col>
                        <Col span={16}>
                          <Input
                            disabled
                            value={props?.data?.insurance_package}
                            placeholder="Basic, Bronze, Silver ..."
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} xs={24} sm={24}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Role:</Col>
                        <Col span={16}>
                          <Input
                            value={Number(isPrincipal) === 1 ? "Dependent" : "Principal"}
                            disabled
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={[16, 16]}>
                    <Col span={24}>
                      <Row gutter={[16, 16]} align="middle">
                        <Col span={4}>Title:</Col>
                        <Col span={20}>
                          <Select
                            onChange={(e) =>
                              setValues((d) => ({ ...d, title: e }))
                            }
                            value={values?.title}
                            options={title}
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>First Name:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                first_name: e.target.value,
                              }))
                            }
                            value={values?.first_name}
                            placeholder="John"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Middle Name:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                middle_name: e.target.value,
                              }))
                            }
                            value={values?.middle_name}
                            placeholder="Doe"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Surname:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                surname: e.target.value,
                              }))
                            }
                            value={values?.surname}
                            placeholder="Clark"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Date of Birth:</Col>
                        <Col span={16}>
                          <DatePicker
                            style={{ width: "100%" }}
                            onChange={selectDate}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Gender:</Col>
                        <Col span={16}>
                          <Radio.Group
                            style={{ marginLeft: "20px" }}
                            options={options}
                            onChange={(e) => {
                              setValues((d) => ({
                                ...d,
                                gender: e.target.value,
                              }));
                            }}
                            value={values?.gender}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      {Number(isPrincipal) === 1 &&<Row gutter={16} align="middle">
                        <Col span={8}>Relationship:</Col>
                        <Col span={16}>
                          <Radio.Group
                            style={{ marginLeft: "20px" }}
                            options={optionsMarital}
                            onChange={(e) => {
                              setValues((d) => ({
                                ...d,
                                relationship: e.target.value,
                              }));
                            }}
                            value={values?.relationship}
                          />
                        </Col>
                      </Row>}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Email:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                email: e.target.value,
                              }))
                            }
                            value={values?.email}
                            placeholder="example@gmail.com"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Phone number:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                phone: e.target.value,
                              }))
                            }
                            value={values?.phone}
                            placeholder="09000000000"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>State of Residence;</Col>
                        <Col span={16}>
                          <Select
                            showSearch
                            defaultValue={"Select state"}
                            style={{ width: "100%", marginRight: "10px" }}
                            onChange={(e) => {
                              setValues((d) => ({ ...d, state: e }));
                              fetchProviders(e);
                            }}
                            value={values?.state}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input) ||
                              (option?.value ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                ) ||
                              (optionA?.value ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.value ?? "").toLowerCase()
                                )
                            }
                            options={states}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>City / LGA:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({ ...d, lga: e.target.value }))
                            }
                            value={values?.lga}
                            placeholder="lga"
                            style={{ width: "100%", marginRight: "10px" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Religion:</Col>
                        <Col span={16}>
                          <Select
                            defaultValue={values?.religion}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              setValues((d) => ({ ...d, religion: e }))
                            }
                            placeholder="select religion"
                          >
                            <Option value="christianity">Christianity</Option>
                            <Option value="islam">Islam</Option>
                            <Option value="others">Others</Option>
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Occupatoin:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                job_title: e.target.value,
                              }))
                            }
                            value={values?.job_title}
                            placeholder="Engineer"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16} align="middle">
                    <Col span={4}>Contact Address:</Col>
                    <Col span={20}>
                      <TextArea
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            contact_address: e.target.value,
                          }))
                        }
                        value={values?.contact_address}
                        showCount
                        style={{ width: "100%" }}
                        rows={3}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={4}>Primary Health Care Facility:</Col>
                    <Col span={20}>
                      <Select
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            primary_health_facility: e,
                          }))
                        }
                        value={values?.primary_health_facility}
                      >
                        {stateHealthCare?.map((item) => (
                          <>
                            <Option
                              value={item.facility_name}
                              title={
                                item.facility_name +
                                " - " +
                                item.facility_plan_type.toUpperCase()
                              }
                              style={{ borderBottom: "1px solid #91caff" }}
                            >
                              <span>
                                {item.facility_name}{" "}
                                <Divider
                                  type="vertical"
                                  style={{ margin: 5, borderColor: "#001d66" }}
                                />{" "}
                                {item.facility_plan_type.toUpperCase()}
                              </span>
                              <Divider style={{ margin: 0 }} />
                              <span>Address: {item.facility_address}</span>
                              <Divider style={{ margin: 0 }} />
                              <span>
                                State: {item.facility_state}{" "}
                                <Divider
                                  type="vertical"
                                  style={{ margin: 5, borderColor: "#001d66" }}
                                />{" "}
                                City: {item.facility_city.toUpperCase()}
                              </span>
                            </Option>
                          </>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={4}>Secondary Health Care Facility:</Col>
                    <Col span={20}>
                      <Select
                        style={{ width: "100%", marginRight: "10px" }}
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            secondary_health_facility: e,
                          }))
                        }
                        value={values?.secondary_health_facility}
                      >
                        {stateHealthCare?.map((item) => (
                          <>
                            <Option
                              value={item.facility_name}
                              title={
                                item.facility_name +
                                " - " +
                                item.facility_plan_type.toUpperCase()
                              }
                              style={{ borderBottom: "1px solid #91caff" }}
                            >
                              <span>
                                {item.facility_name}{" "}
                                <Divider
                                  type="vertical"
                                  style={{ margin: 5, borderColor: "#001d66" }}
                                />{" "}
                                {item.facility_plan_type.toUpperCase()}
                              </span>
                              <Divider style={{ margin: 0 }} />
                              <span>Address: {item.facility_address}</span>
                              <Divider style={{ margin: 0 }} />
                              <span>
                                State: {item.facility_state}{" "}
                                <Divider
                                  type="vertical"
                                  style={{ margin: 5, borderColor: "#001d66" }}
                                />{" "}
                                City: {item.facility_city.toUpperCase()}
                              </span>
                            </Option>
                          </>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row
                    style={{ width: "100%", textAlign: "center" }}
                    gutter={16}
                    justify="center"
                  >
                    <Col span={24}>
                      <h4>Medical Condition(s)</h4>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16} align="middle">
                    <Col span={4}>Existing Medical Condition:</Col>
                    <Col span={20}>
                      <TextArea
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            existing_medical_condition: e.target.value,
                          }))
                        }
                        value={values?.existing_medical_condition}
                        showCount
                        style={{ width: "100%" }}
                        rows={3}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16} align="middle">
                    <Col span={4}>Previous Medical Condition:</Col>
                    <Col span={20}>
                      <TextArea
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            previous_medical_condition: e.target.value,
                          }))
                        }
                        value={values?.previous_medical_condition}
                        showCount
                        style={{ width: "100%" }}
                        rows={3}
                      />
                    </Col>
                  </Row>
                </Col>

                {/* <Col span={24}>
                        <Row style={{width: '100%'}} gutter={16} align='middle' justify={'center'}>
                            <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <ButtonComponent style={{padding: '15px auto', width: 'fit-content', width: '80%'}}  content={loading? <Loader type="Oval" color="#00BFFF" height={20} visible={loading} width={20} />: "Submit"} onClick={handleDependentSubmit} disabled={loading}/>
                            </Col>
                            <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <ButtonComponent style={{padding: '15px auto', width: 'fit-content', width: '50%', color:'red'}} content="Cancel" onClick={props.handleCancel} disabled={loading} />
                            </Col>
                        </Row>
                    </Col> */}
              </Row>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};