import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Select, Button, Row, Col, Typography, notification, InputNumber } from "antd";
import PageTitleHook from "../pagetitle";
import { uri_img } from "../../assets/utils/http-request";
import lib, { fetchPaginatedData, myRequestObj, sleep } from "../../assets/utils/lib";
import logo from "../../assets/images/claima.png";

import "./preauth.css";
import { CloseOutlined, LoadingOutlined, MinusSquareOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import { DebounceSelect, DebounceSelect2 } from "../claims";
import {
  _add_care_category, _add_diagnosis, _add_item, _clean_items, _remove_care_category,
  _remove_diagnosis, _reset_items, _selectCareCategory, _selectData, _selectDiagnosis, _selectItems
} from "../../stores/preauth/indexFiling";  //eslint-disable-line

// import useLocalStorage from "../../assets/utils/useLocal";
// import config from "../../assets/utils/config";
import CurrencyFormat from "react-currency-format";
import TextArea from "antd/es/input/TextArea";
import InputCopy from "../compoinents/copyInput";
import { EnrolleeConsumption } from "../compoinents/EnrolleeConsumption";
import { selectChechin } from "../../stores/verify/checkin";
import { useAuth } from "../../assets/utils/useAuth";

// const { TextArea } = Input;

const PARequestHook = ({state={}, checkinId}) => {
  const [items, setItems] = useState([]);
  const [client, setClient] = useState(state);
  const navigator = useNavigate();
  const [showDetailFlag, setShowDetailFlag] = useState(false);  //eslint-disable-line

  const [checkinType, setCheckinType] = useState(
    {
      insurance_no: state?.insurance_no, 
      checkinType: state?.checkinType, 
      facility_id: state?.facility_id
    }
  );

  const authRequestData = useSelector(_selectItems);
  const authRequestDataToSubmit = useSelector(_selectData);
  const authRequestDiagnosis = useSelector(_selectDiagnosis);
  const authRequestCareCategory = useSelector(_selectCareCategory);
  const dispatcher = useDispatch();
  const [itemsRaw, setItemsRaw] = useState([]);
  const [itemsRawCopy, setItemsRawCopy] = useState([]);  //eslint-disable-line
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [costData, setCostData] = useState({});
  const [diagenosis, setDiagnosis] = useState([]);
  const [, setCareTypes] = useState([]);
  const [value, setValue] = useState([]);
  const [itemsList, setItmesList] = useState({ 1: [], 2: [], 3: [], 4: [] });
  const [, setFreePrice] = useState(false);
  const key = "pre";
  const [loadingDiagnosis, setLoadingDiagnosis] = useState(false);
  const [, setLoadingCareCategory] = useState(false);
  const [fetchingItem, setFetchingItem] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [sendingPA, setSendingPA] = useState(false);
  const [encounterCode, setEncounterCode] = useState('');

  const [enrolless, setEnrollees] = useState([]); // eslint-disable-line
  const [facilities, setFacilities] = useState([]); // eslint-disable-line

  useEffect(() => {
    if(checkinId){
      if(checkinType?.insurance_no) {
        fetchEnrolees(checkinType?.insurance_no, 1).then(data => {
          if(data?.length) {
            setEnrollees(data)
          }
        })
      }
      
      if(checkinType?.facility_id){
        fetchFacilities(state?.facility_name).then(data => {
          if(data?.length) {
            setFacilities(data);
          }
        })
      }
    }
   }, []) //eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (checkinType?.insurance_no && checkinType?.checkinType && checkinType?.facility_id) {
      myRequestObj('constableCategoriesList').post({})
        .then((res) => res?.data)
        .then((data) => {
          const dat = data?.data?.map((c) => {
            const this_dat = {};
            Object.entries(c).forEach((col) => {
              this_dat[col[0]] = col[1] || "";
            });
            setItmesList(d => ({...d, [c?.id] : []}))
            return this_dat;
          });
          setCategories(dat);
        })
        .catch((e) =>
          notification.error({
            message: e?.msg || e?.message,
          }),
        );
      fetchCareTypesList();
    }

  }, [checkinType]); //eslint-disable-line react-hooks/exhaustive-deps

  // async function fetchPAData(claimsId) {
  //   return myRequestObj(`paList`)
  //     .post({ checkinId: claimsId })
  //     .then((response) => response?.data)
  //     .then((body) => {
  //       const provList = body?.data?.diagnosis?.map((d) => ({
  //         label: `${d?.code} ${d?.name}`,
  //         value: d?.id,
  //       }));
  //       setProvDiagenosis(provList);
  //       return body?.data;
  //     });
  // }

  const addData = () => {
    const dataKeys = Object.keys(data);

    if (!dataKeys.includes("category") || !dataKeys.includes("item")) return;
    // if (!dataKeys.includes("category") || !dataKeys.includes("item") || !dataKeys.includes("approved_cost") || !dataKeys.includes("cost")) return;

    switch (data.category) {
      case 1:
        if (
          !(dataKeys.includes("dosage") && data["dosage"]) ||
          !(dataKeys.includes("frequency") && data["frequency"]) ||
          !(dataKeys.includes("duration") && data["duration"])
        )
          return;
        break;
      default:
        if (
          !(dataKeys.includes("quantity") && data["quantity"])
          //  ||!(dataKeys.includes("cost") && data["quantity"])
        )
          return;
    }

    if (Number(data["category"]) === 1 && dataKeys.length > 4) {
      const dt = {
        category: data?.category,
        item: data?.item,
        quantity: data?.quantity,
        cost: data?.cost || 0,
        approved_cost: data?.approved_cost || 0,
        duration: data?.duration,
        frequency: data?.frequency,
        dosage: data?.dosage,
        comment: data?.comment || ''
      };
      dispatcher(_add_item({ data: dt, items: items }));
    } else {
      const dt = {
        category: data?.category,
        item: data?.item,
        quantity: data?.quantity,
        cost: data?.cost || 0,
        approved_cost: data?.approved_cost || 0,
        comment: data?.comment || ''
      };
      dispatcher(_add_item({ data: dt, items: items }));
    }

    setData({ category: data?.category });
  };

  const addCategory = async (e) => {
    setItemsRaw([]);
    setItems((d) => ({ ...d, category: categories.find((d) => d.id === e) }));
    setData((d) => ({ ...d, category: e, item: null }));

    const cat = categories.find((d) => d.id === e);
    setSelectedCategory(cat);

    if (itemsList[cat.id].length) {
      setItemsRaw(itemsList[cat.id]);
      setItemsRawCopy(
        itemsList[cat.id]?.map((item) => ({
          label: (
            <>
              <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                <strong>Name: </strong>
                {item?.item_name}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                <strong>Code: </strong>
                {item?.item_code}
              </Typography.Paragraph>
              {item.item_brand && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Brand: </strong>
                  {item?.item_brand}
                </Typography.Paragraph>
              )}
              {item?.item_presentation && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Presentation: </strong>
                  {item?.item_presentation}
                </Typography.Paragraph>
              )}
              {item?.item_description && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Description: </strong>
                  {item?.item_description}
                </Typography.Paragraph>
              )}
            </>
          ),
          value: item.id,
        })),
      );
    } else {
      setItemsRaw([]);
      setItemsRawCopy([]);
    }
  };

  const addItem = (e) => {
    setItems((d) => ({ ...d, item: itemsRaw.find((d) => d.id === e.value) }));
    setData((d) => ({ ...d, item: e.value }));
    setCostData(0);
    setFreePrice(true);
    setData((d) => ({ ...d, cost: 0 }));

    fetchPrice({ item_id: e.value, facility_id: checkinType?.facility_id }).then(
      (d) => {
        if (d.length && d[0]["cost"]) {
          setCostData(d[0]);
        } else {
          setFreePrice(true);
        }
      },
    );
  };

  const addQuantity = (e) => {
    if (e < 0) return;
    if (Object.entries(costData).length) {
      setData((d) => ({
        ...d,
        quantity: e,
        cost: Number(costData.cost) * Number(e),
      }));
      setItems((d) => ({
        ...d,
        quantity: e,
        cost: Number(costData.cost) * Number(e),
      }));
    } else {
      setData((d) => ({ ...d, quantity: e }));
      setItems((d) => ({ ...d, quantity: e }));
    }
  };

  const addProvisionalDiagnosis = (e) => {
    const nn = e.map((i) => {
      return diagenosis.find((j) => j.id === i.value).code;
    });
    dispatcher(_add_diagnosis({ ...nn }));
    setValue(e);
  };

  // const addCareCategory = (e) => { //eslint-disable-line
  //   dispatcher(
  //     _add_care_category({ ...authRequestCareCategory, careCatValue: e }),
  //   );
  //   setValueCare(e);
  // };

  // const addCareType = async (e) => {
  //   if(!e){
  //     notification.error({message: 'Error Fetching Care Category: Unknow Checkin Type'});
  //     return;
  //   }
  //   setCareCategories([]);
  //   dispatcher(_add_care_category({ careTypeValue: e }));
  //   const payload = { checkinId: params.get("checkin"), careType: e };
  //   const careCat = await fetchCareCategoriesList(payload);
  //   const wrangled = careCateogoryListHandler(careCat);
  //   setCareCategories(wrangled);
  // };

  const send_preauth = async () => {
    // if (!state?.checkinType) {
    //   notification.warning({ message: "Kindly Select a Care Type!" });
    //   return;
    // }

    setSendingPA(true);
    notification.info({
      icon: <LoadingOutlined spin />,
      message: "Sending PA Data",
      key,
      duration: 0,
    });

    const PAData = {
      ...checkinType,
      source: 1,
      items: authRequestDataToSubmit,
      diagnosis: authRequestDiagnosis,
      careCategory: authRequestCareCategory.careCatValue,
      // careType: state?.checkinType,
    };

    if(checkinId) Object.assign(PAData, {update: 1, checkinId})

    const response = await myRequestObj(`paLog_manual`).post(PAData)

    let body = await response?.data;

    if (Number(body?.error)) {
      notification.error({ message: body?.message, key, duration: 2 });
      setSendingPA(false);
    } else {
      notification.success({ message: body?.message, key });
      setEncounterCode(body?.data[0]['checkinId']);
      setTimeout(() => notification.destroy(key), 3000);
      setSendingPA(false);
      dispatcher(_clean_items())
      // navigator("/checkins");
    }
  };

  async function fetchDiagnosisList(username) {
    setLoadingDiagnosis(true);
    return myRequestObj(`diagnosisList`).post({ search: username ?? "" })
      .then((response) => response?.data)
      .then((body) => {
        setDiagnosis((d) => [...body?.data, ...d]);
        return careDiagnosisListHandler(body?.data);
      })
      .catch((e) => {
        notification.error({
          message: e?.msg || e?.message,
        });
      })
      .finally(setLoadingDiagnosis(false));
  }

  const careDiagnosisListHandler = (items) => {
    return Array.isArray(items)
      ? items.map((item) => {
        return {
          label: (
            <>
              <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                <strong>Name: </strong>
                {item?.name}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                <strong>Code: </strong>
                {item?.code}
              </Typography.Paragraph>
              {item?.description && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Description: </strong>
                  {item?.description}
                </Typography.Paragraph>
              )}
            </>
          ),
          value: item?.id,
        };
      })
      : items;
  };

  const careItemListHandler = (items) => {
    return Array.isArray(items)
      ? items.filter(i => i.category_id === selectedCategory?.id).map((item) => {
        return {
          label: (
            <>
              <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                <strong>Name: </strong>
                {item?.item_name}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                <strong>Code: </strong>
                {item?.item_code}
              </Typography.Paragraph>
              {item.item_brand && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Brand: </strong>
                  {item?.item_brand}
                </Typography.Paragraph>
              )}
              {item?.item_presentation && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Presentation: </strong>
                  {item?.item_presentation}
                </Typography.Paragraph>
              )}
              {item?.item_description && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Description: </strong>
                  {item?.item_description}
                </Typography.Paragraph>
              )}
            </>
          ),
          value: item?.id,
        };
      })
      : items.filter(i => i.category_id === selectedCategory?.id);
  };

  // async function fetchCareCategoriesList(payload = {}) {
  //   setLoadingCareCategory(true);
  //   const $allData = [];
  //   try {
  //     const generator = fetchPaginatedData(
  //       "post",
  //       "careCategoriesList",
  //       payload,
  //     );

  //     for await (const data of generator) {
  //       $allData.push(...data);
  //     }

  //   } catch (error) {
  //     console.error("Error fetching care categories:", error);
  //   } finally {
  //     setLoadingCareCategory(false);
  //     return $allData;
  //   }
  // }

  async function fetchCareTypesList(payload = {}) {
    setLoadingCareCategory(true);
    const $allData = [];
    try {
      const generator = fetchPaginatedData("post", "careTypesList", payload);

      for await (const data of generator) {
        $allData.push(...data);
      }
      setCareTypes(
        $allData.map((item) => ({
          label: item?.checkin_type_name,
          value: item?.checkin_type_id,
        })),
      );
    } catch (error) {
      console.error("Error fetching care categories:", error);
    } finally {
      setLoadingCareCategory(false);
      return $allData;
    }
  }

  // const careCateogoryListHandler = (items) => {
  //   if(!items?.length) {
  //     // notification.error({message: 'Empty Care Category, The Patient is not eligible for this care type'})
  //     return;
  //   }
  //   setCareCategories(items);
  //   return Array.isArray(items)
  //     ? items.map((item) => {
  //         return {
  //           label: (
  //             <>
  //               <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
  //                 <strong>Name: </strong>
  //                 {item?.plans_items_name}
  //               </Typography.Paragraph>
  //               <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
  //                 <strong>Description: </strong>
  //                 {item?.plans_items_description}
  //               </Typography.Paragraph>
  //             </>
  //           ),
  //           value: item?.plans_items_id,
  //         };
  //       })
  //     : items;
  // };

  async function fetchItemsList(search) {
    if (!search) return;

    notification.info({
      message: "Loading Items List",
      key: "itmems",
    });

    setFetchingItem(true);
    try {
      const response = await myRequestObj(`itemsList`).post({ ...selectedCategory, search })

      const body = await response?.data;

      notification.info({
        message: "Items List Loaded",
        key: "itmems",
      });

      setItemsRaw(body.data);

      const rawData = careItemListHandler(body.data)
      setItemsRawCopy(rawData);
      setItmesList((j) => ({ ...j, [selectedCategory?.id]: [...j[selectedCategory?.id], ...body.data], }));
      setFetchingItem(false);

      return rawData;
    } catch (e) {
      // console.log(e)
      notification.error({
        message: e?.msg ?? e?.message,
      });

      setFetchingItem(false);

      return [];
    }
  }

  async function fetchPrice(username) {
    notification.info({
      message: "Loading Item Price",
      key: "price",
    });
    return myRequestObj(`itemPrice`).post(username)
      .then((response) => response?.data)
      .then((body) => {
        notification.info({
          message: "Item Price Loaded",
          key: "price",
        });
        return body.data;
      }).catch(e => {
        console.log('Message: ', e?.message || e?.msg)
      });
  }

  const clearPAData = (e) => {
    dispatcher(_remove_care_category());
    dispatcher(_remove_diagnosis());
    dispatcher(_clean_items());
    dispatcher(_clean_items());
    navigator("./checkins");
  };

  // const send_claim = async () => {
  //   navigator("/preauth");
  // };

  async function fetchFacilities(keyward) {
    if (keyward?.length < 4) return []
    return lib.searchProviders({ search: keyward })
      .then((response) => response?.data)
      .then((body) => {
        if (Number(body?.error)) {
          notification.error({
            message: body?.message
          })
          return []
        }

        // setFacilities(body?.data);
        return body?.data?.map((facility) => ({
          label: `${facility.facility_type ? facility.facility_type?.toUpperCase() + ' - ' : ''}${facility.facility_name} - ${facility?.facility_city} ${facility?.facility_state}`,
          value: facility?.facility_id,
          key: facility?.facility_id,
          title: 'facility'
        }));
      });
  }

  async function fetchEnrolees(keyward, source = 0) {
    if (keyward?.length < 3) return []
    if(!source) setCheckinType({});
    return lib.searchEnrolee({ search: keyward })
      .then((response) => response?.data)
      .then((body) => {
        if (Number(body?.error)) {
          notification.error({
            message: body?.message
          })
          return []
        }
        // setFacilities(body.data);
        return body.data.map((user) => ({
          label: `${user?.insurance_no}: ${user?.first_name} ${user?.middle_name ? user?.middle_name + ', ' : ''} ${user?.surname?.toUpperCase()}`,
          value: user?.insurance_no,
          key: user?.id,
          title: 'user'
        }));
      });
  }

  // const getEnrollee = async (search) => {
  //     setShowDetailFlag(false);
  //     setClientData({});

  //     const key = "verify";

  //     if (!clientSearch?.search || clientSearch?.length < 3) return;

  //     if(clientSearch.search.length < 3) {
  //       notification.error({
  //         message: 'The Search Key Can not be less than three characters',
  //       })
  //       return;
  //     }

  //     setLoading(true);

  //     notification.info({
  //       message: "Loading...",
  //       key,
  //       icon: <LoadingOutlined />,
  //       duration: 0,
  //       placement: "topLeft",
  //     });

  //     let reqData = await lib.search(clientSearch);

  //     if (reqData?.status === "error") {
  //       notification.error({
  //         message: reqData?.msg,
  //         key,
  //         duration: 3,
  //         placement: "topLeft",
  //       });
  //     }

  //     if (!reqData?.error) {
  //       setShowDetailFlag(true);
  //       notification.success({
  //         message: reqData?.data?.message,
  //         key,
  //         duration: 2,
  //         placement: "topLeft",
  //       });
  //       setClientData(reqData?.data?.data);
  //     }

  //     if (reqData?.error) {
  //       notification.error({
  //         message: reqData?.data?.message,
  //         key,
  //         duration: 2,
  //         placement: "topLeft",
  //       });
  //     }
  //     setLoading(false);
  // }

  // const getFacilities = async (search) => {
  //     // setShowDetailFlag(false);
  //     // setFacilityDate({});

  //     const key = "verify";

  //     if (!search || search < 3) return;

  //     // setLoading(true);

  //     notification.info({
  //       message: "Loading...",
  //       key,
  //       icon: <LoadingOutlined />,
  //       duration: 0,
  //       placement: "topLeft",
  //     });

  //     let reqData = await lib.searchProviders(clientSearch);

  //     if (reqData?.status === "error") {
  //       notification.error({
  //         message: reqData?.msg,
  //         key,
  //         duration: 3,
  //         placement: "topLeft",
  //       });
  //     }

  //     if (!reqData?.error) {
  //       setShowDetailFlag(true);
  //       notification.success({
  //         message: reqData?.data?.message,
  //         key,
  //         duration: 2,
  //         placement: "topLeft",
  //       });
  //       setFacilityData(reqData?.data?.data);
  //     }

  //     if (reqData?.error) {
  //       notification.error({
  //         message: reqData?.data?.message,
  //         key,
  //         duration: 2,
  //         placement: "topLeft",
  //       });
  //     }

  //     setLoading(false);
  //     setPreviousSearchTerm(clientSearch);
  //   }

  const addUserData = e => {
    console.log(e)
    switch (e?.title) {
      case 'facility':
        setCheckinType(d => ({ ...d, facility_id: e.value }))
        break;

      case 'user':
        showDetail({ insurance_no: e.value, user_id: e.key });
        setCheckinType(d => ({ ...d, insurance_no: e.value, user_id: e.key }));
        break;

      default: break;
    }
  }

  const showDetail = async (payload) => {
    setShowDetailFlag(false);
    setClient({});
    setCheckinType({});

    const key = "verify";

    await sleep(1000)

    if (!Object.keys(payload).length) return;

    notification.info({ message: "Loading...", key });

    let reqData = await (await lib.getClient(payload)).data;

    if (reqData?.error === 1) {
      notification.error({ message: reqData.message, key, duration: 3 });
    }

    if (reqData?.error === 0) {
      setShowDetailFlag(true);
      notification.success({ message: reqData?.message, key, duration: 2 });
      setClient(reqData?.data);
    }
  };
  
  // const clearLocationState = () => { navigator({ pathname: history.location.pathname, state: {}, search: ''});

  const generateEncounterCode = () => {
    // const history = useHistory(); 
    setEncounterCode('');
    setCheckinType({})
    dispatcher(_clean_items())
    setShowDetailFlag(false);
    setClient();
    setCheckinType({});
    navigator({ pathname: window.location.pathname, state: {}, search: ''})
  }

  const closeFiling = () => {
    setShowDetailFlag(false);
    dispatcher(_clean_items())
    setClient();
    setCheckinType({});
    navigator("/checkins");
  }

  return (
    <>
      <PageTitleHook
        title={
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>File An Encounter</div>
            {checkinId && <div><InputCopy value={checkinId} style={{fontSize: '20px', minWidth: '300px'}}/></div>}
          </div>
        }
        style={{ fontSize: "1.8em", marginTop: "1em", marginBottom: "1em" }}
      />

      <hr />
      {
        encounterCode ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh', // Full height of the viewport
          width: '80vw',  // Full width of the viewport
        }}>
          {/* The existing component */}
          <div style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            marginBottom: 20,
            placeContent: 'center',
          }}>
            <span style={{ fontSize: '30px' }}>
              Encounter Code for:
              <span style={{ color: 'Highlight', display: 'flex', gap: 20, fontSize: '30px' }}>
                <span style={{}}>
                  <strong>{client?.surname?.toUpperCase()}</strong> {" "} <strong>{client?.first_name}</strong>
                </span>
                <span style={{}}>
                  <strong>{checkinType?.insurance_no || "N/A"}</strong>
                </span>
              </span>
            </span>

            <InputCopy
              type="text"
              value={encounterCode}
              style={{ padding: 30, fontSize: '30px', borderWidth: '5px', align: 'center' }}
              aria-label="Encounter Code Input"
            />

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <Button type="primary" onClick={closeFiling}>Close</Button>
              <Button type="primary" onClick={generateEncounterCode}>File New Encounter</Button>
            </div>
          </div>
        </div>
          : <>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, marginBottom: 20 }}>
              <div style={{ maxHeight: "600px", width: '50%' }}>
                <label className="form-label">Search for Enrollee</label>
                <DebounceSelect2 fetchOptions={fetchEnrolees}
                  value={client?.insurance_no}
                  placeholder="Type to see enrollees"
                  onChange={addUserData}
                  style={{ width: '100%' }}
                  // options={enrolless}
                  disabled={checkinId}
                />
              </div>
              {client?.insurance_no && <div style={{ maxHeight: "600px", width: '50%' }}>
                <label className="form-label">Select Facility</label>
                <DebounceSelect2 fetchOptions={fetchFacilities}
                  value={client?.facility_id}
                  placeholder="Type to see facilities"
                  onChange={addUserData}
                  style={{ width: '100%' }}
                  // options={facilities}
                  disabled={checkinId}
                />
              </div>
              }
            </div>
            {
              client?.insurance_no &&
              <ClientDataHook
                data={client} style={{ maxWidth: 750 }}
                setCheckinType={e => setCheckinType(d => ({ ...d, checkinType: e }))}
                checkinInfo={checkinType}
                inputDisabled={checkinId}
              />
            }
            {
              (checkinType?.insurance_no && checkinType?.checkinType && checkinType?.facility_id) && <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="form-group" style={{ width: "100%" }}>
                    {/* <div className="form-group mx-2 gap-2" style={{ width: "100%" }}> */}
                    {/* <label className="form-label">Care Type</label> */}
                    {/* <Select
                    showSearch
                    value={Number(state?.checkinType)}
                    style={{ width: "100%" }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={addCareType}
                    options={careTypes}
                    disabled
                  /> */}
                    {/* </div> */}
                    {/* <div className="form-group mx-2 gap-2" style={{ width: "100%" }}> */}
                    {/* <label className="form-label">Care Category</label> */}
                    {/* <Select
                    showSearch
                    value={authRequestCareCategory.careCatValue}
                    style={{ width: "100%" }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={addCareCategory}
                    options={careCategories}
                  /> */}
                    {/* </div> */}
                    <div className="form-group mx-2 gap-2" style={{ width: "100%" }}>
                      <label className="form-label">Diagnosis - Provisional </label>
                      <DebounceSelect
                        mode="multiple"
                        value={value}
                        placeholder="Select Provisional Diagnosis"
                        fetchOptions={fetchDiagnosisList}
                        generatorOption={false}
                        onChange={addProvisionalDiagnosis}
                        optionHandler={careDiagnosisListHandler}
                        style={{ width: "100%" }}
                        loadingDiagnosis={loadingDiagnosis}
                        maxTagCount={4}
                        aborter
                      />
                    </div>
                  </div>
                  <div className="form-group" style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "30px", marginBottom: "10px", gap: 10 }}>
                    <div className="form-group" style={{ width: "50%", gap: 10 }}>
                      <div className="form-group" style={{ width: "100%" }}>
                        <label className="form-label me-2">Request Type</label>
                        <Select
                          value={data?.category}
                          style={{ width: "100%" }}
                          placeholder="Select"
                          onChange={addCategory}
                        >
                          {categories?.map((item) => {
                            return <Select.Option value={item.id}>{item.category_name}</Select.Option>;
                          })}
                        </Select>
                      </div>
                      <div className="form-group" style={{ width: "100%" }}>
                        <label className="form-label me-2">Comment</label>
                        <TextArea
                          onChange={e => {
                            if (e?.target?.value < 0) return;
                            setData((d) => ({ ...d, comment: e?.target?.value }));
                            setItems((d) => ({ ...d, comment: e?.target?.value }));
                          }}
                          rows={5}
                          value={data?.comment} placeholder="Preauthorization Comment..." style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div style={{ width: "50%", display: 'flex', flexDirection: 'column' }}>
                      <div className="form-group" style={{ width: "100%" }}>
                        <label className="form-label me-2">Item Name</label>
                        <DebounceSelect2
                          disabled={!Object.entries(selectedCategory).length}
                          showSearch
                          value={data?.item}
                          placeholder="Select Care Request Item"
                          fetchOptions={fetchItemsList}
                          generatorOption={false}
                          onChange={addItem}
                          optionHandler={careItemListHandler}
                          style={{ width: "100%" }}
                          loadingDiagnosis={fetchingItem}
                          aborter
                        />
                      </div>
                      {Object.keys(data).includes("category") && Number(data["category"]) === 1 ? (
                        <div
                          className="form-group mx-2"
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: 10
                          }}
                        >
                          <div
                            className="form-group"
                            style={{ width: "33.333333%" }}
                          >
                            <label className="form-label me-2">Dosage</label>
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder={"12 tablets"}
                              min={0}
                              value={
                                Object.keys(data).length &&
                                  Object.keys(data).includes("dosage")
                                  ? data["dosage"]
                                  : ""
                              }
                              onChange={(e) => {
                                if (e < 0) return;
                                setData((d) => ({ ...d, dosage: e }));
                                setItems((d) => ({ ...d, dosage: e }));
                              }}
                              keyboard={false}
                            />
                          </div>
                          <div className="form-group" style={{ width: "33.333333%", flexDirection: 'column' }}>
                            <label className="form-label">Frequency</label>
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder={0}
                              min={0}
                              value={
                                Object.keys(data).length &&
                                  Object.keys(data).includes("frequency")
                                  ? data["frequency"]
                                  : 0
                              }
                              onChange={(e) => {
                                if (e < 0) return;
                                setData((d) => ({ ...d, frequency: e }));
                                setItems((d) => ({ ...d, frequency: e }));
                              }}
                              keyboard={false}
                            />
                          </div>
                          <div className="form-group" style={{ width: "33.333333%" }}>
                            <label className="form-label">Duration</label>
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder={0}
                              min={0}
                              value={
                                Object.keys(data).length &&
                                  Object.keys(data).includes("duration")
                                  ? data["duration"]
                                  : 0
                              }
                              keyboard={false}
                              onChange={(e) => {
                                if (e < 0) {
                                  notification.error({
                                    message: "Duration Cannot be less than zero!",
                                  });
                                  return;
                                }
                                setData((d) => ({ ...d, duration: e }));
                                setItems((d) => ({ ...d, duration: e }));
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div
                        className="form-group mx-2"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                          gap: 10
                        }}
                      >
                        <div className="form-group" style={{ width: "33.333333%" }}>
                          <label className="form-label me-2">Quantity</label>
                          <InputNumber
                            placeholder={0}
                            style={{ width: "100%" }}
                            onChange={addQuantity}
                            value={
                              Object.keys(data).length &&
                                Object.keys(data).includes("quantity")
                                ? data["quantity"]
                                : 0
                            }
                            keyboard={false}
                          />
                        </div>
                        <div
                          className="form-group gap-2"
                          style={{ width: "33.333333%" }}
                        >
                          <label className="form-label me-2">Filed Cost</label>
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder={0}
                            disabled={!Object.keys(costData).length ? false : true}
                            onChange={(e) => {
                              if (e < 0) return;
                              setData((d) => ({ ...d, cost: e }));
                              setItems((d) => ({ ...d, cost: e }));
                            }}
                            value={
                              Object.keys(data).length && Object.keys(data).includes("cost")
                                ? data.cost
                                : 0
                            }
                            keyboard={false}
                          />
                        </div>
                        <div
                          className="form-group gap-2"
                          style={{ width: "33.333333%" }}
                        >
                          <label className="form-label me-2">Amount Approved</label>
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder={0}
                            // disabled={!Object.keys(costData).length ? false : true}
                            onChange={(e) => {
                              if (e < 0) return;
                              setData((d) => ({ ...d, approved_cost: e }));
                              setItems((d) => ({ ...d, approved_cost: e }));
                            }}
                            value={
                              Object.keys(data).length && Object.keys(data).includes("approved_cost")
                                ? data.approved_cost
                                : 0
                            }
                            keyboard={false}
                          />
                        </div>
                        <div
                          className="form-group gap-2"
                          style={{
                            // width: "33.333333%",
                            display: "flex",
                            placeItems: 'baseline',
                            placeSelf: 'end'
                          }}
                        >
                          <Button
                            // className="btn-dark"
                            type="primary"
                            onClick={addData}
                            style={{ width: "100%", display: "flex", placeContent: 'center', placeItems: 'center' }}
                            // disabled={!(Number(data?.cost) && Number(data?.approved_cost) && Number(data?.quantity)) }
                            disabled={!(Number(data?.quantity))}
                          >
                            <PlusOutlined /> Add
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <table className="table align-middle mb-0 bg-white">
                    <thead className="bg-light">
                      <tr>
                        <th>S/N</th>
                        <th>Request type</th>
                        <th>Item Name</th>
                        <th>Dosage</th>
                        <th>Frequency</th>
                        <th>Duration</th>
                        <th>Quantity</th>
                        <th>F Cost</th>
                        <th>A Cost</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {authRequestData.map((item, index) => {
                        return (
                          <AuthorizationRequestDataListHook data={item} index={index} />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "30px",
                    justifyContent: "space-between",
                    gap: "5em",
                  }}
                >
                  <Button
                    type="primary"
                    danger
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={clearPAData}
                    disabled={sendingPA}
                    icon={<CloseOutlined />}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={send_preauth}
                    // disabled={!!!authRequestData.length || sendingPA}
                    icon={<SendOutlined />}
                  >
                    Send PAR
                  </Button>
                </div>
              </div>
            }
          </>
      }
    </>
  );
};

// const AuthorizationRequestDataListHook = (props) => {
//   console.log(props?.data)
//   const [itemsQueryStates, ] = useState({
//     itemId: props?.data?.id_,
//     status: 0,
//   });

//   const [itemQueryReqStatus, ] = useState(false);

//   const [itemRejectReqStatus, ] = useState(false);

//   const authorize = () => {
//     if (Number(props?.data?.claim_item_status) === 1) {
//       Modal.success({
//         title: "Approval Memo",
//         content: (
//           <div style={{ marginTop: "10px", maxHeight: 400, overflowY: "auto" }}>
//             {props?.data?.comment}{" "}
//           </div>
//         ),
//         width: 800,
//       });
//     } else {
//       Modal.error({
//         title: "Rejection Memo",
//         content: (
//           <div style={{ marginTop: "10px", maxHeight: 400, overflowY: "auto" }}>
//             {props?.data?.comment}{" "}
//           </div>
//         ),
//         width: 800,
//       });
//     }
//   };

//   return (
//     <>
//       <tr>
//         <td className="fw-bold">{props?.index + 1}</td>
//         <td className="fw-bold">
//           <span>
//             <strong>
//               {Array.from(props?.data?.auth_code ?? "")
//                 .filter((i) => i !== ".")
//                 .join("")}
//             </strong>
//           </span>
//         </td>
//         <td className="fw-bold">
//           <span>
//             <strong>{props?.data?.items_name}</strong>
//           </span>
//         </td>
//         <td className="fw-bold">
//           <span>
//             <strong>{props?.data?.category_name?.split(" ")[0]}</strong>
//           </span>
//         </td>
//         <td className="fw-bold">
//           <span>
//             <strong>{props?.data?.item_name}</strong>
//           </span>
//         </td>
//         <td>
//           <span>
//             <strong>{props?.data?.quantity}</strong>
//           </span>
//         </td>
//         <td>
//           <span>
//             <strong>{props?.data?.cost}</strong>
//           </span>
//         </td>
//         <td>
//           <span style={{ width: 100 }} className="fw-normal mb-1 fw-bold">
//             {props?.data?.approved_cost}
//           </span>
//         </td>
//         <td>
//           <Space split={<Divider orientation="vertical" />}>
//             {Number(props?.data?.claim_item_status) === 1 ? (
//               <Button
//                 type={
//                   Number(props?.data?.claim_item_status) === 3
//                     ? "danger"
//                     : Number(props?.data?.claim_item_status) === 2
//                     ? "dashed"
//                     : Number(props?.data?.claim_item_status) === 1
//                     ? "primary"
//                     : "default"
//                 }
//                 disabled={
//                   !Number(props?.data?.claim_item_status) ||
//                   !Number(props?.data?.claim_item_status) === 3
//                 }
//                 onClick={
//                   Number(props?.data?.claim_item_status) !== 2 ? authorize : null
//                 }
//               >
//                 {" "}
//                 <span>
//                   {" "}
//                   <TiDocumentText color="white" /> Authourized
//                 </span>
//               </Button>
//             ) : Number(props?.data?.claim_item_status) === 3 ? (
//               <Button
//                 type={
//                   Number(props?.data?.claim_item_status) === 3
//                     ? "danger"
//                     : Number(props?.data?.claim_item_status) === 2
//                     ? "dashed"
//                     : Number(props?.data?.claim_item_status) === 1
//                     ? "primary"
//                     : "default"
//                 }
//                 disabled={
//                   !Number(props?.data?.claim_item_status) ||
//                   !Number(props?.data?.claim_item_status) === 3
//                 }
//                 onClick={
//                   Number(props?.data?.claim_item_status) !== 2 ? authorize : null
//                 }
//               >
//                 <span>
//                   {" "}
//                   <TiDocumentText color="white" /> Rejected
//                 </span>
//               </Button>
//             ) : (
//               <Button
//                 type={
//                   Number(props?.data?.claim_item_status) === 3
//                     ? "danger"
//                     : Number(props?.data?.claim_item_status) === 2
//                     ? "dashed"
//                     : Number(props?.data?.claim_item_status) === 1
//                     ? "primary"
//                     : "default"
//                 }
//                 disabled={
//                   !Number(props?.data?.claim_item_status) ||
//                   !Number(props?.data?.claim_item_status) === 3
//                 }
//                 onClick={
//                   Number(props?.data?.claim_item_status) !== 2 ? authorize : null
//                 }
//               >
//                 Pending
//               </Button>
//             )}
//             <PAQuery
//               data={props.data}
//               itemQueryReqStatus={itemQueryReqStatus}
//               itemRejectReqStatus={itemRejectReqStatus}
//               itemsQueryStates={itemsQueryStates}
//             />
//           </Space>
//         </td>
//       </tr>
//     </>
//   );
// };

const AuthorizationRequestDataListHook = (props) => {
  const authRequestData = useSelector(_selectData);
  const authRequestItems = useSelector(_selectItems);
  const dispatcher = useDispatch();
  const id = props.index;

  const remove = () => {
    let data = authRequestData.filter((d, i) => {
      return i !== id;
    });
    let items = authRequestItems.filter((d, i) => {
      return i !== id;
    });
    dispatcher(_reset_items({ data: data, items: items }));
  };

  return (
    <>
      <tr>
        <td>
          <p className="fw-normal mb-1 fw-bold">{id + 1}</p>
        </td>
        <td>
          <p className="fw-normal mb-1 fw-bold">
            {props?.data?.category?.category_name}
          </p>
        </td>
        <td className="fw-bold">
          <p className="fw-normal mb-1 fw-bold">{props?.data?.item?.item_name}</p>
        </td>
        {Number(props?.data?.category.id) === 1 &&
          Object.keys(props?.data).find((i) => i === "dosage") !== undefined ? (
          <td className="fw-bold">
            <p className="fw-normal mb-1 fw-bold">
              <CurrencyFormat
                value={props?.data?.dosage}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
          </td>
        ) : (
          <td></td>
        )}
        {Number(props?.data?.category.id) === 1 &&
          Object.keys(props?.data).find((i) => i === "frequency") !== undefined ? (
          <td className="fw-bold">
            <p className="fw-normal mb-1 fw-bold">
              <CurrencyFormat
                value={props?.data?.frequency}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
          </td>
        ) : (
          <td></td>
        )}
        {Number(props?.data?.category.id) === 1 &&
          Object.keys(props?.data).find((i) => i === "duration") !== undefined ? (
          <td className="fw-bold">
            <p className="fw-normal mb-1 fw-bold">
              <CurrencyFormat
                value={props?.data?.duration}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
          </td>
        ) : (
          <td></td>
        )}
        <td>
          <p className="fw-normal mb-1 fw-bold">
            <CurrencyFormat
              value={props?.data?.quantity}
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        </td>
        <td>
          <p className="fw-normal mb-1 fw-bold">
            <CurrencyFormat
              value={props?.data?.cost}
              prefix="₦"
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        </td>
        <td>
          <p className="fw-normal mb-1 fw-bold">
            <CurrencyFormat
              value={props?.data?.approved_cost}
              prefix="₦"
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        </td>
        <td>
          {/* <Button type="warning" > */}
          <MinusSquareOutlined style={{ fontSize: 22 }} onClick={remove} title="Remove Item" />
          {/* </Button> */}
        </td>
      </tr>
    </>
  );
};

const ClientDataHook = (props) => {
  const [checkinTypes, setCheckinTypes] = useState([]);
  const [loadingCareCategory, setLoadingCareCategory] = useState(false);

  const style = {
    ...props.style,
    display: "flex",
    color: "black",
    fontSize: "1.3em",
    textAlign: "left",
    padding: ".2rem",
    borderWidth: ".2em",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, .4)",
    borderRadius: ".5em",
  };

  async function fetchCareTypesList(payload = {}) {
    setLoadingCareCategory(true);
    const $allData = [];
    try {
      const generator = fetchPaginatedData("post", "careTypesList", payload);

      for await (const data of generator) {
        $allData.push(...data);
      }
      setCheckinTypes(
        $allData.map((item) => ({
          label: item?.checkin_type_name?.toUpperCase(),
          value: item?.checkin_type_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching care categories:", error);
    } finally {
      setLoadingCareCategory(false);
      return $allData;
    }
  }

  useState(fetchCareTypesList, []);

  return (
    <>
      <Row className="card-c" style={style} align="middle">
        <Col span={4} className="img-round img-medium">
          <img
            src={
              props?.data?.pic_name ? `${uri_img}${props?.data?.pic_name}` : logo
            }
            alt=""
            style={{
              width: "120px",
              height: "120px",
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              margin: ".3em",
            }}
            className="rounded-circle"
          />
        </Col>
        <Col
          span={20}
          className="card-c-details"
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifySelf: "center",
            paddingLeft: "30px",
          }}
        >
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Full Name:
            </Col>
            <Col className="card-c-value">
              {props?.data?.surname ? props?.data?.surname?.toUpperCase() + ", " : ""}
              {props?.data?.first_name ? props?.data?.first_name + " " : ""}
              {props?.data?.middle_name ? props?.data?.middle_name : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Insurance ID:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props?.data?.insurance_no ? props?.data?.insurance_no : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Premium Type:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props?.data?.insurance_package
                ? props?.data?.insurance_package
                : ""}{" "}
              | {props?.data?.premium_type ? props?.data?.premium_type : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Premium Status:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props?.data?.status ? `Active` : "Expired"}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
            </Col>
            <Col className="card-c-value">
              <EnrolleeConsumption userId={props?.data?.insurance_no} enrolleeData={props?.data} />
            </Col>
          </Row>
        </Col>
        {props?.checkinInfo?.facility_id && <Col span={24} style={{ marginTop: ".3rem", color: "blue", fontSize: ".6em" }}>
          {/* <div className="input-group"> */}
          <Select
            placeholder="Select Clinic Type"
            style={{ width: "100%" }}
            onChange={props.setCheckinType}
            value={props?.checkinInfo?.checkinType}
            options={checkinTypes}
            disabled={loadingCareCategory || props?.inputDisabled}
          />
          {/* </div> */}
        </Col>}
      </Row>

    </>
  );
};

// export const ClientDataHook = ({facility_id, ...props}) => {
//   const dispatcher = useDispatch(selectChechin);
//   const { user } = useAuth();
//   const navigate = useNavigate();
//   const [, setShowDetailFlag] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [checkinTypes, setCheckinTypes] = useState([]);
//   const [loadingCareCategory, setLoadingCareCategory] = useState(false);

//   async function fetchCareTypesList(payload = {}) {
//     setLoadingCareCategory(true);
//     const $allData = [];
//     try {
//       const generator = fetchPaginatedData("post", "careTypesList", payload);

//       for await (const data of generator) {
//         $allData.push(...data);
//       }
//       setCheckinTypes(
//         $allData.map((item) => ({
//           label: item?.checkin_type_name?.toUpperCase(),
//           value: item?.checkin_type_id,
//         }))
//       );
//     } catch (error) {
//       console.error("Error fetching care categories:", error);
//     } finally {
//       setLoadingCareCategory(false);
//       return $allData;
//     }
//   }

//   useState(fetchCareTypesList, []);

//   console.log(props?.data)

//   const style = {
//     display: "flex",
//     color: "blue",
//     fontSize: ".6em",
//     textAlign: "left",
//     padding: ".2rem",
//     borderWidth: ".2em",
//     borderStyle: "solid",
//     borderColor: "rgba(0, 0, 0, .4)",
//     borderRadius: ".5em",
//   };

//   // const options = [
//   //   { value: "1", key: "In-Patient" },
//   //   { value: "2", key: "Out-Patient" },
//   //   { value: "3", key: "Antenatal" },
//   // ];

//   const [checkinType, setCheckinType] = useState({
//     insurance_no: props?.data?.insurance_no,
//     user_id: props?.data?.id,
//     facility_id,
//   });

//   const verifyCheckin = async () => {
//     setLoading(true);

//     if (Object.keys(checkinType).length < 4) return;

//     const key = "verifyCheck";

//     const request = await fetch(`${uri}?checkCheckin`, {
//       method: "POST",
//       body: JSON.stringify(checkinType),
//       headers: {
//         ...header,
//         Authorization: `Bearer ${user.jwt}`,
//       },
//     });

//     const response = await request?.json();

//     switch (response.error) {
//       case 1:
//         notification.warning({
//           message: "Duplicate Checkin Alert!",
//           description: (
//             <span>
//               {response.message}. <br></br>Are you sure want to proceed as
//               out-patient?
//             </span>
//           ),
//           btn: (
//             <>
//               <Button
//                 type="primary"
//                 danger
//                 onClick={() => {
//                   notification.close(key);
//                   checkin();
//                 }}
//               >
//                 Confirm
//               </Button>
//               <Divider type="vertical" />
//               <Button
//                 type="primary"
//                 danger
//                 onClick={() => notification.close(key)}
//               >
//                 Cancle
//               </Button>
//             </>
//           ),
//           key,
//         });
//         return;

//       case 2:
//         notification.warning({
//           message: "Duplicate Checkin Alert!",
//           description: (
//             <span>
//               {response.message}. <br></br>Are you sure want to proceed as
//               in-patient?
//             </span>
//           ),
//           btn: (
//             <>
//               <Button
//                 type="primary"
//                 danger
//                 onClick={() => {
//                   notification.close(key);
//                   checkin();
//                 }}
//               >
//                 Confirm
//               </Button>
//               <Divider type="vertical" />
//               <Button
//                 type="primary"
//                 danger
//                 onClick={() => notification.close(key)}
//               >
//                 Cancle
//               </Button>
//             </>
//           ),
//           key,
//         });
//         return;

//       case 3:
//         notification.error({
//           message: "Duplicate Checkin Alert!",
//           description: <span>{response.message}.</span>,
//           key,
//           duration: 2,
//         });
//         return;

//       default:
//         checkin();
//     }
//   };

//   const checkin = async () => {
//     const key = "verifyCheck";

//     message.loading({ content: "Loading...", key });

//     let reqData = await lib.checkin(checkinType);

//     if (reqData?.error === 1) {
//       message.error({ content: reqData.message, key, duration: 3 });
//     }

//     if (reqData?.error === 0) {
//       setShowDetailFlag(true);
//       message.success({ content: reqData.message, key, duration: 2 });
//       dispatcher(add_checkin(checkinType));
//       setTimeout(() => {
//         navigate("/checkins");
//       }, 200);
//     }

//     setLoading(true);
//   };

//   const promptMessages = {
//     1: 'Are you certain this client is getting admitted?',
//     2: 'Are you certain this client is her for out-patient services?',
//     3: 'Are you certain this patient is here for antenatal service?',
//     4: 'Are you sure this patient is here for dental care?',
//     5: 'Are you sure this patient is here for eye care?'
//   }

//   return (
//     <>
//       <div className="card-c" style={style}>
//         <div className="img-round img-medium">
//           <img
//             src={props?.data?.pic_name ? `${uri_img}${props?.data?.pic_name}` : logo}
//             alt=""
//             style={{
//               width: "120px",
//               height: "120px",
//               display: "flex",
//               alignSelf: "center",
//               justifySelf: "center",
//               margin: ".3em",
//             }}
//             className="rounded-circle"
//           />
//         </div>
//         <div
//           className="card-c-details"
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             alignSelf: "center",
//             justifySelf: "center",
//           }}
//         >
//           <div className="card-c-datarow">
//             <span className="card-c-label">Patient Full Name</span>:
//             <span className="card-c-value">
//               {" "}
//               {props?.data?.surname.toUpperCase()}, {props?.data?.first_name}{" "}
//               {props?.data?.middle_name}
//             </span>
//           </div>
//           <div className="card-c-datarow">
//             <span className="card-c-label">Patient Insurance ID</span>:
//             <span className="card-c-value"> {props?.data?.insurance_no}</span>
//           </div>
//           <div className="card-c-datarow">
//             <span className="card-c-label">Patient Premium Type</span>:
//             <span className="card-c-value">
//               {" "}
//               {/* {props?.data?.insurance_package} | {props?.data?.premium_type} */}
//               {props?.data?.premium_type}
//             </span>
//           </div>
//           <div className="card-c-datarow">
//             <span className="card-c-label">Patient Premium Status</span>:
//             <span className="card-c-value">
//               {" "}
//               {props?.data?.status ? `Active` : "Expired"}
//             </span>
//           </div>
//         </div>
//       </div>
//       {parseInt(props?.data?.status) && [0, 1].includes(Number(user.role)) ? (
//         <div style={{ marginTop: ".3rem", color: "blue", fontSize: ".6em" }}>
//           <div className="input-group">
//             <Select
//               showSearch
//               placeholder="Select Clinic Type"
//               optionFilterProp="children"
//               filterOption={(input, option) =>
//                 option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
//               }
//               filterSort={(optionA, optionB) =>
//                 optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase())
//               }
//               style={{ width: "calc(100% - 144.43px" }}
//               onChange={(e) =>
//                 setCheckinType((d) => ({ ...d, checkinType: e }))
//               }
//               options={checkinTypes}
//               disabled={loadingCareCategory}
//             />
//             {/* {checkinTypes.map((item) => {
//                 return <Option value={item.value}>{item.key}</Option>;
//               })} */}
//             {/* </Select> */}
//             <PopConfirm
//               title={promptMessages[Number(checkinType['checkinType'])]}
//               onConfirm={verifyCheckin}
//               triggerButtonProps={{ disabled: loading, type: 'default', style: { width: '100%' } }}
//               triggerButtonText={'Check Client In'}
//             />
//           </div>
//         </div>
//       ) : null}
//     </>
//   );
// };

const PAFiling = () => {
  // let params = useRef(new URLSearchParams(window.location.search));
  const location = useLocation();

  const state = location?.state;
  const checkinId = (new URLSearchParams(window.location.search)).get('eid');

  useEffect(() => {
    // console.log(state);
    // console.log(checkinId);
  }, []); //eslint-disable-line

  return (
    <PARequestHook state = {state} checkinId={checkinId}/>
  );
};

export default PAFiling;
