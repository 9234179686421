import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { Select, Button, Input, Spin, message, Row, Col, Segmented, Empty, Avatar, Badge, Modal, Upload, notification } from 'antd';
import Draggable from 'react-draggable';
import { SendOutlined, DeleteColumnOutlined, PlusOutlined, LoadingOutlined, CheckOutlined, } from '@ant-design/icons';
import { RiHospitalFill } from "react-icons/ri";
import PageTitleHook from "../pagetitle";
import debounce from 'lodash/debounce';
import { uri, uri_img } from "../../assets/utils/http-request";
import CurrencyFormat from 'react-currency-format';

import './claims.css';
import { useAuth } from "../../assets/utils/useAuth";
import config from "../../assets/utils/config";
import { selectclient } from '../../stores/verify';
import Helpers from "../../assets/utils/Helpers";
import { myRequest, myRequestObj } from "../../assets/utils/lib";
import Loader from "../compoinents/loader";

const { TextArea } = Input;
let fileses = {};
let uploadData = {};

const NullSafe = (data) => {
  return data?.map(c => {
    const this_dat = {};
    Object.entries(c).forEach(col => {
      this_dat[col[0]] = col[1] || '';
    })
    return this_dat;
  })
}

export const ClaimsRequestHook = () => {
  const [items, setItems] = useState([]);
  const [complaint, setCompaint] = useState('');
  const [diagenosis, setDiagnosis] = useState([]);
  const [diagData, setDiagData] = useState([]);
  const [provDiagenosis, setProvDiagenosis] = useState([]);
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigator = useNavigate();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    fetchPAData(params.get('checkin')).then(res => {
      setItems(res.items)
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchDiagnosisList(username) {
    return myRequestObj('diagnosisList').post({ search: username })
      .then((response) => response?.data)
      .then((body) => {
        setDiagnosis(body.data);
        return body.data.map((user) => ({
          label: `${user.code} ${user.name}`,
          value: user.id,
        }));
      });
  }

  const upload = async () => {
    if (!Object.keys(fileses).length) {
      return;
    }
    const uploadDataWithFiles = new FormData();
    Object.entries(fileses).forEach((i) => {
      uploadDataWithFiles.append(i[0], i[1]);
    });
    uploadDataWithFiles.append('item', JSON.stringify(uploadData))
    notification.info({ message: "Uploding Claim Document" });
    const res = await myRequestObj(`?saveItemDoc`).post(uploadDataWithFiles)
    const body = await res?.data
    if (body?.error) {
      message.error({ content: body?.message });
    } else {
      message.success({ content: body?.message });
    }
    return body?.error;
  }

  async function fetchPAData(claimsId) {
    return myRequestObj(`?paList`).post({ checkinId: claimsId })
      .then((response) => response?.data)
      .then((body) => {
        const provList = body?.data?.diagnosis?.map((d) => ({
          label: `${d.code} ${d.name}`,
          value: d.id,
        }))
        setProvDiagenosis(provList)
        return body?.data;
      });
  }

  const addDiagnosis = e => {
    const nn = e.map(i => {
      return diagenosis.find(j => j.id === i.value)
    })
    setDiagData(nn);
    setValue(e);
  }

  const send_claim = async () => {
    setLoading(true);
    if (await upload()) return;
    const key = 'key';
    notification.info({ message: 'Sending PA Data', key: key, duration: 3 });
    const claimData = { complaint: complaint, diagnosis: diagData, checkinId: params.get('checkin') };
    myRequestObj(`claimLog`).post(claimData)
      .then((response) => response?.data)
      .then((body) => {
        if (body?.error) {
          message.error({ content: body?.message, key: key, duration: 2 })
        } else {
          message.success({ content: body?.message, key: key, duration: 3 });
          setValue([]);
          navigator('/checkins')
        }
      });
    setLoading(false);
  }

  return (
    <>
      <PageTitleHook title={`Compose Claim Request`} style={{ fontSize: '.2em' }} />
      <hr />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '10px', marginBottom: '10px' }}>
        <div className='form-group' style={{ width: '100%', marginBottom: '5px' }}>
          <label className='form-label'>Presenting Complaints</label>
          <TextArea rows={3} style={{ width: '100%' }} onChange={e => setCompaint(e.target.value)} />
        </div>
        <div className='form-group' style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }} >
          <label className='form-label'>Diagnosis - Final</label>
          <DebounceSelect
            mode="multiple"
            value={value}
            placeholder="Select Final Diagnosis"
            fetchOptions={fetchDiagnosisList}
            onChange={addDiagnosis}
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <hr />
      <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
        <label className='form-label'>Diagnosis - Provisional</label>
        <Select
          mode="multiple"
          value={provDiagenosis}
          style={{ width: '100%' }}
          disabled
        >
        </Select>
      </div>

      <div style={{ marginTop: 10 }}>
        <table className="table align-middle mb-0 bg-white" >
          <thead>
            <tr>
              <td>Authorization Code</td>
              <td>Requested Item</td>
              <td>Quantity</td>
              <td>Cost</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              return (<AuthorizationRequestDataListHook data={item} index={index} />)
            })}
          </tbody>
        </table>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', margin: '30px', justifyContent: 'flex-end', gap: '5em' }}>
        <Button onClick={send_claim} style={{ alignItems: 'center', alignContent: 'center' }} disabled={loading}>
          {
            (loading) ?
              <Spin size="small" tip={'Sending Claim Request'} /> :
              <span>Send <SendOutlined style={{ transform: 'rotate(-30deg)' }} /></span>
          }
        </Button>
      </div>
    </>
  )
}

export const AuthorizationRequestDataListHook = props => {
  const [docs, setDocs] = useState(Object.keys(fileses).length);

  const showModal = () => {
    document.querySelector('#doc').click();
  };

  const onChange = (event) => {
    const file = event.target.files[0]
    if (!file) return;
    fileses[`file_${Object.keys(fileses).length}`] = file;
    uploadData = { checkin_id: props.data.checkin_id, id_: props.data.id_ }
    setDocs(Object.keys(fileses).length);
  };


  return (
    <>
      <tr>
        <td className='fw-bold'>
          <Input className="fw-normal mb-1 fw-bold" value={props.data.auth_code} disabled />
        </td>
        <td className='fw-bold'>
          <Input className="fw-normal mb-1 fw-bold" value={`${props.data.category_name.split(' ')[0]} - ${props.data.item_name}`} disabled />
        </td>
        <td>
          <Input className="fw-normal mb-1 fw-bold" value={props.data.quantity} disabled />
        </td>
        <td>
          <Input className="fw-normal mb-1 fw-bold" value={props.data.approved_cost} disabled />
        </td>
        <td>
          {
            (parseInt(props.data.category_id) === 3) ?
              <>
                <Button type="success" onClick={showModal} value={props.data.id_}><DeleteColumnOutlined /> Document ({docs})</Button>
                <input type='file' style={{ display: "none" }} id='doc' name='doc' onChange={onChange} />
              </>
              : null
          }
        </td>
      </tr>
    </>
  )
}

export function DebounceSelect({ fetchOptions, debounceTimeout = 1000, ...props }) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      if (value.length < 3) return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export function DebounceSelect2({
  fetchOptions,
  loadingDiagnosis,
  aborter,
  generatorOption,
  optionHandler,
  debounceTimeout = 1000,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value) => {
      if (!value?.length) return;

      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      options && options.length ?
        setOptions((d) => [
          { label: <Spin size="small" />, value: "_" },
          ...d.filter((i) => i.value !== "_"),
        ]) : setOptions((d) => []);

      setFetching(true);

      try {
        let fetchPromise;

        if (generatorOption) {
          fetchPromise = fetchGeneratorOptions(value);
        } else {
          fetchPromise = fetchOptions(value);
        }

        const newOptions = await fetchPromise;
        if (fetchId !== fetchRef.current) {
          // For fetch callback order
          setFetching(false);
          return;
        }
        if (newOptions?.length) setOptions((d) => [...newOptions]);
      } catch (error) {
        console.warn("Error fetching options:", error);
      } finally {
        setFetching(false);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchGeneratorOptions = async (value) => {
    const generator = fetchOptions(value);
    const options = [];

    for await (const items of generator) {
      if (optionHandler && typeof optionHandler === "function") {
        const opts = optionHandler(items);
        options.push(
          ...opts.filter((o) => !options.find((op) => op.value === o.value))
        );
      } else {
        options.push(...items);
      }
    }

    return options;
  };

  return (
    <Select
      labelInValue
      showSearch
      filterOption={false}
      // filterOption={(input, option) =>
      //   option && option.label && option.label.toLowerCase().includes(input.toLowerCase())
      // }
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" tip="Loading ..." />
        ) : (
          <Empty
            description={<span>Empty</span>}
            style={{ padding: 50, width: "100%", height: 300 }}
          />
        )
      }
      options={options}
      {...props}
    />
  );
}

export const ClaimsListHook = props => {
  const user = JSON.parse(localStorage.getItem(config.key.user)) //eslint-disable-line
  const [claimList, setClaimsList] = useState({});
  const [claimListN, setClaimsListN] = useState({}); // eslint-disable-line
  const [filterdClaimList, setFilterdClaimsList] = useState({}); //eslint-disable-line
  const [filterKeyword, setFilterKeyword] = useState('');
  const [loading, setLoading] = useState(false); //eslint-disable-line
  const section = props.section; //eslint-disable-line
  const activeSegment = useRef(0);
  // const segmentValue = useRef(0);
  const interval = useRef();

  useEffect(() => {
    update_claims_list(activeSegment.current, true);

    interval.current = setInterval(() => {
      update_claims_list(activeSegment.current, false);
    }, 5000)

    return () => clearInterval(interval);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterClaimsList(filterKeyword);
  }, [filterKeyword]) //eslint-disable-line

  async function fetchClaimsList(segment, loader = true) {
    loader && setLoading(true)
    return myRequestObj(`aClaimsListMOC`).post({ segment })
      .then((response) => response)
      .then((body) => {
        if(body?.data?.error === 'error' || Number(body?.data?.error)) {
          return {}
        }else{
          return body?.data
        }
      }).then(data => {
        return data?.data ?? {};
      }).finally(() => setLoading(false));
  }


  const handleSegmentChange = (value, claimListN) => {
    activeSegment.current = value
    setClaimsList({})
    update_claims_list(activeSegment.current, true);
    setFilterKeyword('')
  }

  const filterClaimsList = (e) => {
    // const inputValue = e
    // if(inputValue){
    //   setFilterdClaimsList(claimList.filter((val) => {
    //     return (activeSegment)? 
    //       ((
    //         val.surname.toLowerCase().indexOf(inputValue) >= 0 || val.first_name.indexOf(inputValue) >= 0 || val.claims_id.indexOf(inputValue) >= 0 
    //         || val.middle_name.toLowerCase().indexOf(inputValue) >= 0 || val.insurance_no.indexOf(inputValue) >= 0 || val.checkin_id.toLowerCase().indexOf(inputValue) >= 0 
    //         || val.claims_date.indexOf(inputValue) >= 0 || val.checkin_date.indexOf(inputValue) >= 0 || val.checkinType.toLowerCase().indexOf(inputValue) >= 0
    //         ) && (Number(val.claims_status) === activeSegment - 1)
    //       ) : 
    //       (
    //         val.surname.toLowerCase().indexOf(inputValue) >= 0 || val.first_name.toLowerCase().indexOf(inputValue) >= 0 || val.claims_id.toLowerCase().indexOf(inputValue) >= 0 
    //         || val.middle_name.toLowerCase().indexOf(inputValue) >= 0 || val.insurance_no.indexOf(inputValue) >= 0 || val.checkin_id.indexOf(inputValue) >= 0 
    //         || val.claims_date.indexOf(inputValue) >= 0 || val.checkin_date.indexOf(inputValue) >= 0 || val.checkinType.indexOf(inputValue) >= 0
    //       );
    //   }))
    // }
  }

  const update_claims_list = (segment, loader = true) => {
    if (window.location.pathname === '/claims') {
      fetchClaimsList(segment, loader).then(res => {
        // const dat = NullSafe(res)?.sort((a, b) => Date.parse(b.claims_date) - Date.parse(a.claims_date))?.sort((a, b) => Date.parse(a.claims_date) - Date.parse(b.claims_date))
        setClaimsList(res);
        // sortClaims(dat)
      });
    };
  }

  const style = { padding: '8px 0', justifyContent: 'center', textAlign: 'center', fontSize: '1.2em', fontWeight: 'bold' };

  return (
    <>
      {
        props.extended && (
          <Segmented block
            options={[
              {
                label: (
                  <div style={{ ...style, color: '#121221', cursor: 'pointer' }}>Pending CO</div>
                ),
                value: 0
              },
              {
                label: (
                  <div style={{ ...style, color: '#ff9200', cursor: 'pointer' }}>Pending HM</div>
                ),
                value: 1
              },
              {
                label: (
                  <div style={{ ...style, color: '#afaa0b', cursor: 'pointer' }}>Pending CFO</div>
                ),
                value: 2
              },
              {
                label: (
                  <div style={{ ...style, color: '#00aa00', cursor: 'pointer' }}>Paid</div>
                ),
                value: 3
              }
            ]}
            onChange={e => handleSegmentChange(e, claimListN)}
            style={{ userSelect: 'none' }}
          />
        )
      }

      <div style={{ marginTop: 10 }}>
        {/* {
          props?.extended && (
            <Row justify="end" align="middle">
              <Col span={2}>
                <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>
                  Search:
                </span>
              </Col>
              <Col span={8}>
                <Input placeholder="Type Filter Word" value={filterKeyword?.toLowerCase()} onChange={e => setFilterKeyword(e.target.value.toLowerCase())} />
              </Col>
            </Row>
          )
        } */}
        {loading && <Loader  />}
        {
          !Object.keys(claimList)?.length && !loading?
            <>
              <Empty
                description={
                  <span>
                    There are no claims in this category
                  </span>
                }
                style={{ padding: 50, width: '100%' }}
              >
              </Empty>
            </> :
            <ClaimItemHandler claim={claimList} segment={activeSegment.current} extended={props.extended} />
        }
      </div>
    </>
  )
}

export const ClaimItemHandler = ({ claim, segment, extended }) => {
  const navigate = useNavigate();
  const keys = Object.keys(claim)
  const [selectedFacility, ] = useState(null)
  const [targetFacility, ] = useState(null)
  const [loading, ] = useState(false)
  const interval = useRef();

  const handleClick = (entry) => {
    navigate(`/claims/filings/${btoa(entry)}/${btoa(segment)}`, { state: {segment, entry} })
  }

  useEffect(() => {
    return clearInterval(interval.current);
  }, [])

  return (<>
    {keys.map((entry) => <>
      <Row id={'stop'} style={{
        padding: '10px 20px',
        margin: "10px 0",
        boxShadow: '2px 2px 5px rgba(0,0,0, .4)',
        cursor: 'pointer',
        width: '100%',
      }}
        gap={5}
        justify={'space-between'}
        onClick={() => handleClick(entry)}
      >
        <Col span={12}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <span>Facility Name</span>:
                </Col>
                <Col span={18}>
                  {claim[entry]?.facility?.name}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <span>Facility State / City</span>:
                </Col>
                <Col span={18}>
                  {claim[entry]?.facility?.state} / {claim[entry]?.facility?.city}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <span>Facility Email</span>:
                </Col>
                <Col span={18}>
                  {claim[entry]?.facility?.email}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <span>Facility Phone</span>:
                </Col>
                <Col span={18}>
                  {claim[entry]?.facility?.phone}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={11}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={10}>
                  <span># of Claims || PAR</span>:
                </Col>
                <Col span={14}>
                  {claim[entry][segment]?.claims_count} || {claim[entry][segment]?.pa_count}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={10}>
                  <span>Total Cost</span>:
                </Col>
                <Col span={14}>
                  <CurrencyFormat value={claim[entry][segment]?.encounter_cost} thousandSeparator prefix="₦" displayType="text" />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={10}>
                  <span>Number of Users Concerned</span>:
                </Col>
                <Col span={14}>
                  {/* {claim[entry]?.sort((a, b) => Date.parse(b.claima_date) - Date.parse(a.claims_date))[0]?.claims_id} */}
                  {claim[entry][segment]?.unique_insurance_count}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={10}>
                  <span>Date of Last Submission</span>:
                </Col>
                <Col span={14}>
                  {claim[entry][segment]?.last_claim_date}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {(selectedFacility && selectedFacility === entry) && (<div style={{ padding: '20px 20px', border: '5px dashed #ffcfca', }}>
        {loading && <Loader />}
        {targetFacility?.map((clm, index) =>
          <Row id={`stop-${entry}-${index}`}>
            <Col span={24}>
              {
                (clm.aggregatedItemsStatus) ?
                  <>
                    <Badge.Ribbon
                      text={
                        <>
                          <Badge count={clm.queries || null} offset={[10, -8]}>
                            <span style={{ color: 'white' }}>
                              {clm.flag.text}
                            </span>
                          </Badge>
                        </>
                      }
                      color={clm.flag.color} placement='end'
                    >
                      <Row justify="space-between" align="middle"
                        style={{
                          padding: '2px 10px',
                          boxShadow: '2px 2px 5px rgba(0,0,0, .4)',
                          cursor: 'pointer',
                          width: '100%'
                        }}
                        onClick={() => { navigate(`/claims-action?encounter=${clm.checkin_id}`) }}
                      >
                        <Col span={2}>
                          <Avatar size={64} icon={<img src={`${uri_img}${clm.pic_name}`} alt={clm.first_name} />} />
                        </Col>
                        {
                          extended && (
                            <Col span={4}>
                              <p>
                                {clm.claims_id}
                              </p>
                            </Col>
                          )
                        }
                        <Col span={4}>
                          <p>
                            {clm?.surname?.__capitalize()}, &nbsp;
                            {clm?.first_name?.__capitalize()} &nbsp;
                            {clm?.middle_name?.__capitalize()}
                          </p>
                        </Col>
                        {
                          extended && (
                            <Col span={3}>
                              <p>
                                {(new Date(clm?.claims_date)?.toLocaleString())}
                              </p>
                            </Col>
                          )
                        }
                        <Col span={1}>
                          <p>
                            {clm?.pa_items_count}
                          </p>
                        </Col>
                        <Col span={extended ? 4 : 8}>
                          <Row justify="space=between">
                            <Col span={12} style={{ color: `${clm?.aggregatedItemsStatus?.cat?.Pending?.color}` }}>{clm?.aggregatedItemsStatus?.cat?.Pending?.text}:</Col>
                            <Col>{clm?.aggregatedItemsStatus?.cat?.Pending?.count}</Col>
                          </Row>
                          <Row justify="space=between">
                            <Col span={12} style={{ color: `${clm?.aggregatedItemsStatus?.cat?.Queried?.color}` }}>{clm?.aggregatedItemsStatus?.cat?.Queried?.text}:</Col>
                            <Col>{clm?.aggregatedItemsStatus?.cat?.Queried?.count}</Col>
                          </Row>
                          <Row justify="space=between">
                            <Col span={12} style={{ color: `${clm?.aggregatedItemsStatus?.cat?.Approved?.color}` }}>{clm?.aggregatedItemsStatus?.cat?.Approved?.text}:</Col>
                            <Col>{clm?.aggregatedItemsStatus?.cat?.Approved?.count}</Col>
                          </Row>
                          <Row justify="space=between">
                            <Col span={12} style={{ color: `${clm?.aggregatedItemsStatus?.cat?.Declined?.color}` }}>{clm?.aggregatedItemsStatus?.cat?.Declined?.text}:</Col>
                            <Col>{clm?.aggregatedItemsStatus?.cat?.Declined?.count}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Badge.Ribbon>
                  </>
                  : null
              }
            </Col>
          </Row>
        )}
      </div>)}
    </>)}
  </>
  )
}

// Claims Hook for CFO and Payment Procedures
export const ClaimsListHookPayment = props => {
  const user = JSON.parse(localStorage.getItem(config.key.user))
  const [, setClaimsList] = useState([]);
  const [filterdClaimList, setFilterdClaimsList] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [, setSortedClaims] = useState([]);
  const [sortedPaidClaims, setSortedPaidClaims] = useState([]);
  const [sortedPendingClaims, setSortedPendingClaims] = useState([]);
  const activeSegment = useRef(0);

  useEffect(() => {
    fetchClaimsList(user.id).then(res => {
      setClaimsList(NullSafe(res.data));
      setSortedClaims(sortClaims(res).sorted);
      if (props.extended) {
        setFilterdClaimsList([...sortClaims(res).all_Claims.paid, ...sortClaims(res).all_Claims.pending]);
      } else {
        switch (props.section) {
          case 'all':
            setFilterdClaimsList([...sortClaims(res).all_Claims.paid, ...sortClaims(res).all_Claims.pending]);
            break;
          case 'paid':
            setFilterdClaimsList([...sortClaims(res).all_Claims.paid]);
            break;
          case 'pending':
            setFilterdClaimsList([...sortClaims(res).all_Claims.pending]);
            break;
          default: break;
        }
      }
      setSortedPaidClaims(sortClaims(res).all_Claims.paid)
      setSortedPendingClaims(sortClaims(res).all_Claims.pending)
      update_claims_list();
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Upload modal controls
  const [, setVisiblePaid] = useState(false);

  const sortClaims = (claims) => {
    const batches = Array.from(new Set(claims.data.map(c => c.payment_batch)));
    const pendingClaimSort = NullSafe(claims.data.filter(cl => Number(cl.claims_status) === 2))
    const paidClaimSort = NullSafe(claims.data.filter(cl => Number(cl.claims_status) === 3))

    let all_Claims = { paid: [], pending: [], queried: [], rejected: [] };

    const sorted = claims.facilities.map(fa => { return { ...fa, claims: pendingClaimSort } }).sort((a, b) => Date.parse(b.claims_date) - Date.parse(a.claims_date));
    const sortedPending = (pendingClaimSort.length) ? claims.facilities.map(fa => { return { ...fa, color: '#121221', status: 'Pending', claims: pendingClaimSort } }).sort((a, b) => Date.parse(b.claims_date) - Date.parse(a.claims_date)) : [];

    const sortedPaid = (paidClaimSort.length) ? claims.facilities.map(fa => { return { ...fa, status: 'Paid', color: '#00aa00', claims: paidClaimSort } }).sort((a, b) => Date.parse(b.claims_date) - Date.parse(a.claims_date)) : [];

    for (let b of batches) {
      let datum = { payment_batch: b };
      if (b) {
        for (let f of claims.facilities) {
          if (f) {
            if (paidClaimSort.length) {
              datum = { ...datum, ...f, status: 'Paid', color: '#00aa00', claims: paidClaimSort.filter(c => c.payment_batch === b && c.facility_id === f.id) }
              all_Claims.paid.push(datum);
            }
          }
        }
      } else {
        for (let f of claims.facilities) {
          if (f) {
            if (pendingClaimSort.length) {
              datum = { ...datum, ...f, status: 'Pending', color: '#121221', claims: pendingClaimSort.filter(c => c.facility_id === f.id) }
              all_Claims.pending.push(datum);
            }
          }
        }
      }
    }
    return { sorted, sortedPending, sortedPaid, all_Claims };
  }

  async function fetchClaimsList() {
    return myRequestObj("aClaimsListPayment").post()
      .then((body) => {
        return body?.data;
      })
  }

  const send_claim_paid = () => {
    fetchClaimsList(user.id).then(res => {
      setClaimsList(res);
      setSortedClaims(sortClaims(res).sorted);
      setFilterdClaimsList([...sortClaims(res).all_Claims.paid, ...sortClaims(res).all_Claims.pending]);
      setSortedPaidClaims(sortClaims(res).all_Claims.paid)
      setSortedPendingClaims(sortClaims(res).all_Claims.pending)
    });
    setVisiblePaid(false)
  }

  const handleSegmentChange = (value) => {
    activeSegment.current = value
    switch (value) {
      default:
        setFilterdClaimsList([...sortedPendingClaims, ...sortedPaidClaims]);
        break;
      case 1:
        setFilterdClaimsList(sortedPendingClaims);
        break
      case 2:
        setFilterdClaimsList(sortedPaidClaims);
        break
    }
    setFilterKeyword('')
  }

  const update_claims_list = () => {
    setInterval(() => {
      fetchClaimsList(user.id).then(res => {
        setClaimsList(res);
        setSortedClaims(sortClaims(res).sorted);
        if (activeSegment.current === 2) {
          setFilterdClaimsList([...sortClaims(res).all_Claims.paid]);
        }
        else if (activeSegment.current === 1) {
          setFilterdClaimsList([...sortClaims(res).all_Claims.pending]);
        } else {
          setFilterdClaimsList([...sortClaims(res).all_Claims.pending, ...sortClaims(res).all_Claims.paid]);
        }
        setSortedPaidClaims(sortClaims(res).all_Claims.paid)
        setSortedPendingClaims(sortClaims(res).all_Claims.pending)
      });
    }, 10000)
  }

  const style = { padding: '8px 0', justifyContent: 'center', textAlign: 'center', fontSize: '1.2em', fontWeight: 'bold' };

  return (
    <>
      {
        props.extended && (
          <Segmented block
            options={[
              {
                label: (
                  <div style={{ ...style, color: '#0092ff', cursor: 'pointer' }}>All</div>
                ),
                value: 0
              },
              {
                label: (
                  <div style={{ ...style, color: '#121221', cursor: 'pointer' }}>Pending</div>
                ),
                value: 1
              },
              {
                label: (
                  <div style={{ ...style, color: '#00aa00', cursor: 'pointer' }}>Paid</div>
                ),
                value: 2
              }
            ]}
            onChange={handleSegmentChange}
            style={{ userSelect: 'none' }}
          />
        )
      }
      <div style={{ marginTop: 10 }}>
        {
          props.extended && (
            <Row justify="end" align="middle">
              <Col span={2}>
                <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>
                  Search:
                </span>
              </Col>
              <Col span={8}>
                <Input placeholder="Type Filter Word" value={filterKeyword.toLowerCase()} onChange={e => setFilterKeyword(e.target.value.toLowerCase())} />
              </Col>
            </Row>
          )
        }
        {
          (!filterdClaimList.length) ?
            <>
              <Empty
                description={
                  <span>
                    There are no claims in this category
                  </span>
                }
                style={{ padding: 50, width: '100%' }}
              >
              </Empty>
            </> :
            filterdClaimList.map((claim, index) =>
              <PaymentBatchListing claim={claim} index={index} paymentHandle={() => send_claim_paid()} extended={props.extended} section={props.section} />
            )
        }
      </div>
    </>
  )
}

export const PaymentBatchListing = ({ claim, index, paymentHandle, extended }) => {
  const [claimsIdsOfTheBatch, setClaimsIdsOfTheBatch] = useState([]);
  const [dataWVew, setDataView] = useState(false);
  const navigate = useNavigate();

  // Upload modal controls
  const [visiblePaid, setVisiblePaid] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef(null);

  const handlePayButton = () => {
    setVisiblePaid(true)
    setClaimsIdsOfTheBatch(d => (claim.claims.map((c, i) => c.claims_id)))
  }

  const send_claim_paid = () => {
    paymentHandle();
    setVisiblePaid(false)
  }

  const handleCancel = () => {
    setVisiblePaid(false);
  };

  const handleView = () => {
    setDataView(!dataWVew);
    setClaimsIdsOfTheBatch(d => (claim.claims.map((c, i) => c.claims_id)))
  }

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  return (
    (claim['claims'].length) ?
      <Row style={{ padding: '5px 0' }}>
        <Col span={24}>
          <Badge.Ribbon text={claim.status} color={claim.color} placement='end'>
            <Row justify="start" align="middle" style={{ padding: '2px 10px', boxShadow: '2px 2px 5px rgba(0,0,0, .4)', cursor: 'pointer' }} onClick={() => handleView(index)}>
              <Col span={2}>
                <Avatar size={64} icon={<RiHospitalFill />} />
              </Col>
              <Col span={8}>
                <Row justify="space-between">
                  <Col span={12}>Facility Name:</Col>
                  <Col span={12}>{claim.name}</Col>
                </Row>
                <Row justify="space-between">
                  <Col span={12}>Facility City / State:</Col>
                  <Col span={12}>{claim.city} / {claim.state}</Col>
                </Row>
                {
                  extended &&
                  <Row justify="space-between">
                    <Col span={12}>Facility Phone:</Col>
                    <Col span={12}><a href={`tel:${claim.phone}`}>{claim.phone}</a></Col>
                  </Row>
                }
              </Col>
              <Col span={6}>
                <Row justify="start">
                  <Col span={12}>Claims:</Col>
                  <Col>{claim.claims.length}</Col>
                </Row>
                <Row justify="start">
                  <Col span={12}>Approved Cost:</Col>
                  <Col><CurrencyFormat value={claim.claims.reduce((prev, curr) => prev + Number(curr.encounter_cost), 0)} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></Col>
                </Row>
              </Col>
              {
                extended &&
                <Col span={4}>
                  <Row gutter={[6, 6]}>
                    <Col span={12}>
                      <Button type="primary" onClick={() => handleView(index)} style={{ width: '100%' }}>View</Button>
                    </Col>
                    {
                      (!claim.claims[0].payment_batch) ?
                        <Col span={12}>
                          <Button type="primary" danger onClick={() => handlePayButton(index)} disabled={claim?.status === 'Paid'}>Paid</Button>
                        </Col> : null
                    }
                    {
                      (claim.claims.length && parseInt(claim.claims[0].claims_status) === 3 && claim.claims[0].payment_batch) ?
                        <Col span={12}>
                          <Button type="link" href={`${uri}documents/claims/payments/claims/${claim.claims[0].payment_batch}.pdf`} target={'_blank'}>
                            Payment Document
                          </Button>
                        </Col> : null
                    }
                  </Row>
                </Col>
              }
            </Row>
            {
              (dataWVew) ?
                <div style={{ overflowY: 'auto', marginTop: 10, padding: 20 }}>
                  {claim.claims.map((claim) =>
                    <Row style={{ padding: '5px 0' }}>
                      <Col span={24}>
                        {
                          (claim.aggregatedItemsStatus) ?
                            <Row justify="space-between" align="middle" style={{ padding: '2px 10px', boxShadow: '2px 2px 5px rgba(0,0,0, .4)', cursor: 'pointer' }} onClick={() => { navigate(`/claims-action?encounter=${claim.checkin_id}`) }}>
                              <Col span={2}>
                                <Avatar size={64} icon={<img src={`${uri_img}${claim.pic_name}`} alt={claim.first_name} />} />
                              </Col>
                              <Col span={3}>
                                <p>
                                  {claim.claims_id.split('.')[1]}
                                </p>
                              </Col>
                              <Col span={4}>
                                <p>
                                  {Array.from(claim.surname).map((i, v) => i.toLocaleUpperCase()).join('').trim()}, &nbsp;
                                  {Array.from(claim.first_name).map((i, v) => (v === 0) ? i.toLocaleUpperCase() : i).join('')} &nbsp;
                                  {Array.from(claim.middle_name).map((i, v) => (v === 0) ? i.toLocaleUpperCase() : i).join('')}
                                </p>
                              </Col>
                              <Col span={3}>
                                <p>
                                  {(new Date(claim.claims_date).toLocaleString())}
                                </p>
                              </Col>
                              <Col span={1}>
                                <p>
                                  {claim.pa_items_count}
                                </p>
                              </Col>
                              <Col span={4}>
                                <p>
                                  {(new Date(claim.claims_date).toLocaleString())}
                                </p>
                              </Col>
                              <Col span={3}>
                                <Row justify="space=between">
                                  <Col span={12} style={{ color: `${claim.aggregatedItemsStatus.cat.Pending.color}` }}>{claim.aggregatedItemsStatus.cat.Pending.text}:</Col>
                                  <Col>{claim.aggregatedItemsStatus.cat.Pending.count}</Col>
                                </Row>
                                <Row justify="space=between">
                                  <Col span={12} style={{ color: `${claim.aggregatedItemsStatus.cat.Queried.color}` }}>{claim.aggregatedItemsStatus.cat.Queried.text}:</Col>
                                  <Col>{claim.aggregatedItemsStatus.cat.Queried.count}</Col>
                                </Row>
                                <Row justify="space=between">
                                  <Col span={12} style={{ color: `${claim.aggregatedItemsStatus.cat.Approved.color}` }}>{claim.aggregatedItemsStatus.cat.Approved.text}:</Col>
                                  <Col>{claim.aggregatedItemsStatus.cat.Approved.count}</Col>
                                </Row>
                                <Row justify="space=between">
                                  <Col span={12} style={{ color: `${claim.aggregatedItemsStatus.cat.Declined.color}` }}>{claim.aggregatedItemsStatus.cat.Declined.text}:</Col>
                                  <Col>{claim.aggregatedItemsStatus.cat.Declined.count}</Col>
                                </Row>
                              </Col>
                            </Row> : null
                        }
                      </Col>
                    </Row>
                  )}
                </div> : null
            }
          </Badge.Ribbon>
        </Col>
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => { }}
              onBlur={() => { }} // end
            >
              Payment Document Upload
            </div>
          }
          width='300px'
          visible={visiblePaid}
          onOk={send_claim_paid}
          onCancel={handleCancel}
          destroyOnClose
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          okText={'Done'}
        >
          <div className='form-group' style={{ width: 'fit-content', margin: '5px auto' }}>
            <Uploader claimItems={claimsIdsOfTheBatch} done={() => send_claim_paid()} />
          </div>
        </Modal>
      </Row>
      : null
  )
}

export const Uploader = ({ claimItems, done }) => {
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const beforeUpload = (file) => {

    setLoading(true);

    if (file.type !== 'application/pdf') {
      notification.error({ message: 'You can only upload a pdf file!', placement: 'topLeft' });
      setLoading(false);
      return;
    }

    const postData = new FormData();
    postData.append('items', JSON.stringify(claimItems));
    postData.append('file', file);
    myRequest('post', "saveBatchDoc", postData)
      .then(body => body?.data).then(body => {
        if (body.error) {
          setLoading(false);
          setUploaded(false);
        } else {
          notification.success({
            message: body.message,
            placement: 'topLeft'
          })
          done()
          setLoading(false);
          setUploaded(true);
        }
      })

    return false;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : uploaded ? <CheckOutlined color="green" /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      disabled={loading || uploaded}
    >
      {uploadButton}
    </Upload>
  );
};

export const AuthorizationRequestDataListHookPayment = props => {
  const [, setSending] = useState(false);
  const [, setVisibleQuery] = useState(false);
  const [visibleReject,] = useState(false);
  const [visibleApproval, setVisibleApproval] = useState(false);
  const [comment, setComment] = useState('');
  const [, setProcessed] = useState(false);
  const [, setProcessing] = useState(false);
  var claimData = { ...props.data };

  // Modal control start
  const [disabled, setDisabled] = useState(false);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  async function submitAction() {
    return myRequestObj(`claimItemApprove`).post(claimData)
      .then((response) => response?.data)
  }

  const handleOkReject = () => {
    setProcessing(true);
    claimData.claim_flag = 1;
    claimData.claim_comment = comment;
    submitAction();
    setSending(true);
    setVisibleApproval(false);
    setProcessing(false);
    setProcessed(true);
  }

  const handleOkApprove = e => {
    setProcessing(true);
    claimData.claim_flag = 1;
    claimData.claim_comment = comment;
    submitAction();
    setSending(true);
    setVisibleApproval(false);
    setProcessing(false);
    setProcessed(true);
  }

  const handleCancel = (e) => {
    setVisibleApproval(false);
    setVisibleQuery(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <tr>
        <td>
          <Input value={props.data.auth_code} title={props.data.auth_code} disabled />
        </td>
        <td>
          <Input value={`${props.data.category_name.split(' ')[0]} - ${props.data.item_name}`} title={`${props.data.category_name.split(' ')[0]} - ${props.data.item_name}`} disabled />
        </td>
        <td>
          <Input style={{ width: 100 }} value={props.data.quantity} title={props.data.quantity} disabled />
        </td>
        <td>
          <Input style={{ width: 100 }} value={props.data.cost} title={props.data.cost} disabled />
        </td>
        <td>
          <Input style={{ width: 100 }} value={props.data.approved_cost} title={props.data.approved_cost} disabled />
        </td>
        {/* <td>
          <span style={{display: 'flex', gap: '5px 5px'}}>
            <Button type="primary" disabled={(props.data.auth_code && (!processed && !processing) && (props.data.claim_flag != 1 ))? false: true} onClick={showApprovalComment}>
              Approve 
            </Button>
            <PAQuery data = {props.data} disabled={(props.data.auth_code && (!processed && !processing)  && (props.data.claim_flag != 1 && props.data.claim_flag < 2))? false: true} />
            <Button type="primary" danger disabled={(props.data.auth_code && (!processed && !processing)  && (props.data.claim_flag != 1 && props.data.claim_flag < 2))? false: true} onClick={showRejectComment}>
              Reject
            </Button>
          </span>
        </td> */}

        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => { }}
              onBlur={() => { }} // end
            >
              Approval Memo
            </div>
          }
          open={visibleApproval}
          onOk={handleOkApprove}
          onCancel={handleCancel}
          destroyOnClose
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          okText={'Approve'}
        >
          <div className='form-group' style={{ width: '100%', marginBottom: '5px' }}>
            <label className='form-label'>Comment</label>
            <TextArea rows={3} style={{ width: '100%' }} onChange={(e) => setComment(e.target.value)} />
          </div>
        </Modal>

        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => { }}
              onBlur={() => { }} // end
            >
              Rejection Memo
            </div>
          }
          visible={visibleReject}
          onOk={handleOkReject}
          onCancel={handleCancel}
          destroyOnClose
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          okText={'Reject'}
        >
          <div className='form-group' style={{ width: '100%', marginBottom: '5px' }}>
            <label className='form-label'>Comment</label>
            <TextArea rows={3} style={{ width: '100%' }} onChange={(e) => setComment(e.target.value)} />
          </div>
        </Modal>
      </tr>
    </>
  )
}

const ClaimsHook = (props) => {
  const extended = props.extended !== undefined ? props.extended : true;
  const section = props.section !== undefined ? props.section : 'all';

  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { set, user } = useAuth();
  const dispatcher = useDispatch(selectclient);

  useEffect(() => {
    if (user) {
      if (![1, 3, 4, 5].includes(Number(user?.role['staffRoleId']))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission',
          placement: 'topLeft'
        })
        navigate('/dashboard')
      }
    } else {
      navigate('/login')
    }
  })

  return (
    <>
      {
        (user) ?
          (params.get('checkin')) ?
            <ClaimsRequestHook extended={extended} section={section} /> :
            (![5].includes(parseInt(user?.role['staffRoleId']))) ?
              <ClaimsListHook extended={extended} section={section} /> : <ClaimsListHookPayment extended={extended} section={section} />
          : null
      }
    </>
  )
}

export default ClaimsHook;