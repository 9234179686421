// import { Button, Input, message, Select, Tabs, notification, Row, Col, Avatar, Dropdown, Menu, Modal, Empty, Segmented, Badge, Switch } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../assets/utils/useAuth';
import lib, { myRequest } from '../../assets/utils/lib';
import './clients.css'
import CurrencyFormat from 'react-currency-format';

import { Table, Modal, Form, Input, Button, Select, notification, Col, Row, Divider, Space, Card, InputNumber, Empty, Statistic } from 'antd';
import { PlusOutlined, MinusCircleOutlined, LoadingOutlined, CloseOutlined, Loading3QuartersOutlined, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { ProgressBar } from "react-loader-spinner";
import { keysHaveEmptyValues } from "../../assets/utils/Helpers";

const { TextArea } = Input;
const { Option } = Select;

function Plans() {
  const { user } = useAuth();
  const navigator = useNavigate();

  useEffect(() => {
    if (!user || ![1, 5].includes(Number(user.role.staffRoleId))) {
      notification.info({
        message: 'Invalid User/Permission',
        description: 'Please login with the right permission',
        placement: 'topLeft'
      })
      navigator('/login')
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ paddingTop: 5 }}>
      <Row justify={'space-between'} gutter={[24, 24]}>
        <Col span={24} sx={24} md={24} sm={24} lg={24}>
          <Row gutter={[12, 12]} justify={'center'}>
            <Col sx={24} sm={24} md={24}>
              <PlansSummary />
            </Col>
            <Col sx={24} sm={24} md={24}>
              <CreatePlan />
            </Col>
            <Col sx={24} sm={24} md={12}>
              <CreateCategory />
            </Col>
            <Col sx={24} sm={24} md={12} >
              <CreateItems />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export const PlansSummary = props => {
  const [summary, setSummary] = useState({})
  const [loading, setLoading] = useState(false)

  const getSummary = (first = false) => {
    if (first) setLoading(true);
    lib.plansSummary()
      .then((resp) => resp.data)
      .then((planTypes) => {
        if (planTypes && !planTypes?.error) setSummary(planTypes?.data)
      })
      .finally(() => { setLoading(false) })
  }

  useEffect(() => {
    let timerId;

    getSummary(true)
    timerId = setInterval(getSummary, 5000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  // useEffect(() => getSummary, [])

  return (
    <div >
      {
        !(loading) ?
          Object.keys(summary).length ? <>
            <Row gutter={[12, 12]} justify={'space-between'}>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Insurance Plans" value={summary?.plans} precision={0} />
                </Card>
              </Col>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Insurance Plan Categories" value={summary?.categories} precision={0} />
                </Card>
              </Col>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Insurance (Health) Benefits" value={summary?.items} precision={0} />
                </Card>
              </Col>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Organizations Count" value={summary?.organizations} precision={0} />
                </Card>
              </Col>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Policies Counts - Self Paid" value={summary?.selfPaidPolicies} precision={0} />
                </Card>
              </Col>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Policies Counts - Corporate" value={summary?.corporatePolicies} precision={0} />
                </Card>
              </Col>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Policies Count - Total" value={summary?.totalPolicies} precision={0} />
                </Card>
              </Col>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Total Enrolee Counts" value={summary?.enrolees} precision={0} />
                </Card>
              </Col>
              <Col span={6}>
                <Card bordered={true} hoverable>
                  <Statistic title="Total Policy Cost" value={summary?.totalPolicyCost} precision={0} prefix={'₦'} />
                </Card>
              </Col>
            </Row>
          </> : <Empty description={'Summary is not available'} image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
          <ProgressBar
            height="130"
            width="130"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{ position: 'absolute', top: '40%', left: '40%' }}
            wrapperClass="progress-bar-wrapper"
            borderColor='#14442E'
            barColor='#51250F'
            visible={true}
          />
      }
    </div>
  )
}

const CreatePlan = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [categoriesDesign, setCategoriesDesign] = useState([]);

  const [summaries, setSummaries] = useState([]);

  const planFields = ['plan_name', 'plan_description', 'plan_type'];

  const [visiblePlan, setVisiblePlan] = useState(false);
  const [visiblePlanDetails, setVisiblePlanDetails] = useState(false);
  const [visiblePlanEdit, setVisiblePlanEdit] = useState(false);
  const [visiblePlanDesign, setVisiblePlanDesign] = useState(false);

  const [visiblePlanSummary, setVisiblePlanSummary] = useState(false);

  const [PlanToEdit, setPlanToEdit] = useState({});
  const [PlanToDesign,] = useState({});
  const [PlanDetails,] = useState({});
  const [formCreatePlans, setFormCreatePlans] = useState({});

  const [PlanToSummary,] = useState({});

  const [planTypes, setPlanTypes] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingDetails,] = useState(false);
  const [loadingSummaries, setLoadingSummaries] = useState(false);

  const getPlanTypes = async () => {
    lib.getPlanTypes()
      .then((resp) => resp.data)
      .then((types) => types?.data?.map(planType => { return { value: planType.plans_types_id, label: planType.plans_types_name } }))
      .then(render => setPlanTypes((render)))
  }

  const getPlans = () => {
    lib.getPlans()
      .then((resp) => resp.data)
      .then((plans) => plans?.data)
      .then(render => setPlans(render))
  }

  const getCategoriesDesign = () => {
    myRequest('post', 'categoriesDesign', {})
      .then((resp) => resp.data)
      .then((planTypes) => planTypes?.data)
      .then(render => setCategoriesDesign(render))
  }

  const updatePlan = (data = false) => {
    console.log(data || PlanToEdit)
    setLoading(true)
    lib.updatePlan(data || PlanToEdit)
      .then((resp) => resp.data)
      .then((plan) => {
        if (plan?.status === 'error') {
          notification.error({ message: plan.msg, placement: 'topLeft' })
        } else if (Number(plan?.error) === 0) {
          notification.success({ message: plan.message, placement: 'topLeft' })
          setVisiblePlanEdit(false)
        } else {
          notification.error({ message: plan.message, placement: 'topLeft' })
          // getPlans();
        }
        // planTypes?.data
        console.log(plan)
        getPlans()
      })
      .finally(() => setLoading(false))
  }

  const getSummary = () => {
    setLoadingSummaries(true);
    myRequest('post', 'planSummary', PlanToSummary)
      .then((resp) => resp?.data)
      .then((detail) => setSummaries(detail?.data ?? []))
      .finally(() => setLoadingSummaries(false))
  }

  useEffect(() => {
    getPlans();
  }, [])

  useEffect(() => {
    if (visiblePlan) getPlanTypes();
    else setFormCreatePlans({});
  }, [visiblePlan])

  useEffect(() => {
    if (visiblePlanEdit) getPlanTypes();
    else setPlanToEdit({});
  }, [visiblePlanEdit])

  useEffect(() => {
    // console.log(formCreatePlans);
  }, [formCreatePlans])

  useEffect(() => {
    visiblePlanDesign && getCategoriesDesign();
    form.setFieldValue('plan', PlanToDesign.plan_id)
  }, [visiblePlanDesign]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    visiblePlanSummary && getSummary();
  }, [visiblePlanSummary]) //eslint-disable-line react-hooks/exhaustive-deps

  const createPlan = () => {
    if (keysHaveEmptyValues(formCreatePlans, planFields)) {
      notification.warning({ message: 'There are some empty fields, kindly fill then and try again.', placement: 'topLeft' })
      return;
    }
    setLoading(true)
    lib.createPlan(formCreatePlans)
      .then((resp) => resp.data)
      .then((planData) => {
        if (Number(planData?.error) === 0) {
          notification.success({ message: planData.message, placement: 'topLeft' })
          setVisiblePlan(false)
        } else {
          notification.error({ message: planData.message, placement: 'topLeft' })
        }
        console.log(planData)
        getPlans();

      }).finally(() => setLoading(false))
  }

  const [form] = Form.useForm();

  const [formSummary] = Form.useForm();

  const onFinish = (values) => {
    console.log('Received values of form:', values);
  };

  const handleChange = (e) => {
    form.setFieldsValue({
      categories: [],
    });
  };

  const handleItemSelection = e => {
    // console.log(e, categories.filter(item => item.plans_categories_id != e))
    // setItemsCopy(d => (d.filter(item => item.plans_items_id != e)))
    // console.log(form.getFieldsValue())
  }

  const handleDesignClosed = e => {
    setVisiblePlanDesign(false)
    form.setFieldsValue({
      categories: [],
    });
  }

  const handleDesignSubmit = e => {
    form.validateFields().then(values => {
      setVisiblePlanDesign(false)
      lib.designplan(form.getFieldsValue())
        .then(res => res.data)
        .then(des => {
          form.setFieldValue('categories', []);
        });
    })
      .catch(e => {
        notification.error({ message: 'Form Fields Error', placement: 'topLeft' })
      })
  }

  const handlePanSummaryClosed = e => {
    setVisiblePlanSummary(false)
    setSummaries([])
    formSummary.setFieldsValue({
      summaries: [],
    });
  }

  const handlePlanSummarySubmit = e => {
    formSummary.validateFields().then(values => {
      // console.log('value', {...values, ...PlanToSummary});
      if (!values['summaries']?.length) {
        setVisiblePlanSummary(false);
        setSummaries([])
        return;
      }
      setLoadingSummaries(true)
      myRequest('post', 'addPlanSummary', { ...values, ...PlanToSummary })
        .then(response => response.data)
        .then(res => {
          if (res?.error) {
            notification.error({ message: res?.message, placement: 'topLeft' })
          } else {
            notification.success({ message: res?.message, placement: 'topLeft' })
            formSummary.setFieldValue('summaries',)
          }
        })
        .finally(() => {
          getSummary()
          setLoadingSummaries(false)
        })
    })
      .catch(e => {
        notification.error({ message: 'Form Fields Error: Kindly remove empty fields', placement: 'topLeft' })
      })
  }

  const removeSummary = id => {
    setLoadingSummaries(true)
    myRequest('post', 'removePlanSummary', id)
      .then(response => response.data)
      .then(res => {
        if (res?.error) {
          notification.error({ message: res?.message, placement: 'topLeft' })
        } else {
          notification.success({ message: res?.message, placement: 'topLeft' })
        }
      })
      .finally(() => {
        getSummary()
        setLoadingSummaries(false)
      })
  }

  const handleRowClick = (record) => {
    navigate(`/plans/${btoa(record.plan_id)}`, { state: record })
  };

  const rowProps = (record) => {
    return {
      onClick: () => handleRowClick(record)
    };
  };

  const columnConf = [
    {
      title: 'Plan Name',
      key: 'plan_name',
      dataIndex: 'plan_name',
      width: '25%'
    },
    {
      title: 'Plan Unit Cost',
      key: 'plan_cost',
      dataIndex: 'plan_cost',
      width: '15%',
      render: (_) => <CurrencyFormat value={_} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
    },
    {
      title: 'Plan Family Cost',
      key: 'plan_six_cost',
      dataIndex: 'plan_six_cost',
      width: '15%',
      render: (_) => <CurrencyFormat value={_} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
    },
    // {
    //   title: 'In-Patient Limit',
    //   key: 'in_patient_limit',
    //   dataIndex: 'in_patient_limit',
    //   width: '15%'
    // },
    // {
    //   title: 'Out-Patient Limit',
    //   key: 'out_patient_limit',
    //   dataIndex: 'out_patient_limit',
    //   width: '15%'
    // },
    {
      title: 'Plan Description',
      key: 'plan_description',
      dataIndex: 'plan_description',
      width: '20%'
    },
    {
      title: 'Plan Type',
      key: 'plans_types_name',
      dataIndex: 'plans_types_name',
      width: 'auto'
    },
    {
      title: 'Status',
      key: 'plan_status',
      dataIndex: 'plan_status',
      render: (_) => parseInt(_) ? <CheckCircleFilled style={{ fontSize: 25 }} /> : <CloseCircleFilled style={{ color: "#ef4d0e", fontSize: 25 }} />,
      width: 'auto',
    }
  ]

  return <>
    <div style={{ position: 'relative' }}>
      <Table
        columns={columnConf}
        dataSource={plans}
        scroll={{
          y: 300,
        }}
        onRow={rowProps}
        rootClassName='row'
      />
      <Button
        title="Create A New Plan"
        style={{
          position: 'absolute', bottom: '20px', left: '20px', display: 'flex', placeItems: 'center'
        }}
        shape="round"
        type="primary"
        onClick={() => setVisiblePlan(true)}
        icon={<PlusOutlined />}
      >
        Create A New Plan
      </Button>
    </div>

    {/* Create New Plan */}
    <Modal
      open={visiblePlan}
      title="Create New Plans"
      onOk={null}
      onCancel={() => setVisiblePlan(false)}
      footer={[
        <Button type="primary" danger onClick={() => setVisiblePlan(false)} disabled={loading}>Close</Button>,
        <Button type="primary" onClick={createPlan} loading={loading} disabled={loading}>Create</Button>
      ]}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Name</Col>
            <Col span={16}>
              <Input type="text" autoFocus style={{ width: '100%' }} value={formCreatePlans?.plan_name} onChange={e => setFormCreatePlans(d => ({ ...d, plan_name: e.target.value }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Description</Col>
            <Col span={16}>
              <TextArea type="text" rows={4} style={{ width: '100%' }} value={formCreatePlans?.plan_description} onChange={e => setFormCreatePlans(d => ({ ...d, plan_description: e.target.value }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Type</Col>
            <Col span={16}>
              <Select style={{ width: '100%' }} value={formCreatePlans?.plan_type} onChange={e => setFormCreatePlans(d => ({ ...d, plan_type: e }))} options={planTypes} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Cost</Col>
            <Col span={16}>
              <InputNumber style={{ width: '100%' }} value={formCreatePlans?.plan_cost} onChange={e => setFormCreatePlans(d => ({ ...d, plan_cost: e }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Cost - Family</Col>
            <Col span={16}>
              <InputNumber style={{ width: '100%' }} value={formCreatePlans?.plan_six_cost} onChange={e => setFormCreatePlans(d => ({ ...d, plan_six_cost: e }))} />
            </Col>
          </Row>
        </Col>
        {/* <Col span={24}>
          <Row>
            <Col span={8}>In-Patient Limit</Col>
            <Col span={16}>
              <InputNumber style={{ width: '100%' }} value={formCreatePlans?.in_patient_limit} onChange={e => setFormCreatePlans(d => ({ ...d, in_patient_limit: e }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Out-Patient Limit</Col>
            <Col span={16}>
              <InputNumber style={{ width: '100%' }} value={formCreatePlans?.out_patient_limit} onChange={e => setFormCreatePlans(d => ({ ...d, out_patient_limit: e }))} />
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Modal>

    {/* Designing Modal */}
    <Modal
      open={visiblePlanDesign}
      title="Plan Design"
      onOk={null}
      onCancel={handleDesignClosed}
      footer={[
        <Button type="primary" danger onClick={handleDesignClosed} >Close</Button>,
        <Button type="primary" onClick={handleDesignSubmit} >Save</Button>
      ]}
      style={{ maxHeight: 900 }}
      width={800}
    >
      <Form
        form={form}
        name="dynamic_form_complex"
        onFinish={onFinish}
        autoComplete="off"
      // onValuesChange={(changed, values) => console.log(changed, values)}
      >
        <Form.Item
          name="plan"
          label="Plan"
          rules={[
            {
              required: true,
              message: 'Missing area',
            },
          ]}
        >
          <Select onChange={handleChange} disabled={PlanToDesign?.plan_id}>
            {plans?.map(plan => <Option value={plan.plan_id}>{plan.plan_name}</Option>)}
          </Select>
        </Form.Item>
        <Form.List name="categories">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row key={field.key} style={{ width: '100%' }}>
                  <Col span={18}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                      }
                      style={{
                        width: '70%',
                      }}
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          label="Category"
                          name={[field.name, 'category']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing Category',
                            },
                          ]}
                          style={{
                            width: '100%',
                          }}
                        >
                          <Select
                            disabled={!form.getFieldValue('plan')}
                            onChange={handleItemSelection}
                            style={{
                              width: '100%',
                            }}
                          >
                            {categoriesDesign.map((item) => (
                              <Option key={item.plans_categories_name} value={item.plans_categories_id} disabled={form.getFieldsValue()?.categories?.find(itm => itm?.category === item.plans_categories_id)}>
                                {item.plans_categories_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Space align="baseline" style={{ width: '100%' }}>
                      <Form.Item
                        {...field}
                        label="Price"
                        name={[field.name, 'price']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing price',
                          },
                        ]}
                        style={{
                          width: '100%',
                        }}
                      >
                        <InputNumber />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={!form.getFieldValue('plan')}>
                  Add Category
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>

    {/* Editing Modal */}
    <Modal
      open={visiblePlanEdit}
      title="Edit Plan"
      onOk={null}
      onCancel={() => setVisiblePlanEdit(false)}
      footer={[
        <Button type="primary" danger onClick={() => setVisiblePlanEdit(false)} disabled={loading}>Close</Button>,
        <Button type="primary" onClick={() => updatePlan()} loading={loading} disabled={loading}>Save</Button>
      ]}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Name</Col>
            <Col span={16}>
              <Input type="text" style={{ width: '100%' }} value={PlanToEdit.plan_name} onChange={e => setPlanToEdit(d => ({ ...d, plan_name: e.target.value }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Description</Col>
            <Col span={16}>
              <TextArea type="text" style={{ width: '100%' }} value={PlanToEdit.plan_description} onChange={e => setPlanToEdit(d => ({ ...d, plan_description: e.target.value }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Type</Col>
            <Col span={16}>
              <Select style={{ width: '100%' }} value={PlanToEdit?.plan_type} onChange={e => setPlanToEdit(d => ({ ...d, plan_type: e }))} options={planTypes} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Cost</Col>
            <Col span={16}>
              <InputNumber style={{ width: '100%' }} value={PlanToEdit?.plan_cost} onChange={e => setPlanToEdit(d => ({ ...d, plan_cost: e }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Plan Cost - Family</Col>
            <Col span={16}>
              <InputNumber style={{ width: '100%' }} value={PlanToEdit?.plan_six_cost} onChange={e => setPlanToEdit(d => ({ ...d, plan_six_cost: e }))} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>

    {/* Details Modal */}
    <Modal
      title={<><strong>{PlanDetails?.plan_name}</strong> - Detail</>}
      centered
      open={visiblePlanDetails}
      onCancel={() => setVisiblePlanDetails(false)}
      footer={[<Button onClick={() => setVisiblePlanDetails(false)}>Ok</Button>]}
      closeIcon={loadingDetails ? <LoadingOutlined /> : <CloseOutlined />}
      width={1000}
      destroyOnClose
    >
      <Row gap={[12, 24]} justify={'center'}>
        <Col span={24}>
          <Row gap={[8, 18]} >
            <Col span={6}><strong>Plan Name:</strong></Col>
            <Col span={18}>{PlanDetails?.plan_name}</Col>
            <Col span={6}><strong>Plan Description:</strong></Col>
            <Col span={18}>{PlanDetails?.plan_description}</Col>
            <Col span={6}><strong>Plan Cost:</strong></Col>
            <Col span={18}>{PlanDetails?.plan_cost} Naira</Col>
          </Row>
        </Col>
        <Divider style={{ padding: 0, margin: '10px 0', fontWeight: 800 }} orientation="left" orientationMargin={0}>
          Plan Categories
        </Divider>
        <Col span={24}>
          <ProgressBar
            height="150"
            width="150"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{ position: 'relative', top: '10%', left: '40%' }}
            wrapperClass="progress-bar-wrapper"
            borderColor='#14442E'
            barColor='#51250F'
            visible={loadingDetails}
          />
          {
            (!loadingDetails) ?
              (PlanDetails?.categories?.length) ? PlanDetails?.categories?.map(category => <Row gap={[12, 12]} style={{ paddingLeft: 20 }} key={category?.plans_categories_items_item_id}>
                <Col span={6}><strong>Category Name:</strong></Col>
                <Col span={18}><em>{category?.plans_categories_name}</em></Col>
                <Col span={6}><strong>Category Description:</strong></Col>
                <Col span={18}><em>{category?.plans_categories_description}</em></Col>
                <Col span={6}><strong>Category Limit:</strong></Col>
                <Col span={18}>
                  <em><CurrencyFormat value={category?.plan_plan_category_cost} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></em>
                </Col>
                <Col span={6}><strong>Category Status:</strong></Col>
                <Col span={18}><em>{parseInt(category?.plans_categories_status) ? 'Active' : 'Disabled'}</em></Col>
                <Divider style={{ padding: 0, margin: '10px 0', marginLeft: 20, fontWeight: 800 }} orientation="left" orientationMargin={0}>
                  Category Items
                </Divider>
                <Col span={24}>
                  {
                    category?.items?.length ? category?.items?.map(item => <Row key={item.plans_items_id} style={{ paddingLeft: 20 }}>
                      <Col span={6}><strong>Item Name:</strong></Col>
                      <Col span={18}><em>{item?.plans_items_name}</em></Col>
                      <Col span={6}><strong>Item Description:</strong></Col>
                      <Col span={18}><em>{item?.plans_items_description}</em></Col>
                      <Col span={6}><strong>Item Limit:</strong></Col>
                      <Col span={18}>
                        <em><CurrencyFormat value={item?.plans_categories_items_cost} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></em>
                      </Col>
                      <Col span={6}><strong>Item Status:</strong></Col>
                      <Col span={18}><em>{parseInt(item?.plans_items_status) ? 'Active' : 'Disabled'}</em></Col>
                      <Col span={24}>&nbsp;</Col>
                    </Row>) : <Empty description={'there are no items for this plan category'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </Col>
                <Col span={24}>&nbsp;</Col>
              </Row>) :
                <Empty description={'not designed yet...'} image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null
          }
        </Col>
      </Row>
    </Modal>

    {/* Perks Modal */}
    <Modal
      open={visiblePlanSummary}
      title={"Plan Perks - " + PlanToSummary?.plan_name}
      onOk={null}
      onCancel={handlePanSummaryClosed}
      footer={[
        <Button type="primary" danger onClick={handlePanSummaryClosed} disabled={loadingSummaries}>Close</Button>,
        <Button type="primary" onClick={handlePlanSummarySubmit} disabled={loadingSummaries} loading={loadingSummaries}>Save</Button>
      ]}
      style={{ maxHeight: 900 }}
      width={800}
    >
      <Form
        form={formSummary}
        name="dynamic_form_complex_2"
        autoComplete="off"
      // onValuesChange={(changed, values) => console.log(changed, values)}
      >
        {
          loadingSummaries && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Loading3QuartersOutlined spin style={{ fontSize: 50 }} />
          </div>
        }
        {summaries?.map((su, index) => (
          <Row key={index} style={{ width: '100%' }} gutter={[12, 12]}>
            <Col span={21}>
              <strong>
                {su?.plan_summary_text}
              </strong>
            </Col>
            <Col span={3}>
              <Space align="baseline" style={{ width: '100%' }}>
                <Button type="dashed" danger icone={<MinusCircleOutlined />} onClick={() => removeSummary({ id: su?.plan_summary_id })} disabled={loadingSummaries}>Remove</Button>
              </Space>
            </Col>
          </Row>
        ))}
        <Divider />
        <Form.List name="summaries">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} style={{ width: '100%' }} gutter={[12, 4]}>
                  <Col span={23}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                      }
                      style={{
                        width: '70%',
                      }}
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          // label={`Summary ${++index}`}
                          name={[field.name, 'item']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing Value',
                            },
                          ]}
                          style={{
                            width: '100%',
                          }}
                        >
                          <Input disabled={loadingSummaries} />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Space align="baseline" style={{ width: '100%' }}>
                      <MinusCircleOutlined onClick={() => remove(field.name)} disabled={loadingSummaries} />
                    </Space>
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={loadingSummaries}>
                  Add Summary
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  </>
}

const CreateCategory = () => {
  const [categories, setCategories] = useState([]);
  const [itemsDesign, setItemsDesign] = useState([]);

  const categoryFields = ['plans_categories_name', 'plans_categories_description'];

  const [visibleCategory, setVisibleCategory] = useState(false);
  const [visibleCategoryEdit, setVisibleCategoryEdit] = useState(false);
  const [visibleCategoryDesign, setVisibleCategoryDesign] = useState(false);

  const [CategoryToDesign,] = useState({});
  const [CategoryToEdit, setCategoryToEdit] = useState({});
  const [CategoryToEditCopy, setCategoryToEditCopy] = useState({});
  const [formCreateCategory, setFormCreateCategory] = useState({});

  const [loading, setLoading] = useState(false);

  const [visibleCategoryDetails, setVisibleCategoryDetails] = useState(false);
  const [CategoryDetails,] = useState({});
  const [loadingDetails,] = useState(false);


  const getCategories = () => {
    lib.getCategories()
      .then((resp) => resp.data)
      .then((planTypes) => planTypes?.data)
      .then(render => setCategories(render))
  }

  const getItemsDesign = () => {
    setLoading(true);
    myRequest('post', 'itemsDesign', {})
      .then((resp) => resp.data)
      .then((planTypes) => planTypes?.data)
      .then(render => {
        setItemsDesign(render)
      })
      .finally(() => setLoading(false))
  }

  const update = (data = false) => {
    if (!data) {
      notification.warning({ message: 'No Data' });
      return;
    } else {
      let changed = false;
      for (let key of Object.keys(data)) {
        if (data[key] !== CategoryToEditCopy[key]) {
          changed = true;
        }
      }
      if (!changed) {
        notification.warning({ message: 'No Changes was Made' });
        return;
      }
    }

    setLoading(true)
    lib.updateCategory(data || CategoryToEdit)
      .then((resp) => resp.data)
      .then((plan) => {
        if (Number(plan?.error) === 0) {
          notification.success({ message: plan.message, placement: 'topLeft' })
          setVisibleCategoryEdit(false)
        } else {
          notification.error({ message: plan.message, placement: 'topLeft' })
        }
        getCategories()
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getCategories();
  }, [])

  useEffect(() => {
    // console.log(CategoryToDesign)
    form.setFieldValue('category', CategoryToDesign.plans_categories_id)
    getItemsDesign()
  }, [CategoryToDesign]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (visibleCategoryEdit);
    else setCategoryToEdit({});
  }, [visibleCategoryEdit])

  useEffect(() => {
    // console.log(formCreateCategory);
  }, [formCreateCategory])

  const updateCategoryStaus = (item, value) => {
    setLoading(true);
    const data = { plans_categories_status: value, plans_categories_id: item.plans_categories_id };
    update(data);
  }

  const CreateCategory = () => {
    if (keysHaveEmptyValues(formCreateCategory, categoryFields)) {
      notification.warning({ message: 'There are some empty fields, kindly fill then and try again.', placement: 'topLeft' })
      return;
    }
    setLoading(true)
    lib.createCategory(formCreateCategory)
      .then((resp) => resp.data)
      .then((planData) => {
        if (Number(planData?.error) === 0) {
          notification.success({ message: planData.message, placement: 'topLeft' })
          setVisibleCategory(false)
        } else {
          notification.error({ message: planData.message, placement: 'topLeft' })
        }
        // console.log(planData)
        getCategories();

      }).finally(() => setLoading(false))

  }

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Received values of form:', values);
  };

  const handleChange = (e) => {
    // console.log(e)
    form.setFieldsValue({
      category_items: [],
    });
  };

  const handleItemSelection = e => {
    // console.log(e, items.filter(item => item.plans_items_id != e))
    // setItemsCopy(d => (d.filter(item => item.plans_items_id != e)))
    // console.log(form.getFieldsValue())
  }

  const handleDesignClosed = e => {
    setVisibleCategoryDesign(false)
    form.setFieldsValue({
      items: [],
    });
  }

  const handleDesignSubmit = e => {
    form.validateFields().then(values => {
      setLoading(true)
      setVisibleCategoryDesign(false)
      lib.designCategory(form.getFieldsValue())
        .then(res => res.data)
        .then(des => {
          // console.log(des)
          if (des.error) {
            notification.error({ message: des.message, placement: 'topLeft' })
          } else {
            notification.success({ message: des.message, placement: 'topLeft' })
          }
          form.setFieldValue('items', []);
        });
    })
      .catch(e => {
        notification.error({ message: 'Form Fields Error', placement: 'topLeft' })
      })
      .finally(() => setLoading(false));
  }

  const columnConf = [
    { title: 'Categories Name', dataIndex: 'plans_categories_name', key: 'plans_categories_name', width: '30%' },
    { title: 'Categories Description', dataIndex: 'plans_categories_description', key: 'plans_categories_description', width: '55%' },
    {
      title: 'Status', dataIndex: 'plans_categories_status', key: 'plans_categories_status', render: (_, record) =>
        <>
          {parseInt(_) ? <CheckCircleFilled style={{ fontSize: 25 }} /> : <CloseCircleFilled style={{ color: "#ef4d0e", fontSize: 25 }} />}
          {/* { parseInt(_)? <CheckCircleTwoTone/> : <CloseCircleTwoTone/> } */}
        </>, width: 'auto'
    }
  ];

  const handleRowClick = (record) => {
    setCategoryToEdit(record)
    setCategoryToEditCopy(record)
    setVisibleCategoryEdit(true)
  };

  const rowProps = (record) => {
    return {
      onClick: () => handleRowClick(record)
    };
  };

  return <>
    <div>
      <Table
        columns={columnConf}
        dataSource={categories}
        scroll={{
          y: 300,
        }}
        onRow={rowProps}
        rootClassName='row'
      />
      <Button
        title="Create A New Plan Category"
        style={{
          position: 'absolute', bottom: '20px', left: '20px', display: 'flex', placeItems: 'center'
        }}
        shape="round"
        type="primary"
        onClick={() => setVisibleCategory(true)}
        icon={<PlusOutlined />}
      >
        Create A New Plan Category
      </Button>
    </div>
    {/* Create Category */}
    <Modal
      open={visibleCategory}
      title="Create New Category"
      onOk={null}
      onCancel={() => setVisibleCategory(false)}
      footer={[
        <Button type="primary" danger onClick={() => setVisibleCategory(false)} disabled={loading}>Close</Button>,
        <Button type="primary" onClick={CreateCategory} loading={loading} disabled={loading}>Create</Button>
      ]}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row>
            <Col span={8}>Name</Col>
            <Col span={16}>
              <Input type="text" style={{ width: '100%' }} value={formCreateCategory.plans_categories_name} onChange={e => setFormCreateCategory(d => ({ ...d, plans_categories_name: e.target.value }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Description</Col>
            <Col span={16}>
              <TextArea style={{ width: '100%' }} value={formCreateCategory.plans_categories_description} onChange={e => setFormCreateCategory(d => ({ ...d, plans_categories_description: e.target.value }))} rows={3} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>

    {/* Design Category */}
    <Modal
      open={visibleCategoryDesign}
      title="Design Category"
      onOk={null}
      onCancel={handleDesignClosed}
      footer={[
        <Button type="primary" danger onClick={handleDesignClosed} >Close</Button>,
        <Button type="primary" onClick={handleDesignSubmit} >Save</Button>
      ]}
      style={{ maxHeight: 900 }}
      width={800}
    // destroyOnClose
    >

      <Form
        form={form}
        name="dynamic_form_complex"
        onFinish={onFinish}
        autoComplete="off"
        // onValuesChange={(changed, values) => console.log(changed, values)}
        disabled={loading}
      >
        <Form.Item
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: 'Missing area',
            },
          ]}
        >
          <Select onChange={handleChange} disabled={CategoryToDesign?.plans_categories_id}>
            {categories?.map(cat => <Option value={cat.plans_categories_id}>{cat.plans_categories_name}</Option>)}
          </Select>
        </Form.Item>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} style={{ width: '100%' }}>
                  <Col span={18}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                      }
                      style={{
                        width: '70%',
                      }}
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          label="Item"
                          name={[field.name, 'item']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing Item',
                            },
                          ]}
                          style={{
                            width: '100%',
                          }}
                        >
                          <Select
                            disabled={!form.getFieldValue('category') || loading}
                            onChange={handleItemSelection}
                            style={{
                              width: '100%',
                            }}
                          >
                            {itemsDesign.map((item) => (
                              <Option key={item.plans_items_name} value={item.plans_items_id} disabled={form.getFieldsValue()?.items?.find(itm => itm?.item === item.plans_items_id)}>
                                {item.plans_items_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Space align="baseline" style={{ width: '100%' }}>
                      <Form.Item
                        {...field}
                        label="Price"
                        name={[field.name, 'price']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing price',
                          },
                        ]}
                        style={{
                          width: '100%',
                        }}
                      >
                        <InputNumber disabled={loading} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} disabled={loading} />
                    </Space>
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={!form.getFieldValue('category') || loading}>
                  Add Item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>

    {/* Edit Category */}
    <Modal
      open={visibleCategoryEdit}
      title="Edit Category"
      onOk={null}
      onCancel={() => setVisibleCategoryEdit(false)}
      footer={[
        <Button type="primary" danger onClick={() => setVisibleCategoryEdit(false)} disabled={loading}>Close</Button>,
        <Button disabled={loading} type="primary" onClick={() => updateCategoryStaus(CategoryToEdit, parseInt(CategoryToEdit?.plans_categories_status) ? 0 : 1)} >{parseInt(CategoryToEdit?.plans_categories_status) ? 'Disable' : 'Enable'}</Button>,
        <Button type="primary" onClick={() => update(CategoryToEdit)} loading={loading} disabled={loading}>Save</Button>
      ]}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row>
            <Col span={8}>Name</Col>
            <Col span={16}>
              <TextArea disabled={loading} type="text" style={{ width: '100%' }} rows={3} value={CategoryToEdit.plans_categories_name} onChange={e => setCategoryToEdit(d => ({ ...d, plans_categories_name: e.target.value }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Description</Col>
            <Col span={16}>
              <TextArea disabled={loading} type="text" style={{ width: '100%' }} rows={5} value={CategoryToEdit.plans_categories_description} onChange={e => setCategoryToEdit(d => ({ ...d, plans_categories_description: e.target.value }))} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>

    {/* Detail Category */}
    <Modal
      title={<><strong>{CategoryDetails?.plans_categories_name}</strong> - Detail</>}
      centered
      open={visibleCategoryDetails}
      onOk={() => setVisibleCategoryDetails(false)}
      onCancel={() => setVisibleCategoryDetails(false)}
      closeIcon={loadingDetails ? <LoadingOutlined /> : <CloseOutlined />}
      width={800}
    >
      <Row gap={[12, 24]} justify={'center'}>
        <Col span={24}>
          <Row gap={[8, 18]} >
            <Col span={6}><strong>Catgory Name:</strong></Col>
            <Col span={18}>{CategoryDetails?.plans_categories_name}</Col>
            <Col span={6}><strong>Catgory Description:</strong></Col>
            <Col span={18}>{CategoryDetails?.plans_categories_description}</Col>
          </Row>
        </Col>
        <Divider style={{ padding: 0, margin: '10px 0' }} />
        <Col span={24}>
          <ProgressBar
            height="150"
            width="150"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{ position: 'relative', top: '10%', left: '40%' }}
            wrapperClass="progress-bar-wrapper"
            borderColor='#14442E'
            barColor='#51250F'
            visible={loadingDetails}
          />
          {
            !loadingDetails && visibleCategoryDetails && CategoryDetails?.items.map(item => <Row gap={[12, 12]} key={item?.plans_categories_items_item_id}>
              <Col span={6}><strong>Benefit Name:</strong></Col>
              <Col span={18}>{item?.plans_items_name}</Col>
              <Col span={6}><strong>Benefit Description:</strong></Col>
              <Col span={18}>{item?.plans_items_description}</Col>
              <Col span={6}><strong>Benefit Cost:</strong></Col>
              <Col span={18}>
                <em><CurrencyFormat value={item?.plans_categories_items_cost} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></em>
              </Col>
              <Col span={6}><strong>Benefit Status:</strong></Col>
              <Col span={18}>{parseInt(item?.plans_items_status) ? 'Active' : 'Disabled'}</Col>
              <Col span={24}>&nbsp;</Col>
            </Row>
            )
          }
        </Col>
      </Row>
    </Modal>
  </>
}

const CreateItems = () => {
  const [p_items, setItems] = useState([]);

  const itemsFields = ['plans_items_name', 'plans_items_description'];

  const [visibleItems, setVisibleItems] = useState(false);
  const [visibleItemsEdit, setVisibleItemsEdit] = useState(false);
  const [ItemsToEdit, setItemsToEdit] = useState({});
  const [ItemsToEditCopy, setItemsToEditCopy] = useState({});
  const [formCreateItems, setFormCreateItems] = useState({});

  const [loading, setLoading] = useState(false);

  const getItems = () => {
    lib.getItems()
      .then((resp) => resp.data)
      .then((planTypes) => planTypes?.data)
      .then(render => setItems(render))
  }

  const update = (data = false) => {
    if (!data) {
      return;
    } else {
      let changed = false;
      for (let key of Object.keys(data)) {
        if (data[key] !== ItemsToEditCopy[key]) {
          changed = true;
        }
      }
      if (!changed) {
        notification.warning({ message: 'No Changes was made' });
        return;
      }
    }

    setLoading(true)
    lib.updateItem(data || ItemsToEdit)
      .then((resp) => resp.data)
      .then((plan) => {
        if (Number(plan?.error) === 0) {
          notification.success({ message: plan.message, placement: 'topLeft' })
          setVisibleItemsEdit(false)
        } else {
          notification.error({ message: plan.message, placement: 'topLeft' })
        }
        getItems()
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getItems();
  }, [])

  // useEffect(() => {
  //   if(visiblePlan) getPlanTypes();
  //   else setFormCreatePlans({});
  // }, [visiblePlan])

  useEffect(() => {
    if (visibleItemsEdit);
    else setItemsToEdit({});
  }, [visibleItemsEdit])

  useEffect(() => {
    console.log(formCreateItems);
  }, [formCreateItems])

  const updateItemStaus = (item, value) => {
    setLoading(true);
    const data = { plans_items_status: value, plans_items_id: item.plans_items_id }
    update(data);
  }

  const createItem = () => {
    if (keysHaveEmptyValues(formCreateItems, itemsFields)) {
      notification.warning({ message: 'There are some empty fields, kindly fill then and try again.', placement: 'topLeft' })
      return;
    }
    setLoading(true)
    lib.createItem(formCreateItems)
      .then((resp) => resp.data)
      .then((planData) => {
        if (Number(planData?.error) === 0) {
          notification.success({ message: planData.message, placement: 'topLeft' })
          setFormCreateItems([])
        } else {
          notification.error({ message: planData.message, placement: 'topLeft' })
        }
        console.log(planData)
        getItems();
        setVisibleItems(false)

      }).finally(() => setLoading(false))

  }

  const handleRowClick = (record) => {
    setItemsToEdit(record)
    setItemsToEditCopy(record)
    setVisibleItemsEdit(true)
  };

  const rowProps = (record) => {
    return {
      onClick: () => handleRowClick(record)
    };
  };

  const columnConf = [
    { title: 'Items Name', dataIndex: 'plans_items_name', key: 'plans_items_name', width: '30%' },
    { title: 'Items Description', dataIndex: 'plans_items_description', key: 'plans_items_description', width: '55%' },
    {
      title: 'Status', dataIndex: 'plans_items_status', key: 'plans_items_status', render: (_, record) =>
        <>
          {parseInt(_) ? <CheckCircleFilled style={{ fontSize: 25 }} /> : <CloseCircleFilled style={{ color: "#ef4d0e", fontSize: 25 }} />}
        </>, width: 'auto'
    },
  ]

  return <>
    <div>
      <Table
        columns={columnConf}
        dataSource={p_items}
        scroll={{
          y: 300,
        }}
        onRow={rowProps}
        rootClassName='row'
      />
      <Button
        title="Create A New Plan Item"
        style={{
          // borderRadius: '50%',
          // width: '50px',
          // height: '50px',
          position: 'absolute', bottom: '20px', left: '20px', display: 'flex', placeItems: 'center'
        }}
        shape="round"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setVisibleItems(true)}
      >
        Create A New Plan Item
      </Button>
    </div>

    {/* Create Item */}
    <Modal
      open={visibleItems}
      title="Create New Item"
      onOk={null}
      onCancel={() => setVisibleItems(false)}
      footer={[
        <Button type="primary" danger onClick={() => setVisibleItems(false)} disabled={loading}>Close</Button>,
        <Button type="primary" onClick={() => createItem()} loading={loading} disabled={loading}>Ok</Button>
      ]}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row>
            <Col span={8}>Name</Col>
            <Col span={16}>
              <Input type="text" style={{ width: '100%' }} value={formCreateItems.plans_items_name} onChange={e => setFormCreateItems(d => ({ ...d, plans_items_name: e.target.value }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Description</Col>
            <Col span={16}>
              <TextArea rows={3} type="text" style={{ width: '100%' }} value={formCreateItems.plans_items_description} onChange={e => setFormCreateItems(d => ({ ...d, plans_items_description: e.target.value }))} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>

    <Modal
      open={visibleItemsEdit}
      title="Edit Item"
      onOk={null}
      onCancel={() => setVisibleItemsEdit(false)}
      footer={[
        <Button disabled={loading} type="primary" danger onClick={() => setVisibleItemsEdit(false)} >Close</Button>,
        <Button disabled={loading} type="primary" onClick={() => updateItemStaus(ItemsToEdit, parseInt(ItemsToEdit?.plans_items_status) ? 0 : 1)} >{parseInt(ItemsToEdit?.plans_items_status) ? 'Disable' : 'Enable'}</Button>,
        <Button disabled={loading} type="primary" onClick={() => update(ItemsToEdit)} >Save</Button>
      ]}
    >
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row>
            <Col span={8}>Name</Col>
            <Col span={16}>
              <TextArea type="text" style={{ width: '100%' }} disabled={loading} value={ItemsToEdit.plans_items_name} onChange={e => setItemsToEdit(d => ({ ...d, plans_items_name: e.target.value }))} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>Description</Col>
            <Col span={16}>
              <TextArea rows={3} type="text" style={{ width: '100%' }} disabled={loading} value={ItemsToEdit.plans_items_description} onChange={e => setItemsToEdit(d => ({ ...d, plans_items_description: e.target.value }))} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  </>
}

export default Plans;