import React, { useEffect, useRef, useState } from "react";
import logom from '../../assets/images/icon/amanhmo.svg';
import { Button, Col, Divider, Drawer, Modal, notification, Row, Space, Table } from "antd";
import { IdcardOutlined } from "@ant-design/icons";
import { uri_img } from "../../assets/utils/http-request";
import { myRequestObj } from "../../assets/utils/lib";
import Loader from "./loader";
import CurrencyFormat from "react-currency-format";

export const EnrolleeConsumption = ({ userId, enrolleeData = null, ...props }) => {
    const imgs = useRef(logom);
    const [showConsumption, setShowConsumption] = useState(false);
    const [loading, setLoading] = useState(false);
    const [consumptionSummary, setConsumptionSummary] = useState({});

    useEffect(() => {
        if (userId === null) {
            notification.error({ message: 'Unknow Enrollee' })
            return <></>;
        }

        if (showConsumption) {
            loadConsumption(userId);
        }
    }, [showConsumption]) // eslint-disable-line

    const handleConsumption = () => {
        setShowConsumption(!showConsumption)
    }

    const loadConsumption = (userId) => {
        setLoading(true)
        myRequestObj('consumption').post({ userId })
            .then(response => {
                if (Number(response?.status) === 200) {
                    return response?.data
                } else {
                    notification.error({ message: 'Failed to load consumption summary' })
                    return null;
                }
            })
            .then(data => {
                if (data) {
                    if (Number(data?.error) === 0) {
                        setConsumptionSummary(data?.data)
                    }
                    else {
                        notification.error({ message: data?.message })
                    }
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    let imageStyle = {
        width: "50px",
        height: "50px",
        margin: '0 auto',
        marginTop: 10,
        borderRadius: '50%',
        cursor: 'pointer',
    }

    const headerSpanStyle = {
        display: 'inline-block',
        cursor: 'pointer'
    }

    imgs.current = enrolleeData?.pic_name ? `${uri_img}${enrolleeData.pic_name}` : logom

    return (
        <>
            <Button onClick={handleConsumption} icon={<IdcardOutlined />} >View Premium Consumption</Button>
            <Drawer width={600} title={<Space onClick={handleConsumption}>
                <span style={headerSpanStyle}><img style={imageStyle} src={imgs.current} alt={''} /></span>
                <span style={headerSpanStyle}>Enrollee Policy Consumption</span>
            </Space>}
                closable={false}
                placement="right" onClose={handleConsumption} open={showConsumption} extra={
                    <Space>
                        {userId !== null ? <Button onClick={() => loadConsumption(userId)} type="primary">Reload</Button> : null}
                        <Button onClick={handleConsumption} type="primary">Close</Button>
                    </Space>
                }
            >
                {loading ?
                    <Loader /> :
                    <div>
                        <div>
                            <div>
                                <Divider type="horizontal" orientation="left" orientationMargin={0}>Biodata Brief</Divider>
                                <Row justify='space-between' className="id-container-body-name-div-name-policy">
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Enrollee Name:</span></Col>
                                            <Col span={12}><span style={{ color: "#34495e", fontWeight: 'bold' }}>{enrolleeData?.first_name || ''} {enrolleeData?.middle_name || ''} {enrolleeData?.surname || ''}</span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Insurance No.:</span></Col>
                                            <Col span={12}><span style={{ color: "#34495e" }}>{enrolleeData?.insurance_no || ''}</span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Insurance Package:</span></Col>
                                            <Col span={12}><span style={{ color: "#34495e" }}>{consumptionSummary?.insurance_package?.toUpperCase()}</span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Policy No.:</span></Col>
                                            <Col span={12}><span style={{ color: "#34495e" }}>{consumptionSummary?.policy_no}</span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Insurace Status:</span></Col>
                                            <Col span={12}><span style={{ color: "#34495e" }}>{Number(enrolleeData?.status) ? "Active" : 'Inactive'}</span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Expires:</span></Col>
                                            <Col span={12}><span style={{ color: "#34495e" }}>{(new Date(consumptionSummary?.policyVailidity)).toDateString()}</span></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider type="horizontal" orientation="left" orientationMargin={0}>Premium Consumption</Divider>
                                <Row gutter={[8, 8]} justify='space-between' className="id-container-body-name-div-name-policy">
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Number Of Encounters:</span></Col>
                                            <Col span={6}><span style={{ color: "#34495e" }}>{consumptionSummary?.checkins_count}</span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Cost Incurred for In-Patient Care:</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.in_patient} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><span style={{ color: "#34495e" }}>of</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.in_patient_limit} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Cost Incurred for Out-Patient Care:</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.out_patient ?? 0} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><span style={{ color: "#34495e" }}>of</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.out_patient_limit ?? 0} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Cost Incurred for Antenatal Care:</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.antenatal_care ?? 0} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><span style={{ color: "#34495e" }}>of</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.antenatal_care_limit ?? 0} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Cost Incurred for Optomology Care:</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.optomology_care ?? 0} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><span style={{ color: "#34495e" }}>of</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.optomology_care_limit ?? 0} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12}><span className="id-container-body-name-div-id">Cost Incurred for Dental Care:</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.dental_care ?? 0} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><span style={{ color: "#34495e" }}>of</span></Col>
                                            <Col span={5}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.dental_care_limit ?? 0} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24} style={{ margin: '5px 0px' }}>
                                        <Divider type="horizontal" />
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={16}><span className="id-container-body-name-div-id">Cost Incurred for Drugs and Consumables:</span></Col>
                                            <Col span={6}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.drugs_costs} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><ConsumedServices userId={userId} category={1} categoryName={'Drugs and Consumables'} disabled={!(Number(consumptionSummary?.drugs_costs) > 0)} /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={16}><span className="id-container-body-name-div-id">Cost Incurred for Laboratory Services:</span></Col>
                                            <Col span={6}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.laboratory_cost} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><ConsumedServices userId={userId} category={3} categoryName={'Laboratory Services'} disabled={!(Number(consumptionSummary?.laboratory_cost) > 0)} /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={16}><span className="id-container-body-name-div-id">Cost Incurred for Radiological Services:</span></Col>
                                            <Col span={6}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.radiological_cost} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><ConsumedServices userId={userId} category={4} categoryName={'Radiological Services'} disabled={!(Number(consumptionSummary?.radiological_cost) > 0)} /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={16}><span className="id-container-body-name-div-id">Cost Incurred for Other Medical Services:</span></Col>
                                            <Col span={6}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.services_cost} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                            <Col span={2}><ConsumedServices userId={userId} category={2} categoryName={'Other Services'} disabled={!(Number(consumptionSummary?.services_cost) > 0)} /></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24} style={{ margin: '5px 0px' }}>
                                        <Divider type="horizontal" />
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={16}><span className="id-container-body-name-div-id">Total Policy Cover Used:</span></Col>
                                            <Col span={8}><span style={{ color: "#34495e" }}><CurrencyFormat value={consumptionSummary?.total_consumption} prefix="₦" thousandSeparator={','} type="text" displayType="text" /></span></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                }
            </Drawer>
        </>

    )
}

export const ConsumedServices = ({ userId, category, categoryName=null, disabled = false }) => {
    const [showData, setShowData] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userId === null) {
            notification.error({ message: 'Unknow Enrollee' })
            return <></>;
        }

        if (category === null) {
            notification.error({ message: 'Unknow Target Care Category' })
            return <></>;
        }

        if (showData) {
            fetchServices(userId, category, page);
        }
        return () => setData([]);
    }, [showData]) // eslint-disable-line

    const showModal = () => {
        setShowData(!showData);
    }


    const fetchServices = async (userId, category, page = 1) => {
        try {
            setLoading(true);

            let hasMoreData = true;
            while (hasMoreData) {
                const response = await myRequestObj('consumed_items').post({ userId, category, page });

                if (Number(response?.status) === 200) {
                    const data = response?.data;

                    if (Number(data?.error) === 0) {
                        setData(prevData => [...prevData, ...data?.data]);
                        // If data length is 50, assume more pages
                        hasMoreData = Number(data?.data?.length) === 50;
                        page++;
                        setLoading(false);
                    } else {
                        notification.error({ message: data?.message });
                        hasMoreData = false;
                    }
                } else {
                    notification.error({ message: 'Failed to load consumption summary' });
                    hasMoreData = false;
                }
            }
        } catch (error) {
            console.error('Error fetching services:', error);
            notification.error({ message: 'An error occurred while fetching data' });
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 30,
        },
        {
            title: 'Item Name',
            dataIndex: 'item_name',
            key: 'item_name',
            width: 150,
        },
        {
            title: 'Item Brand',
            dataIndex: 'item_brand',
            key: 'item_brand',
            width: 80,
        },
        {
            title: 'Item Strength',
            dataIndex: 'item_strength',
            key: 'item_strength',
        },
        {
            title: 'Item Dosage',
            dataIndex: 'item_dosage',
            key: 'item_dosage',
        },
        {
            title: 'Item Presentation',
            dataIndex: 'item_presentation',
            key: 'item_presentation',
        },
        {
            title: '# Usage',
            dataIndex: 'totalTimes',
            key: 'totalTimes 4',
        },
        {
            title: 'Usage Cost',
            dataIndex: 'totalApprovedCost',
            key: 'totalApprovedCost 4',
            render: (__) => (
                <CurrencyFormat value={__} prefix="₦" thousandSeparator={','} type="text" displayType="text" />
            ),
        },
    ];

    return (
        <>
            <Button type="dashed" disabled={disabled} onClick={showModal}>View</Button>
            <Modal centered width={1000} title={<strong>Enrollee Consumption - {categoryName}</strong>} open={showData} onOk={showModal} destroyOnClose onCancel={showModal} onClose={showModal} footer={
                <Button onClick={showModal}>
                    Close   
                </Button>
            }>
                <Table columns={columns} dataSource={data} loading={loading}/>
            </Modal>
        </>
    );
}

