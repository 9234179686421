import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claims: {},
  totalCost: 0,
  approvedCost: 0
};

export const AppClaims = createSlice({
  name: 'appClaims',
  initialState,
  reducers: {
    add_claim: (state, action) => {
        state.claims = action?.payload;
        state.totalCost = action?.payload.reduce((i, j) => i + parseFloat(j.cost), 0)
        state.approvedCost = action?.payload.reduce((i, j) => {return (Number(j.claim_item_status) === 1 && Number(j.claim_flag) === 1)? i + parseFloat(j.approved_cost) : i}, 0)
    },
    remove_claims: (state) => {
        state.claims = {};
    },
    update_claims: (state, action) => {
        state.claims = action.payload;
    },
    add_approved: (state, action) => {
        state.approvedCost += parseFloat(action?.payload.approved_cost);
    }
  }
});

export const { add_claim, remove_claims, update_claims, add_approved } = AppClaims.actions;

export const selectClaims = (state) => state.AppClaims.claims;
export const selectApprovedCost = (state) => state.AppClaims.approvedCost;
export const selectTotalCost = (state) => state.AppClaims.totalCost;

export default AppClaims.reducer;